import { useDisclosure } from "@chakra-ui/hooks";
import {
  CheckIcon,
  HamburgerIcon,
  PlusSquareIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  MenuGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { merge, startCase, toLower } from "lodash";
import { useContext, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/all";
import { IoEllipsisVertical } from "react-icons/io5";
import { useNavigate } from "react-router";
import { Outlet, useLocation } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { AlertsStepWizard } from "../components/AlertsStepWizard";
import { AppDrawer } from "../components/app-drawer";
import OrgSelect from "../components/OrgSelect";
import { RESOURCES } from "../constants/user-constants";
import MessageModalContext from "../contexts/MessageModalContext";
import DathicChatBtn from "../features/dathic-chat/DathicChatBtn";
import { setIsSelectingOnMap } from "../features/map-selection/mapSelectionSlice";
import {
  selectViews,
  setViews,
  ViewState,
} from "../features/map-views/mapViewsSlice";
import ViewSettingsModal from "../features/map-views/ViewSettingsModal";
import {
  selectSelectedAudiences,
  selectSelectedStates,
} from "../features/map/keplerReducer";
import { PopoverTrigger } from "../features/org-screen/OrgOverview";
import AppStatusBar from "../features/status-bar/AppStatusBar";
import DisableStoresProxy from "../features/stores/DisableStoresProxy";
import { useRecommendationAlerts } from "../features/stores/RecommendationHooks";
import { StoreRecommendationsProxy } from "../features/stores/StoreRecommendationsProxy";
import HelpBtn from "../features/tours/HelpBtn";
import { getViews, putUser, putView } from "../services/api.service";
import {
  addOpenTour,
  selectAccessToken,
  selectCurrentUser,
  selectIsReadOnly,
  selectOpenUploadProxy,
  selectOrgProperties,
  selectTidioGuides,
  selectUserIsInDemo,
  selectUserResources,
} from "./appSlice";
import LateralAppBar from "./LateralAppBar";
import { LateralSubmenu } from "./LateralSubmenu";
import NotionTutorial from "./NotionTutorial";
import { useAppDispatch, useAppSelector } from "./store";

function HeaderOptions() {
  const [isCreatingView, setIsCreatingView] = useState(false);
  const shareModalDisclosure = useDisclosure();
  const views = useAppSelector(selectViews);
  const currentUser = useAppSelector(selectCurrentUser);
  const accessToken = useAppSelector(selectAccessToken);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const openOrCloseView = async (
    action: string,
    view: ViewState["views"][keyof ViewState["views"]],
    index: number
  ) => {
    getViews(view.id, accessToken).then((upToDateView) => {
      return putView(
        {
          id: upToDateView.id,
          name: upToDateView.name,
          description: upToDateView.description,
          config: {
            ...(upToDateView.config || {}),
            closed: action === "close",
          },
        },
        accessToken
      );
    });
    dispatch(
      setViews((oldViews) => {
        const newList = { ...oldViews };
        newList[`view${index}`] = merge(newList[`view${index}`], {
          config: { closed: action === "close" },
        });
        return newList;
      })
    );
  };
  return (
    <Stack
      spacing={3}
      direction={["column", null, "row"]}
      mt={[10, null, 0]}
      flex={1}
    >
      {!location.pathname?.includes("storelocator") && (
        <>
          <Menu>
            <MenuButton
              as={Button}
              variant="link"
              leftIcon={<PlusSquareIcon />}
              isActive={location.pathname?.includes("/")}
            >
              Maps ({Object.values(views).length})
            </MenuButton>
            <MenuList zIndex={999999}>
              <MenuItem
                onClick={() => {
                  navigate("/", { state: { from: location } });
                }}
              >
                Go to map
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setIsCreatingView(true);
                  shareModalDisclosure.onOpen();
                }}
                isDisabled={
                  !currentUser.resources?.includes(RESOURCES.VIEW_CREATE)
                }
              >
                Create new map
              </MenuItem>
              <MenuGroup title="My maps">
                {Object.keys(views).map((viewKey) => (
                  <MenuItem
                    icon={
                      !views[viewKey].config?.closed ? <CheckIcon /> : undefined
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      openOrCloseView(
                        !views[viewKey].config?.closed ? "close" : "open",
                        views[viewKey],
                        +viewKey.split("view")[1]
                      );
                      navigate("/");
                    }}
                  >
                    {views[viewKey].name}
                  </MenuItem>
                ))}
              </MenuGroup>
            </MenuList>
          </Menu>
          <ViewSettingsModal
            isOpen={shareModalDisclosure.isOpen}
            onClose={() => {
              setIsCreatingView(false);
              shareModalDisclosure.onClose();
            }}
            isCreatingView={isCreatingView}
          />
        </>
      )}
      <Spacer />
      {location.pathname?.includes("storelocator") &&
        !currentUser.roles?.[0]?.role?.name?.includes("store_locator") && (
          <Button
            onClick={() => navigate("/", { replace: true })}
            leftIcon={<BiArrowBack />}
          >
            Back to growth
          </Button>
        )}
      {/* <FacebookDashboard /> */}
      <DathicChatBtn />
      <HelpBtn />
    </Stack>
  );
}

const _Home = () => {
  const mobileSideDrawerDisclosure = useDisclosure();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 765px)");
  const location = useLocation();
  useRecommendationAlerts();
  const selectedStates = useAppSelector(selectSelectedStates);
  const selectedAudiences = useAppSelector(selectSelectedAudiences);
  const appIsReadOnly = useAppSelector(selectIsReadOnly);
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const userIsInDemo = useAppSelector(selectUserIsInDemo);
  const currentUser = useAppSelector(selectCurrentUser);
  const openUploadProxy = useAppSelector(selectOpenUploadProxy);
  const tidioGuides = useAppSelector(selectTidioGuides);
  const dispatch = useAppDispatch();
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const orgName = orgProperties?.organization?.name;
  const messageModalContext = useContext(MessageModalContext);

  useEffect(() => {
    const messageFromVisitor = (o: any) => {
      var doc = new DOMParser().parseFromString(o?.message, "text/html");
      const message = doc.documentElement.textContent;
      const selectedGuide = tidioGuides?.find((g) => g.msg === message)?.guide;
      if (selectedGuide) {
        messageModalContext.showModal({
          title: message,
          message: <NotionTutorial tutorialFileName={selectedGuide} />,
          modalProps: { size: "4xl" },
        });
        window.tidioChatApi.close();
      }
    };
    window.tidioChatApi.on("messageFromVisitor", messageFromVisitor);

    return () => {
      window.removeEventListener(
        "tidioChat-messageFromVisitor",
        messageFromVisitor
      );
    };
  }, []);

  useEffect(() => {
    const viewedTours: string[] =
      orgProperties?.properties?.viewedGrowthTours?.[currentUser.id] || [];
    switch (location.pathname) {
      case "/":
        if (!viewedTours.includes("homeTour")) {
          dispatch(addOpenTour("homeTour"));
        }
        break;
      case "/storelocator":
        if (!viewedTours.includes("storelocatorTour")) {
          dispatch(addOpenTour("storelocatorTour"));
        }
        break;

      default:
        break;
    }
  }, [currentUser, dispatch, location.pathname, orgProperties]);

  useEffect(() => {
    dispatch(setIsSelectingOnMap(false));
  }, [dispatch, drawerOpen]);

  useEffect(() => {
    if (
      !location.pathname.includes("storelocator") &&
      currentUser.roles?.[0]?.role?.name?.includes("store_locator")
    ) {
      window.location.href = "/storelocator";
    }
  }, [location.pathname]);

  const openDisableStoresProxy = openUploadProxy.find(
    (uploadProxy) =>
      uploadProxy.name === "DisableStoresProxy" &&
      uploadProxy.props?.items?.length
  );

  const openStoreRecommendationsProxy = openUploadProxy.find(
    (uploadProxy) => uploadProxy.name === "StoreRecommendationsProxy"
  );

  const openStoreDiscontinuedProxy = openUploadProxy.find(
    (uploadProxy) => uploadProxy.name === "StoreDiscontinuedProxy"
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Flex style={{ height: height, width: width }} flexDir="row">
          <LateralAppBar
            isOpen={mobileSideDrawerDisclosure.isOpen}
            onClose={mobileSideDrawerDisclosure.onClose}
          />
          <Flex flexDir="column" flex={1} backgroundColor="white" zIndex={1}>
            <HStack
              padding={["1rem 0.6rem", null, "1rem 2rem"]}
              justify="space-between"
              zIndex={999}
              spacing={3}
              borderBottomWidth={1}
            >
              <HStack spacing={2} flex={1}>
                <IconButton
                  icon={<HamburgerIcon />}
                  onClick={mobileSideDrawerDisclosure.onOpen}
                  display={["flex", null, "none"]}
                  aria-label="Mobile sidebar hamburger button"
                />

                {location.pathname?.includes("storelocator") && (
                  <VStack alignItems="flex-start">
                    {!!orgName && (
                      <Text fontSize="sm">
                        {orgName
                          ? `${orgName}${
                              toLower(orgName.at(-1)) === "s" ? "'" : "'s"
                            }`
                          : ""}
                      </Text>
                    )}
                    <Heading size="md">{storeLabel} Locator</Heading>
                  </VStack>
                )}
                {!!currentUser.is_super && (
                  <Button
                    variant="link"
                    float={"right"}
                    colorScheme="blue"
                    onClick={() =>
                      messageModalContext.showModal({
                        title: "Change organization",
                        message(dialogState, setDialogState) {
                          return (
                            <Box w="100%" h={250}>
                              <OrgSelect
                                value={dialogState?.organization_id}
                                onChange={(e) =>
                                  setDialogState({
                                    organization_id: e,
                                  })
                                }
                              />
                            </Box>
                          );
                        },
                        actions: [
                          {
                            label: "Reload",
                            callback(_, dialogState) {
                              putUser(
                                {
                                  id: currentUser.id,
                                  access_token: currentUser.access_token,
                                  token: currentUser.token,
                                  username: currentUser?.username,
                                  user_id: currentUser.user_id,
                                  organization_id: dialogState?.organization_id,
                                },
                                accessToken
                              ).then(() => window.location.reload());
                            },
                          },
                        ],
                        initialDialogState: {
                          organization_id: currentUser.organization_id,
                        },
                      })
                    }
                  >
                    Change
                  </Button>
                )}
              </HStack>
              {!appIsReadOnly && !location.pathname?.includes("storelocator") && (
                <HStack flex={1} justifyContent="center">
                  <Button
                    id="audience-select-btn"
                    className="header-btn"
                    variant="solid"
                    rightIcon={
                      <SearchIcon
                        id="audience-select-btn"
                        className="header-btn-icon"
                      />
                    }
                    onClick={() => setDrawerOpen((oldValue) => !oldValue)}
                    disabled={
                      userIsInDemo ||
                      !userResources?.includes(RESOURCES.AUDIENCE_SELECT)
                    }
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <HStack alignItems="center">
                      <Text>Explore</Text>
                      {!!selectedStates?.length &&
                        !!selectedAudiences?.length && (
                          <Text fontSize="lg">
                            {`${
                              selectedAudiences?.length > 3
                                ? `${selectedAudiences?.length} nationalities`
                                : selectedAudiences
                                    ?.map((item) => startCase(item))
                                    .join(" & ")
                            } in ${
                              selectedStates?.length > 3
                                ? `${selectedStates?.length} states`
                                : selectedStates.join(" & ").toUpperCase()
                            }`}
                          </Text>
                        )}
                    </HStack>
                  </Button>
                </HStack>
              )}
              {!appIsReadOnly &&
                !!location.pathname?.includes("storelocator") && (
                  <AlertsStepWizard />
                )}
              {isMobile && (
                <Popover>
                  <PopoverTrigger>
                    <>
                      <IconButton
                        icon={<IoEllipsisVertical />}
                        aria-label="header options trigger"
                      />
                    </>
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <HeaderOptions />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
              {!isMobile && <HeaderOptions />}
            </HStack>
            <Flex flexDir="row" flex={1} w="100%" overflowY="auto">
              <LateralSubmenu />
              <Box flex={1} pos="relative">
                <Box
                  pos="absolute"
                  top={0}
                  right={0}
                  bottom={0}
                  left={0}
                  bg="#F7F7F7"
                >
                  <Outlet />
                </Box>
              </Box>
            </Flex>
            <AppStatusBar />
            <AppDrawer
              isOpen={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            />
          </Flex>
          {openDisableStoresProxy && (
            <DisableStoresProxy {...(openDisableStoresProxy?.props || {})} />
          )}
          {openStoreRecommendationsProxy && (
            <StoreRecommendationsProxy
              {...(openStoreRecommendationsProxy?.props || {})}
            />
          )}
          {openStoreDiscontinuedProxy && (
            <StoreRecommendationsProxy
              {...(openStoreDiscontinuedProxy?.props || {})}
            />
          )}
        </Flex>
      )}
    </AutoSizer>
  );
};

export const Home = _Home;
