import { CheckIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { DataType } from "ka-table";
import _ from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import StepWizard, { StepWizardChildProps } from "react-step-wizard";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import {
  selectAccessToken,
  selectChains,
  selectCurrentUser,
  selectHelpExiprySec,
  selectOrgProperties,
  selectProducts,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import AutocompleteInput from "../../components/AutocompleteInput";
import FileUploadProxy from "../../components/file-upload-proxy";
import PageLayout from "../../components/PageLayout";
import { PageSection } from "../../components/PageSection";
import { ResponsiveModal } from "../../components/responsive-modal";
import MessageModalContext from "../../contexts/MessageModalContext";
import { ProductStore } from "../../domain/ProductStore";
import { SearchStores } from "../../domain/SearchStores";
import { Store } from "../../domain/Store";
import { StoreImportHistory } from "../../domain/StoreImportHistory";
import {
  getStoreImportHistory,
  postSearchChains,
  postStoreImportHistory,
  postStoresBatch,
  postSupportTicket,
  putStoreImportHistory,
  putStoresBatch,
} from "../../services/api.service";
import ProductRelationProxy, {
  State as ProductRelationState,
} from "./ProductRelationProxy";
import {
  storeUploadValidation,
  validateStoreAddress,
  validateStoreProductRelationWithUploaded,
} from "./store-validators";
import "./styles.css";
import { useUpload, useUploadSupport } from "./UploadHooks";
import UploadProductStoreProxy, {
  State as UploadProductStoreState,
} from "./UploadProductStoreProxy";
import UploadStoresProxy from "./UploadStoresProxy";

type Props = {
  onClose: () => void;
  file: File;
  storeTypes?: (string | undefined)[];
};
const steps = (storeLabel: string) => [
  "Upload file and mapping",
  `${storeLabel} Validation`,
  "Product Validation",
  `Product availability`,
  "Review and finish",
];
const storeFilesFolderName = "store-files";

const Nav = ({
  totalSteps,
  goToStep,
  currentStep,
  previousStep,
  nextStep,
  storeLabel,
  filename,
}: StepWizardChildProps & { storeLabel: string; filename: string }) => {
  return (
    <HStack
      borderBottom="1px solid gainsboro"
      p="1rem 1.5rem"
      justifyContent={"space-between"}
      mb={5}
    >
      <Box>
        <Text fontSize="lg">Importing{filename ? ` from file:` : " file"}</Text>
        {!!filename && (
          <Text fontSize="sm" fontStyle={"italic"} color="gray">
            {filename}
          </Text>
        )}
      </Box>
      {steps(storeLabel).length > 1 ? (
        <HStack
          justifyContent="center"
          color="black"
          divider={<ChevronRightIcon />}
          spacing={5}
          mt={5}
        >
          {steps(storeLabel).map((dot, index) => {
            return (
              <Button
                variant={"link"}
                key={`step-${index}`}
                style={{
                  opacity: 0.4,
                  transition: "opacity 1s ease",
                  willChange: "opacity",
                  ...(currentStep === index + 1
                    ? {
                        color: "var(--chakra-colors-blue-400)",
                        opacity: 1,
                      }
                    : currentStep > index + 1
                    ? {
                        color: "var(--chakra-colors-green-400)",
                        opacity: 1,
                      }
                    : {}),
                }}
                disabled
              >
                <Text
                  border={"1px solid"}
                  borderRadius="full"
                  p={1}
                  w={7}
                  mr={2}
                  style={
                    currentStep > index + 1
                      ? {
                          color: "white",
                          backgroundColor: "var(--chakra-colors-green-400)",
                        }
                      : {}
                  }
                >
                  {currentStep > index + 1 ? <CheckIcon /> : index + 1}
                </Text>{" "}
                {dot}
              </Button>
            );
          })}
        </HStack>
      ) : (
        <></>
      )}
    </HStack>
  );
};

const ValidatingStoresMessage = ({
  storeLabel,
  chainsValidated,
}: {
  storeLabel: string;
  chainsValidated: boolean;
}) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (chainsValidated) {
      if (step === 0) {
        setStep(1);
        return;
      }
      setTimeout(() => {
        setStep((old) => old + 1);
      }, Math.round(Math.random() * 5000 + 3000));
    }
  }, [chainsValidated, step]);

  return (
    <PageLayout
      title={`We're validating the ${pluralize(storeLabel)} in your file`}
      label="This can take a moment, Please don't close this page or refresh your browser."
    >
      <Progress w={"100%"} size="xs" isIndeterminate />
      <PageSection contentDirection="column">
        <Text>{step === 0 ? "..." : <CheckIcon />} Checking retailers 🛍️</Text>
        {step > 0 && (
          <Text>
            {step === 1 ? "..." : <CheckIcon />} Confirming your store locations
            📝
          </Text>
        )}
        {step > 1 && (
          <Text>
            {step > 2 ? <CheckIcon /> : "..."} Spotting any duplicates in the
            file 📑
          </Text>
        )}
        {step > 2 && (
          <Text>
            {step > 3 ? <CheckIcon /> : "..."} Checking for{" "}
            {pluralize(storeLabel)} already in your locator 📍
          </Text>
        )}
        {step > 3 && (
          <Text>...Matching {pluralize(storeLabel)} with Dathic 🎯</Text>
        )}
      </PageSection>
    </PageLayout>
  );
};

export default function StoreUploadWizard({
  onClose,
  file,
  storeTypes,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const messageModalContext = useContext(MessageModalContext);
  const currentUser = useAppSelector(selectCurrentUser);
  const accessToken = useAppSelector(selectAccessToken);
  const helpExiprySec = useAppSelector(selectHelpExiprySec);
  const chains = useAppSelector(selectChains);
  const [stepWizardInstance, setStepWizardInstance] =
    useState<StepWizardChildProps | null>(null);
  const [fileProxyActions, setFileProxyActions] = useState<any>();
  const [chainsValidated, setChainsValidated] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [storeProxyActions, setStoreProxyActions] = useState<any>();
  const [importHistoryItem, setImportHistoryItem] =
    useState<StoreImportHistory>();
  const [productsProxyActions, setProductsProxyActions] = useState<any>();
  const [productStoresProxyActions, setProductStoresProxyActions] =
    useState<any>();
  const [filePath, setFilePath] = useState<any>();
  const [fieldsResult, setFieldsResult] = useState<{
    selectedFields: any;
    data?: any[];
    dataCsv?: any;
    dataWithProblems?: any;
  }>();
  const [storeUploadResult, setStoreUploadResult] = useState<{
    goodData: { item: Store; original: any }[];
    badItems: any[];
    file?: File;
    validatedItems?: Store[];
  }>();
  const [productRelationState, setProductRelationState] =
    useState<ProductRelationState>();
  const [uploadProductStoreState, setUploadProductStoreState] =
    useState<UploadProductStoreState>();
  const [validationResult, setValidationResult] = useState<{
    selectedItems?: Store[];
    validatedItems?: Store[];
  }>({});
  const [storeProxyResult, setStoreProxyResult] = useState<any>();
  const products = useAppSelector(selectProducts);
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLabel = useMemo(
    () =>
      storeTypes?.includes("restaurant")
        ? "Restaurant"
        : orgProperties?.properties?.storeNameReplacement || "Store",
    [orgProperties, storeTypes]
  );
  const statesLabel = useMemo(
    () => orgProperties?.properties?.statesNameReplacement || "State",
    [orgProperties]
  );
  const zipcodeLabel = useMemo(
    () => orgProperties?.properties?.zipcodeNameReplacement || "Zipcode",
    [orgProperties]
  );
  const putBatch = useCallback(
    async (items: any[], accessToken: string) => {
      const toUpdate = items.map((i) =>
        new Store({
          ...i,
          type: storeTypes?.includes("restaurant") ? "restaurant" : i.type,
          chain: i.chain,
        }).buildForUpdate()
      );
      await putStoresBatch(accessToken, toUpdate);
      return toUpdate;
    },
    [storeTypes]
  );
  const postBatch = useCallback(
    async (items: any[], accessToken: string) => {
      const { store_id } = await postStoresBatch(
        accessToken,
        items.map((i) =>
          new Store({
            ...i,
            type: storeTypes?.includes("restaurant") ? "restaurant" : i.type,
            chain: i.chain,
          }).buildForPost()
        )
      );
      return store_id;
    },
    [storeTypes]
  );
  const schema = useMemo(
    () => Store.getSchemaForTable(statesLabel, zipcodeLabel, storeTypes),
    [statesLabel, storeTypes, zipcodeLabel]
  );
  const validator = useMemo(
    () => (itemsToUpload: Store[], accessToken: string) =>
      storeUploadValidation(
        itemsToUpload,
        accessToken,
        [],
        orgProperties?.properties?.country,
        storeLabel,
        orgProperties?.organization_id
      ),
    [orgProperties, storeLabel]
  );
  const {
    uploadItems,
    onValidate,
    uploadFile,
    updateItems,
    validatingMessage,
    updatingItems,
    uploadingItems,
  } = useUpload<Store>({
    noModals: true,
    validator,
    entityName: storeLabel,
    postBatch,
    putBatch,
    schema,
  });
  const { startSupport } = useUploadSupport({
    startSupportCondition: !!validationResult.validatedItems?.length,
    startSupportTimeout: 2000,
    useEffectDependencies: [validationResult],
    eventName: "location_import_help",
    messageTriggerTicket: "Help me with location import",
    createSupportTicket: async () => {
      return postSupportTicket(accessToken, {
        title: "Help requested importing locations",
        description: "User has requested help with importing locations",
        data: { importHistoryItem },
        user_id: currentUser?.id,
      });
    },
  });

  useEffect(() => {
    if (file) {
      const fileName = DateTime.now()
        .toFormat("yyyy-MM-dd_hh-mm-ss")
        .concat(file.name);
      const folderKey =
        orgProperties?.organization?.id +
        "/" +
        encodeURIComponent(storeFilesFolderName) +
        "/";
      const Key = folderKey + fileName;
      setFilePath({ fileName, folderKey, Key });
      uploadFile({
        file,
        getFileName: fileName,
        folderName: storeFilesFolderName,
      })
        .then((uploadedFile) => {
          if (uploadedFile) {
            const Bucket = process.env.REACT_APP_S3_BUCKET;
            return postStoreImportHistory(accessToken, {
              original_filename: file.name,
              original_file_key: `${Bucket}:${Key}`,
              status: "started",
            });
          }
          return undefined;
        })
        .then((newItem) => {
          setImportHistoryItem(newItem);
          return getStoreImportHistory(accessToken);
        })
        .then((importHistory) => {
          const lastFilename = importHistory?.[0]?.original_filename;
          const nextFilename = importHistory?.[1]?.original_filename;
          const expiryTime = DateTime.fromSQL(
            importHistory?.[1]?.created_at
          ).plus({ seconds: helpExiprySec });
          const isExpired =
            !isNaN(helpExiprySec) && expiryTime < DateTime.now();
          if (
            lastFilename &&
            nextFilename &&
            lastFilename === nextFilename &&
            !isExpired
          ) {
            startSupport();
          }
        });
    }
  }, [accessToken, file, uploadFile]);

  const handleWizardContinue = () => {
    switch (stepWizardInstance?.currentStep) {
      case 1:
        fileProxyActions?.handleContinueUpload();
        break;
      case 2:
        storeProxyActions?.uploadLocationsSubmit();
        break;
      case 3:
        if (
          !productRelationState?.relationMethod ||
          productRelationState?.relationMethod === "not_in_file" ||
          !productRelationState?.detectedProducts?.length
        ) {
          stepWizardInstance?.goToStep(5);
          setCurrentStep(5);
          break;
        }
        productsProxyActions?.doFinish();
        break;
      case 4:
        productStoresProxyActions?.onSubmit();
        break;
      case 5:
        onClose();
        break;

      default:
        break;
    }
  };

  const getNextButtonText = () => {
    if (currentStep === steps(storeLabel).length) {
      return "Finish";
    }
    return `Next`;
  };

  const getAllowContinue = () => {
    switch (stepWizardInstance?.currentStep) {
      case 1:
        return fileProxyActions?.allowSubmit;
      case 2:
        return (
          (!validationResult.validatedItems?.length &&
            !validatingMessage &&
            !updatingItems &&
            !uploadingItems &&
            !storeProxyActions?.uploadingItems &&
            stepWizardInstance?.currentStep === 2) ||
          storeProxyActions?.selectedStores
        );
      case 3:
        return productsProxyActions?.allowContinue;

      default:
        return true;
    }
  };

  const handleUploadFinish = useCallback(
    async (
      goodData: { item: Store; original: any }[],
      badItems: any[],
      file?: File,
      validatedItems?: Store[],
      selectedFields?: Object
    ) => {
      setStoreUploadResult({
        goodData,
        badItems,
        file,
        validatedItems,
      });
      const data = validatedItems?.filter((d) => !!d) || [];
      setProductRelationState({ data, selectedFields: selectedFields || {} });
      stepWizardInstance?.goToStep(3);
      setCurrentStep(3);
    },
    [stepWizardInstance]
  );

  const generateDownload = useCallback(
    (storesToDownload: any[], processID: string) => {
      var wb = XLSX.utils.book_new();
      const storesSheet = XLSX.utils.json_to_sheet(storesToDownload);
      XLSX.utils.book_append_sheet(wb, storesSheet, "locations");
      XLSX.writeFile(wb, "upload_progress.xlsx");

      const wbBlob = new Blob(
        [XLSX.write(wb, { type: "array", bookType: "xlsx" })],
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
      const file = new File([wbBlob], "upload_progress.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      uploadFile({
        file,
        folderName: "store-files",
        getFileName: `${processID}_upload_progress.xlsx`,
      }).then((res) => {
        console.log(res);
      });
    },
    [uploadFile]
  );

  const _uploadItems = useCallback(
    (
      validationResult: {
        selectedItems?: Store[];
        validatedItems?: Store[];
      },
      selectedFields: Object,
      processID: string
    ) => {
      const { selectedItems = [], validatedItems } = validationResult;
      const filterForUpdate = (item: Store) =>
        item.searched?.orgStore?.status === "not_selling";
      const mapForUpdate = (item: any) =>
        new Store({
          ...(item.searched?.orgStore || {}),
          status: "selling",
        }).buildForUpdate();
      const toUpdate = filterForUpdate
        ? mapForUpdate
          ? selectedItems.filter(filterForUpdate).map(mapForUpdate)
          : selectedItems.filter(filterForUpdate)
        : [];
      let _storeProxyResult: any;
      const { fileName, folderKey, Key } = filePath || {};
      const Bucket = process.env.REACT_APP_S3_BUCKET;
      updateItems(toUpdate)
        .then(() => {
          return uploadItems(
            selectedItems
              .filter((item) =>
                filterForUpdate ? !filterForUpdate(item) : true
              )
              .map((item) => {
                item.sources = [`${Bucket}:${Key}`];
                return item;
              })
          );
        })
        .then(({ goodItems, badItems }) => {
          const _file = file;
          toast.success(
            `Added ${pluralize(
              storeLabel,
              goodItems?.length ?? 0,
              true
            )} to your locator`
          );
          _storeProxyResult = { ...validationResult, goodItems, badItems };
          setStoreProxyResult(_storeProxyResult);
          handleUploadFinish(
            [
              ...goodItems,
              ...toUpdate.map((item) => ({ item, original: item })),
            ],
            badItems,
            _file,
            validatedItems,
            selectedFields
          );
          if (_file) {
            return uploadFile({
              file: _file,
              getFileName: fileName,
              folderName: storeFilesFolderName,
            });
          } else {
            return undefined;
          }
        })
        .then((uploadedFile) => {
          if (uploadedFile) {
            const processedKey =
              folderKey + `${processID}_upload_progress.xlsx`;
            generateDownload(
              _storeProxyResult?.goodItems.map((i: any) =>
                _.pick(i.item, [
                  "id",
                  "name",
                  "chain",
                  "address",
                  "city",
                  "state",
                  "zipcode",
                ])
              ),
              processID
            );
            if (importHistoryItem) {
              putStoreImportHistory(accessToken, {
                ...importHistoryItem,
                status: "imported",
                num_stores_added:
                  (importHistoryItem.num_stores_added ?? 0) +
                  (_storeProxyResult?.goodItems?.length ?? 0),
              }).then((newItem) => {
                setImportHistoryItem(newItem);
              });
            } else {
              postStoreImportHistory(accessToken, {
                original_filename: file.name,
                original_file_key: `${Bucket}:${Key}`,
                processed_file_key: `${Bucket}:${processedKey}`,
                num_stores_added: _storeProxyResult?.goodItems?.length,
                status: "imported",
                process_id: processID,
              }).then((newItem) => {
                setImportHistoryItem(newItem);
              });
            }
          }
        });
    },
    [
      file,
      filePath,
      handleUploadFinish,
      updateItems,
      uploadFile,
      uploadItems,
      storeLabel,
      accessToken,
      importHistoryItem,
      generateDownload,
    ]
  );

  const startValidation = useCallback(
    async (_fieldsResult: {
      selectedFields: any;
      data?: any[];
      dataCsv?: any;
      dataWithProblems?: any;
    }) => {
      const { data, dataWithProblems } = _fieldsResult || fieldsResult || {};
      let _data = data as Store[] | undefined;
      if (_data?.length) {
        const chainMatchResult = (await postSearchChains(
          accessToken,
          _data.map((s) => {
            const { id, store_id, status, ...rest } = s;
            return new Store(rest).buildForSearch() as Store;
          })
        )) as (SearchStores & Store)[];
        const countWithChains = chainMatchResult?.reduce(
          (acc, c) => acc + (c.chain ? 1 : 0),
          0
        );
        if (!countWithChains) {
          const chainMatchResult = (await postSearchChains(accessToken, [
            { address: "", chain_input: file.name },
          ])) as (SearchStores & Store)[];
          const foundChain = chainMatchResult?.find((c) => c.chain)?.chain;
          const selectedRetailer: string | undefined = await new Promise(
            (resolve) =>
              messageModalContext.showModal({
                title: "No retailers in file",
                initialDialogState: { selectedRetailer: foundChain },
                hideCloseButton: true,
                modalProps: { closeOnEsc: false },
                message: (dialogState, setDialogState) => (
                  <VStack alignItems={"flex-start"} minH={200}>
                    <Text>
                      We couldn't find any retailers in your file. Would you
                      like to add a retailer to the locations on file?
                    </Text>
                    <FormControl>
                      <FormLabel>Select retailer</FormLabel>
                      <AutocompleteInput
                        options={chains}
                        value={dialogState.selectedRetailer}
                        onChange={(e) => {
                          setDialogState({ selectedRetailer: e });
                        }}
                        handleSearchResultClick={(e) => {
                          setDialogState({
                            selectedRetailer: e?.suggestionValue,
                          });
                        }}
                      ></AutocompleteInput>
                      <FormHelperText mt={3}>
                        Selected retailer will be added to all locations on file
                      </FormHelperText>
                    </FormControl>
                  </VStack>
                ),
                actions: [
                  {
                    label: "Continue without retailers",
                    callback: () => resolve(undefined),
                  },
                  {
                    label: "Use selected retailer",
                    callback: (_, dialogState) =>
                      resolve(dialogState.selectedRetailer),
                    props(index, dialogState, setDialogState) {
                      return {
                        isDisabled: dialogState.selectedRetailer === "",
                      };
                    },
                  },
                ],
              })
          );
          if (selectedRetailer) {
            _data = _data.map((d) => {
              d.chain = selectedRetailer;
              return d;
            });
          }
        }
        setChainsValidated(true);

        const validationResult = await onValidate(
          _data,
          undefined,
          dataWithProblems,
          () => {},
          { storeTypes }
        );
        setValidationResult(validationResult);
      } else {
        toast.error("No items to upload");
      }
    },
    [
      accessToken,
      fieldsResult,
      file.name,
      messageModalContext,
      onValidate,
      storeTypes,
    ]
  );

  const handleFieldsPass = useCallback(
    async (
      selectedFields: any,
      data?: any[],
      dataCsv?: any,
      dataWithProblems?: any
    ) => {
      setFieldsResult({
        selectedFields,
        data,
        dataCsv,
        dataWithProblems,
      });
      stepWizardInstance?.goToStep(2);
      setCurrentStep(2);
      startValidation({
        selectedFields,
        data,
        dataCsv,
        dataWithProblems,
      });
    },
    [startValidation, stepWizardInstance]
  );

  const onCloseStoresProxy = useCallback(() => {}, []);

  const onFinishStoresProxy = useCallback(
    (selectedItems: any[], isPartial: boolean, processID: string) => {
      if (selectedItems && !isPartial) {
        const newValidationResult = { ...validationResult, selectedItems };
        _uploadItems(
          newValidationResult,
          fieldsResult?.selectedFields,
          processID
        );
        setValidationResult({});
      } else if (isPartial) {
        const { validatedItems } = { ...validationResult };
        setStoreProxyResult({ ...validationResult, goodItems: selectedItems });
        handleUploadFinish(
          selectedItems || [],
          [],
          file,
          validatedItems,
          fieldsResult?.selectedFields
        );
        setValidationResult({});
        const { folderKey } = filePath || {};
        generateDownload(
          selectedItems.map((s) =>
            _.pick(s.item, [
              "id",
              "name",
              "chain",
              "address",
              "city",
              "state",
              "zipcode",
            ])
          ),
          processID
        );
        const processedKey = folderKey + `${processID}_upload_progress.xlsx`;
        const Bucket = process.env.REACT_APP_S3_BUCKET;
        putStoreImportHistory(accessToken, {
          ...importHistoryItem,
          processed_file_key: `${Bucket}:${processedKey}`,
          num_stores_added: selectedItems?.length,
          status: "imported",
          process_id: processID,
        }).then((newItem) => {
          setImportHistoryItem(newItem);
        });
      }
    },
    [
      filePath,
      validationResult,
      _uploadItems,
      fieldsResult,
      handleUploadFinish,
      file,
      orgProperties,
      generateDownload,
      accessToken,
      importHistoryItem,
    ]
  );

  const onFinishProductsProxy = useCallback(
    (data: any[]) => {
      const { goodData = [], validatedItems } = storeUploadResult || {};
      const _goodData = goodData.map((v) => {
        const { id: searchedId, ...searchedStoreWithProducts } =
          data.find((d) => d.searched?.id === v.original.id) || {};
        const { id: dupId, ...dupStoreWithProducts } =
          data.find((d) => d.duplicate_pair === v.original.id) || {};
        return {
          item: _.merge(
            { ...v.item, id: v.item.id },
            _.merge({ ...searchedStoreWithProducts }, dupStoreWithProducts)
          ) as Store,
          original: _.merge(
            { ...v.original, id: v.item.id },
            _.merge({ ...searchedStoreWithProducts }, dupStoreWithProducts)
          ),
        };
      });
      const existingItems = (validatedItems || [])
        .filter(
          (v) =>
            !!v.searched.org_store_id &&
            !_goodData.map((g) => g.item.searched?.id).includes(v.searched.id)
        )
        .map((v) => {
          const { id, ...storeWithProducts } =
            data.find((d) => d.org_store_id === v.searched.org_store_id) || {};
          return _.merge(
            { ...v, id: v.searched.org_store_id },
            storeWithProducts
          ) as Store;
        });
      const sheet = [
        ..._goodData,
        ...existingItems.map((e) => ({ item: e, original: e })),
      ].flatMap(({ item, original }: { item: Store; original: any }) =>
        Object.entries(original)
          .filter(
            ([key, value]) =>
              products.map((p) => `${p.id} - ${p.name}`).includes(key) &&
              !!value
          )
          .map(
            ([key, units]) =>
              new ProductStore({
                origin: "Manual",
                store_id: item.id,
                product: products.find(
                  (p) =>
                    key.includes(`${p.id}`) ||
                    key.toLowerCase().includes(p.name.toLowerCase())
                ),
                product_id: products.find(
                  (p) =>
                    key.includes(`${p.id}`) ||
                    key.toLowerCase().includes(p.name.toLowerCase())
                )?.id,
                units: units || 0,
              })
          )
      );
      if (sheet.length) {
        try {
          const validRelations = validateStoreProductRelationWithUploaded(
            [..._goodData.map((g) => g.item), ...existingItems],
            sheet,
            products,
            storeLabel
          );
          if (validRelations?.length) {
            setUploadProductStoreState({
              stores: _goodData.map((g) => g.item),
              validRelations,
              existingItems,
            });
          } else {
            toast.info(`No ${storeLabel}<>Product relations to create`);
          }
        } catch (error: any) {
          toast.error(error.message);
        }
      }
      setImportHistoryItem((old) => {
        const newItem = new StoreImportHistory({
          ...old,
          num_products_added: productRelationState?.newProducts?.length,
        });
        putStoreImportHistory(accessToken, newItem);
        return newItem;
      });
      const nextStep = sheet.length ? 4 : 5;
      stepWizardInstance?.goToStep(nextStep);
      setCurrentStep(nextStep);
    },
    [
      products,
      stepWizardInstance,
      storeLabel,
      storeUploadResult,
      productRelationState,
      accessToken,
    ]
  );

  const setProductStoreState = useCallback<
    React.Dispatch<React.SetStateAction<UploadProductStoreState | undefined>>
  >(
    (newState) => {
      setUploadProductStoreState(newState);
      if (!newState) {
        stepWizardInstance?.goToStep(5);
        setCurrentStep(5);
      }
    },
    [stepWizardInstance]
  );

  const doSetProductStoreState = useCallback<
    React.Dispatch<React.SetStateAction<UploadProductStoreState | undefined>>
  >(
    (s) => {
      if (!s) {
        stepWizardInstance?.goToStep(5);
        setCurrentStep(5);
      } else {
        setProductStoreState(s);
      }
    },
    [setProductStoreState, stepWizardInstance]
  );

  const doSetProductRelationState = useCallback<
    React.Dispatch<React.SetStateAction<ProductRelationState | undefined>>
  >(
    (s) => {
      if (!!s) {
        setProductRelationState(s);
      }
    },
    [setProductRelationState]
  );

  const onFinishProductStoreProxy = useCallback(
    ({
      toAdd = [],
      toRemove = [],
      fileKey,
    }: {
      toAdd: any[];
      toRemove: any[];
      fileKey: string;
    }) => {
      setImportHistoryItem((old) => {
        const newItem = new StoreImportHistory({
          ...old,
          new_product_availability: `Added ${
            new Set(toAdd?.map((item) => (item as ProductStore).product_id))
              .size || 0
          } products to ${pluralize(
            storeLabel,
            new Set(toAdd?.map((item) => (item as ProductStore).store_id))
              .size || 0,
            true
          )}, Removed ${
            new Set(toRemove.map((r) => r.product_id)).size
          } products from ${pluralize(
            storeLabel,
            new Set(toRemove.map((r) => r.product_id)).size,
            true
          )}`,
          availability_file_key: fileKey,
        });
        putStoreImportHistory(accessToken, newItem);
        return newItem;
      });
    },
    [accessToken, storeLabel]
  );

  return (
    <div>
      <ResponsiveModal isOpen isCentered scrollBehavior="inside" size="full">
        <ModalOverlay />
        <ModalContent h="100%">
          <ModalBody as={VStack} style={{ alignItems: "normal" }}>
            <StepWizard
              // @ts-ignore
              nav={<Nav storeLabel={storeLabel} filename={file?.name} />}
              isLazyMount
              // @ts-ignore
              instance={setStepWizardInstance}
              className="store-upload-step-wizard"
            >
              <PageLayout
                title="Map fields"
                label="Match fields in the file with the corresponding label in dathic"
              >
                <PageSection>
                  <FileUploadProxy
                    file={file}
                    schemas={{
                      One:
                        Store.getSchemaForTable(
                          statesLabel,
                          zipcodeLabel,
                          storeTypes
                        )?.map((item) => ({
                          name: item.key,
                          helperText: item.title,
                          isRequired: item.isRequired,
                          format: (value: any) => {
                            switch (item.dataType) {
                              case DataType.Boolean:
                                return Boolean(value);
                              case DataType.Date:
                                return DateTime.fromISO(value).toJSDate();
                              case DataType.Number:
                                return Number(value);
                              case DataType.String:
                                return value ? `${value}` : undefined;

                              default:
                                return value;
                            }
                          },
                          validate: (value: any) => {
                            if (item.key === "address") {
                              return validateStoreAddress(value);
                            }
                          },
                          matchFunction: (schemaField: any, fileField: any) => {
                            if (schemaField.name === "zipcode") {
                              return (
                                fileField?.toLowerCase().includes("zip") ||
                                fileField?.toLowerCase().includes("postal")
                              );
                            }
                            return false;
                          },
                        })) || [],
                    }}
                    defaultSheet="Stores"
                    //   @ts-ignore
                    actionsFromOutside={setFileProxyActions}
                    isModal={false}
                    onFinishProxy={handleFieldsPass}
                  />
                </PageSection>
              </PageLayout>
              <VStack w="100%" spacing={6} flex={1}>
                {(validatingMessage || !chainsValidated) &&
                  !updatingItems &&
                  !uploadingItems && (
                    <ValidatingStoresMessage
                      storeLabel={storeLabel}
                      chainsValidated={chainsValidated}
                    />
                  )}
                {(updatingItems || uploadingItems) && (
                  <PageLayout
                    title={`${
                      updatingItems ? "Updating" : "Uploading"
                    } ${pluralize(storeLabel)}...`}
                    label={<Progress />}
                  />
                )}
                {!!validationResult.validatedItems?.length &&
                  !validatingMessage &&
                  !updatingItems &&
                  !uploadingItems &&
                  !productRelationState && (
                    <PageLayout
                      title={`Review ${pluralize(storeLabel)}`}
                      label={
                        <Box>
                          <Text>
                            Review import success and issues below. Check the
                            boxes for the locations you want to import, then
                            click "Next" to confirm.
                          </Text>
                          <Text fontStyle={"italic"}>
                            Make sure your data is accurate before uploading or
                            click the field to edit. If store name is not
                            provided, shoppers will see the chain name instead.
                          </Text>
                        </Box>
                      }
                      contentProps={{ flex: 1 }}
                    >
                      <PageSection
                        containerProps={{ flex: 1 }}
                        contentProps={{ flex: 1 }}
                      >
                        <UploadStoresProxy
                          items={validationResult.validatedItems}
                          storeTypes={storeTypes}
                          onFinish={onFinishStoresProxy}
                          onClose={onCloseStoresProxy}
                          noModals
                          actionsFromOutside={setStoreProxyActions}
                          filePath={filePath}
                        />
                      </PageSection>
                    </PageLayout>
                  )}
              </VStack>
              <PageLayout
                title="Validate products"
                label={
                  (
                    productRelationState?.data?.some((d) =>
                      Object.keys(d).some((key) =>
                        products.map((p) => `${p.id} - ${p.name}`).includes(key)
                      )
                    )
                      ? "columns"
                      : productRelationState?.data?.some((d) =>
                          Object.keys(d).some((key) =>
                            key.toLowerCase().includes("product")
                          )
                        )
                      ? "single_column"
                      : ""
                  )
                    ? 'We found product details in your file. Match the products with the corresponding product in dathic or create a new product by clicking on "Create product."'
                    : "We didn't find product details."
                }
              >
                <PageSection>
                  {productRelationState && (
                    <ProductRelationProxy
                      state={productRelationState}
                      setState={doSetProductRelationState}
                      onFinish={onFinishProductsProxy}
                      noModals
                      actionsFromOutside={setProductsProxyActions}
                    />
                  )}
                </PageSection>
              </PageLayout>
              <PageLayout
                title="Products in store match up"
                label="Review and confirm the products to add to each store. Click the buttons to see product or store details. "
              >
                <PageSection>
                  {uploadProductStoreState && (
                    <UploadProductStoreProxy
                      state={uploadProductStoreState}
                      setState={doSetProductStoreState}
                      noModals
                      actionsFromOutside={setProductStoresProxyActions}
                      onFinish={onFinishProductStoreProxy}
                    />
                  )}
                </PageSection>
              </PageLayout>
              <PageLayout title="Summary and finish">
                <PageSection
                  title={storeLabel + " Validation"}
                  contentDirection="column"
                >
                  <Text>
                    {pluralize(
                      storeLabel,
                      storeProxyResult?.selectedItems?.length,
                      true
                    )}{" "}
                    added to your locator
                  </Text>
                </PageSection>
                <PageSection
                  title="Product Validation"
                  contentDirection="column"
                >
                  <Text>
                    {pluralize(
                      "Product",
                      productRelationState?.detectedProducts?.length,
                      true
                    )}{" "}
                    were found on the file
                  </Text>
                  {!productRelationState?.detectedProducts?.length && (
                    <Text fontSize="sm" color="gray" fontStyle="italic">
                      If you know the products sold in each store, click "Manage
                      Stores" below to add the products.
                    </Text>
                  )}
                </PageSection>
                <PageSection
                  title="Product Availability"
                  contentDirection="column"
                  contentProps={{ divider: <Divider /> }}
                >
                  {!(
                    (uploadProductStoreState?.results?.toAdd?.length || 0) +
                    (uploadProductStoreState?.results?.toRemove?.length || 0)
                  ) && <Text>No changes were made</Text>}
                  {!!uploadProductStoreState?.results?.toAdd?.length && (
                    <Text>
                      {pluralize(
                        "product",
                        Array.from(
                          new Set(
                            uploadProductStoreState?.results?.toAdd?.map(
                              (ps) => ps.product_id
                            )
                          )
                        ).length,
                        true
                      )}{" "}
                      {Array.from(
                        new Set(
                          uploadProductStoreState?.results?.toAdd?.map(
                            (ps) => ps.product_id
                          )
                        )
                      ).length > 1
                        ? "were"
                        : "was"}{" "}
                      added to{" "}
                      {pluralize(
                        "location",
                        Array.from(
                          new Set(
                            uploadProductStoreState?.results?.toAdd?.map(
                              (ps) => ps.store_id
                            )
                          )
                        ).length,
                        true
                      )}
                    </Text>
                  )}
                  {!!uploadProductStoreState?.results?.toRemove?.length && (
                    <Text>
                      {pluralize(
                        "product",
                        Array.from(
                          new Set(
                            uploadProductStoreState?.results?.toRemove?.map(
                              (ps) => ps.product_id
                            )
                          )
                        ).length,
                        true
                      )}{" "}
                      {Array.from(
                        new Set(
                          uploadProductStoreState?.results?.toRemove?.map(
                            (ps) => ps.product_id
                          )
                        )
                      ).length > 1
                        ? "were"
                        : "was"}{" "}
                      removed from{" "}
                      {pluralize(
                        "location",
                        Array.from(
                          new Set(
                            uploadProductStoreState?.results?.toRemove?.map(
                              (ps) => ps.store_id
                            )
                          )
                        ).length,
                        true
                      )}
                    </Text>
                  )}
                  <Text color="green" fontWeight={"bold"}>
                    Click "Manage Stores" to update product availability of
                    these locations.
                  </Text>
                </PageSection>
              </PageLayout>
            </StepWizard>
          </ModalBody>
          <ModalFooter
            as={HStack}
            justifyContent={"flex-end"}
            borderTop="1px solid gainsboro"
            spacing={5}
          >
            <Box>
              {currentStep !== steps(storeLabel).length && (
                <Button
                  onClick={() =>
                    messageModalContext.showModal({
                      title: "Cancel file import",
                      hideCloseButton: true,
                      message:
                        "Are you sure you want to cancel the import of the file?",
                      actions: [
                        { label: "Cancel import", callback: () => onClose() },
                        { label: "Continue import" },
                      ],
                    })
                  }
                  variant="link"
                >
                  Cancel
                </Button>
              )}
            </Box>
            {currentStep === steps(storeLabel).length && (
              <Button
                onClick={() => {
                  if (location.pathname.includes("storelocator")) {
                    navigate(`/storelocator/stores`, {
                      state: {
                        tabIndex: 1,
                        selectedStores: Array.from(
                          new Set([
                            ...(storeProxyResult?.goodItems?.map(
                              (d: any) => d?.item?.id
                            ) || []),
                            ...(storeProxyResult?.validatedItems
                              ?.map((d: any) => d.searched?.orgStore?.id)
                              .filter((id: any) => !!id) || []),
                          ])
                        ),
                      },
                    });
                    onClose();
                    return;
                  }
                  navigate(`/StoresTabs/stores-manage`, {
                    state: {
                      tabIndex: 1,
                      selectedStores: Array.from(
                        new Set([
                          ...(storeProxyResult?.goodItems?.map(
                            (d: any) => d?.item?.id
                          ) || []),
                          ...(storeProxyResult?.validatedItems
                            ?.map((d: any) => d.searched?.orgStore?.id)
                            .filter((id: any) => !!id) || []),
                        ])
                      ),
                    },
                  });
                  onClose();
                }}
                isDisabled={!getAllowContinue()}
              >
                Manage {pluralize(storeLabel)}
              </Button>
            )}
            <Button
              colorScheme="blue"
              onClick={() => handleWizardContinue()}
              isDisabled={!getAllowContinue()}
            >
              {getNextButtonText()}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ResponsiveModal>
    </div>
  );
}
