import { InputProps } from "@chakra-ui/input";
import {
  Button,
  ButtonProps,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  GridItemProps,
  HStack,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  SimpleGrid,
  SimpleGridProps,
  Spacer,
  Textarea,
} from "@chakra-ui/react";
import { startCase } from "lodash";
import { DateTime } from "luxon";
import React, { ReactNode, useEffect, useMemo, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  FormProvider,
  RegisterOptions,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormRegister,
} from "react-hook-form";
import { toast } from "react-toastify";
import CheckboxGridInput from "./CheckboxGridInput";
import { DathicFileDrop, Props as FileDropProps } from "./DathicFileDrop";
import MultiSelectFormControl from "./MultiSelectFormControl";

type OptionalFunction<T> = ((values: { [key: string]: any }) => T) | T;

export type SchemaItem = {
  type?:
    | "list"
    | "string"
    | "checkboxGrid"
    | "longString"
    | "multipleSelect"
    | "number"
    | "date"
    | "file";
  fileAccept?: string;
  key: string;
  gridItemProps?: GridItemProps;
  fileDropProps?: OptionalFunction<FileDropProps>;
  label: string;
  helperText?: string;
  getOptionValue?: (option: unknown) => string | number | boolean;
  registerOptions?: RegisterOptions;
  inputProps?: InputProps;
  disabled?: OptionalFunction<boolean>;
  autocompleteProps?: { [key: string]: any };
  options?: any[];
  isRequired?: boolean;
  isHidden?: OptionalFunction<boolean>;
  placeholder?: string;
  renderFormControl?: (
    values: { [key: string]: any },
    onChange: (attr: string, value: any) => void,
    register: UseFormRegister<any>
  ) => ReactNode;
};

type SubmitButtonProps = {
  buttonProps?: ButtonProps;
  isLoading?: boolean;
  text?: string;
  submitFromOutside?: { shouldSubmit: boolean } | undefined;
};

type Props = {
  schema: SchemaItem[];
  values?: { [p: string]: any };
  gridProps?: SimpleGridProps;
  submitBtnProps?: ButtonProps;
  isLoading?: boolean;
  submitBtnText?: string;
  formName?: string;
  onSubmit?: Function;
  onFormValuesChange?: Function;
  submitFromOutside?: { shouldSubmit: boolean };
  footerContent?: ReactNode;
  RenderSubmitButton?: (props: SubmitButtonProps) => React.ReactElement;
  useFormProps?: UseFormProps;
};

const CustomDateInput = ({
  onClick,
  _formValues,
  schemaItem,
}: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  _formValues: { [p: string]: any };
  schemaItem: SchemaItem;
}) => {
  return (
    <Button onClick={onClick} variant="outline" fontWeight="normal" pr={6}>
      {_formValues[schemaItem.key]
        ? `${DateTime.fromJSDate(_formValues[schemaItem.key]).toFormat(
            "LLL dd, yyyy HH:mm"
          )}`
        : schemaItem.placeholder || schemaItem.label}
    </Button>
  );
};

export default function Form({
  schema,
  onSubmit,
  submitBtnText,
  submitBtnProps,
  footerContent,
  submitFromOutside,
  isLoading = false,
  onFormValuesChange,
  values,
  gridProps,
  useFormProps,
  formName,
  RenderSubmitButton,
}: Props) {
  const formMethods = useForm(useFormProps);
  const { handleSubmit, register, formState } = formMethods;
  const { errors = {} } = formState || {};
  const submitBtn = useRef<HTMLButtonElement>(null);

  const formValues = formMethods.watch();
  const _formValues = useMemo(() => values || formValues, [formValues, values]);

  const _setFormValues = (attr: string, value: any) => {
    const newValues = { ..._formValues, [attr]: value };
    if (onFormValuesChange) onFormValuesChange(newValues);
    formMethods.setValue(attr, value);
  };

  useEffect(() => {
    if (onFormValuesChange) onFormValuesChange(_formValues);
  }, [_formValues, onFormValuesChange]);

  useEffect(() => {
    if (submitFromOutside?.shouldSubmit) {
      submitBtn.current?.click();
    }
  }, [submitFromOutside]);

  useEffect(() => {
    if (Object.values(errors || {}).length) {
      Object.values(errors).forEach((error) => toast.warn(error.message));
    }
  }, [errors]);

  const handleOnSubmit: SubmitHandler<any> = (data, e) => {
    e?.preventDefault();
    setTimeout(() => {
      if (onSubmit) onSubmit(_formValues);
    }, 500);
  };

  const renderForm = () => (
    <SimpleGrid dir="column" spacing={5} columns={2} {...(gridProps || {})}>
      {schema
        .filter(
          (schemaItem) =>
            !(typeof schemaItem.isHidden === "function"
              ? schemaItem.isHidden(_formValues)
              : schemaItem.isHidden)
        )
        .map((schemaItem) =>
          schemaItem.renderFormControl ? (
            schemaItem.renderFormControl(_formValues, _setFormValues, register)
          ) : (
            <GridItem
              key={schemaItem.key}
              {...(schemaItem.gridItemProps || {})}
            >
              {schemaItem.type === "list" ? (
                <MultiSelectFormControl
                  label={schemaItem.label}
                  helperText={schemaItem.helperText}
                  getOptionValue={schemaItem.getOptionValue}
                  inputProps={{
                    placeholder: schemaItem.placeholder,
                    ...(schemaItem.inputProps || {}),
                  }}
                  disabledReason={schemaItem.helperText}
                  value={_formValues[schemaItem.key]}
                  isDisabled={
                    typeof schemaItem.disabled === "function"
                      ? schemaItem.disabled(_formValues)
                      : schemaItem.disabled
                  }
                  onChange={(selection) => {
                    _setFormValues(schemaItem.key, selection);
                  }}
                  autocompleteProps={{
                    options: schemaItem.options,
                    exclude: _formValues[schemaItem.key],
                    focusInputOnSuggestionClick: true,
                    ...(schemaItem.autocompleteProps || {}),
                  }}
                />
              ) : (
                <FormControl
                  key={schemaItem.key}
                  isRequired={schemaItem.isRequired}
                  isInvalid={errors?.[schemaItem.key]?.message}
                >
                  {schemaItem.label && (
                    <FormLabel>{schemaItem.label}</FormLabel>
                  )}
                  <FormHelperText
                    visibility={
                      (!schemaItem.helperText && "hidden") || undefined
                    }
                    h={schemaItem.helperText ? undefined : 0}
                  >
                    {schemaItem.helperText || "-"}
                  </FormHelperText>
                  {schemaItem.type === "file" && (
                    <DathicFileDrop
                      fileFromOutside={_formValues[schemaItem.key]}
                      onChange={(value) =>
                        _setFormValues(schemaItem.key, value)
                      }
                      accept={schemaItem.fileAccept}
                      message={schemaItem.helperText}
                      // @ts-ignore
                      shouldUpload={false}
                      {...((typeof schemaItem.fileDropProps === "function"
                        ? schemaItem.fileDropProps(_formValues)
                        : schemaItem.fileDropProps) || {})}
                      onUrl={(url) => {
                        _setFormValues(schemaItem.key, url || "");
                        (typeof schemaItem.fileDropProps === "function"
                          ? schemaItem.fileDropProps(_formValues)
                          : schemaItem.fileDropProps
                        )?.onUrl?.(url);
                      }}
                    />
                  )}
                  {schemaItem.type === "date" && (
                    <DatePicker
                      onChange={(date) => {
                        if (date) {
                          date.setSeconds(0);
                          date.setMilliseconds(0);
                          _setFormValues(schemaItem.key, date);
                        } else {
                          _setFormValues(schemaItem.key, null);
                        }
                      }}
                      showTimeSelect
                      selected={_formValues[schemaItem.key]}
                      // @ts-ignore
                      customInput={
                        <CustomDateInput
                          _formValues={_formValues}
                          schemaItem={schemaItem}
                        />
                      }
                      isClearable
                    />
                  )}
                  {schemaItem.type === "checkboxGrid" && (
                    <CheckboxGridInput
                      value={_formValues[schemaItem.key]}
                      name={schemaItem.key}
                      options={schemaItem.options}
                      onChange={(value) =>
                        _setFormValues(schemaItem.key, value)
                      }
                      refForCheckboxes={register(schemaItem.key, {
                        required: false,
                        ...(schemaItem.registerOptions || {}),
                      })}
                      {...(schemaItem.inputProps || {})}
                    />
                  )}
                  {schemaItem.type === "longString" && (
                    // @ts-ignore
                    <Textarea
                      value={_formValues[schemaItem.key]}
                      placeholder={schemaItem.placeholder}
                      {...{
                        ...register(schemaItem.key, {
                          required: false,
                          ...(schemaItem.registerOptions || {}),
                        }),
                        ...(schemaItem.inputProps || {}),
                      }}
                      onChange={(event) =>
                        _setFormValues(schemaItem.key, event.target.value)
                      }
                      disabled={
                        typeof schemaItem.disabled === "function"
                          ? schemaItem.disabled(_formValues)
                          : schemaItem.disabled
                      }
                    />
                  )}
                  {schemaItem.type === "string" && !schemaItem.options?.length && (
                    <Input
                      value={_formValues[schemaItem.key]}
                      disabled={
                        typeof schemaItem.disabled === "function"
                          ? schemaItem.disabled(_formValues)
                          : schemaItem.disabled
                      }
                      placeholder={schemaItem.placeholder}
                      {...{
                        ...register(schemaItem.key, {
                          required: false,
                          ...(schemaItem.registerOptions || {}),
                        }),
                        ...(schemaItem.inputProps || {}),
                      }}
                      onChange={(event) =>
                        _setFormValues(schemaItem.key, event.target.value)
                      }
                    />
                  )}
                  {schemaItem.type === "string" &&
                    !!schemaItem.options?.length && (
                      <Select
                        value={_formValues[schemaItem.key]}
                        disabled={
                          typeof schemaItem.disabled === "function"
                            ? schemaItem.disabled(_formValues)
                            : schemaItem.disabled
                        }
                        placeholder={schemaItem.placeholder}
                        onChange={(event) =>
                          _setFormValues(schemaItem.key, event.target.value)
                        }
                      >
                        {schemaItem.placeholder && (
                          <option value="" selected disabled hidden>
                            {schemaItem.placeholder}
                          </option>
                        )}
                        {schemaItem.options.map((option) => (
                          <option key={option} value={option}>
                            {startCase(option)}
                          </option>
                        ))}
                      </Select>
                    )}
                  {schemaItem.type === "multipleSelect" &&
                    schemaItem.options?.length && (
                      <CheckboxGroup
                        value={_formValues[schemaItem.key]}
                        isDisabled={
                          typeof schemaItem.disabled === "function"
                            ? schemaItem.disabled(_formValues)
                            : schemaItem.disabled
                        }
                        onChange={(newValues) => {
                          _setFormValues(schemaItem.key, newValues);
                        }}
                      >
                        <HStack>
                          {schemaItem.options.map((option) => (
                            <Checkbox key={option.key} value={option.key}>
                              {option.value}
                            </Checkbox>
                          ))}
                        </HStack>
                      </CheckboxGroup>
                    )}
                  {schemaItem.type === "number" && (
                    <NumberInput
                      // @ts-ignore
                      value={
                        !_formValues[schemaItem.key] &&
                        _formValues[schemaItem.key] !== 0
                          ? undefined
                          : `${_formValues[schemaItem.key]}`
                      }
                      disabled={
                        typeof schemaItem.disabled === "function"
                          ? schemaItem.disabled(_formValues)
                          : schemaItem.disabled
                      }
                      placeholder={schemaItem.placeholder}
                      pattern="-?[0-9]+(.[0-9]+)?"
                      {...{
                        ...register(schemaItem.key, {
                          required: false,
                          ...(schemaItem.registerOptions || {}),
                        }),
                        ...(schemaItem.inputProps || {}),
                      }}
                      onChange={(str, num) => {
                        _setFormValues(
                          schemaItem.key,
                          isNaN(num) ? undefined : num
                        );
                      }}
                    >
                      <NumberInputField />
                    </NumberInput>
                  )}
                  <FormErrorMessage>
                    {errors?.[schemaItem.key]?.message}
                  </FormErrorMessage>
                </FormControl>
              )}
            </GridItem>
          )
        )}
    </SimpleGrid>
  );

  const SubmitButton = (props: SubmitButtonProps) => {
    const { buttonProps, isLoading, text, submitFromOutside } = props;
    return RenderSubmitButton ? (
      <RenderSubmitButton {...props} />
    ) : (
      <Button
        ml={3}
        colorScheme="blue"
        {...(buttonProps || {})}
        type="submit"
        visibility={submitFromOutside ? "hidden" : "visible"}
        ref={submitBtn}
        isLoading={isLoading}
      >
        {text || "Save"}
      </Button>
    );
  };
  return (
    <FormProvider {...formMethods}>
      <form
        name={formName}
        onSubmit={handleSubmit(handleOnSubmit)}
        style={{ width: "100%", height: "100%", overflowX: "auto" }}
      >
        <Flex flexDir="column" w="100%" h="100%">
          {renderForm()}
          <Spacer />
          <Flex
            direction="row"
            bg="white"
            p={5}
            w="100%"
            borderTop="1px solid gainsboro"
            visibility={submitFromOutside ? "hidden" : "visible"}
          >
            {footerContent}
            <Spacer />
            <SubmitButton
              buttonProps={submitBtnProps}
              submitFromOutside={submitFromOutside}
              text={submitBtnText}
              isLoading={isLoading}
            />
          </Flex>
        </Flex>
      </form>
    </FormProvider>
  );
}
