import { Button } from "@chakra-ui/button";
import {
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { Box, Text, VStack } from "@chakra-ui/layout";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Badge,
  Divider,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  ModalHeader,
  Select,
  Tag,
  TagLabel,
  TagRightIcon,
  Tooltip,
} from "@chakra-ui/react";
import { selectRow } from "ka-table/actionCreators";
import { DataType } from "ka-table/enums";
import { ICellEditorProps } from "ka-table/props";
import { pick, startCase } from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IoMdAlert } from "react-icons/io";
import { IoCheckmarkCircle, IoFilter } from "react-icons/io5";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  removeOpenUploadProxy,
  removeStatusMessage,
  selectAccessToken,
  selectOrgProperties,
  selectProducts,
  selectUserResources,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  BatchActionRenderProps,
  CustomColumn,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import { ResponsiveModal } from "../../components/responsive-modal";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { RecommendationProductStore } from "../../domain/RecommendationProductStore";
import { Store } from "../../domain/Store";
import { StoreLocatorRecommendationStore } from "../../domain/StoreLocatorRecommendationStore";
import { StoreLocatorStoreRecommendation } from "../../domain/StoreLocatorStoreRecommendation";
import { trackClick } from "../../services/tracking.service";
import { camelCaseWords } from "../../utils/stringUtils";
import StoreFilterByProduct from "./StoreFilterByProduct";
import { useStoreRecommendations } from "./StoreRecommendationsHooks";

const ToggleStoreApproval = ({
  selectedRows,
  onToggleFinish,
  type,
}: {
  selectedRows: Store[];
  onToggleFinish: (updatedStores: Store[]) => void;
  type: string;
}) => {
  const userResources = useAppSelector(selectUserResources);

  const batchUpdateStores = (
    stores: Store[],
    newStatus: "approve" | "deny" | ""
  ) => {
    onToggleFinish(
      // @ts-ignore
      stores.map((s) => {
        s.status = newStatus;
        return {
          ...s,
          productRelations: s.productRelations?.map((pr) => ({
            ...pr,
            is_approved:
              newStatus === "approve"
                ? true
                : newStatus === "deny"
                ? false
                : undefined,
          })),
        };
      })
    );
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<CheckIcon size="1rem" />}
        isDisabled={
          !selectedRows.length ||
          !userResources?.includes(RESOURCES.STORES_UPDATE)
        }
        aria-label={"Batch recommendation approval or denial button"}
      >
        Mark to {type === "new" ? "add" : "disable"} or deny
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => batchUpdateStores(selectedRows, "")}>
          Ignore this time
        </MenuItem>
        <MenuItem
          icon={<CheckIcon size="1rem" />}
          onClick={() => batchUpdateStores(selectedRows, "approve")}
          color="green"
        >
          Mark to {type === "new" ? "add" : "disable"}
        </MenuItem>
        <MenuItem
          icon={<CloseIcon size="1rem" />}
          onClick={() => batchUpdateStores(selectedRows, "deny")}
          color="red"
        >
          Deny (wont {type === "new" ? "add" : "disable"})
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const BatchStoreAction = ({
  selectedRows,
  onToggleFinish,
  type,
  recommendationType,
}: {
  selectedRows: Store[];
  onToggleFinish: (updatedStores: Store[]) => void;
  type: "approve" | "deny" | "";
  recommendationType: "new" | "discontinued";
}) => {
  const userResources = useAppSelector(selectUserResources);

  const batchUpdateStores = (
    stores: Store[],
    newStatus: "approve" | "deny" | ""
  ) => {
    onToggleFinish(
      // @ts-ignore
      stores.map((s) => {
        s.status = newStatus;
        return {
          ...s,
          productRelations: s.productRelations?.map((pr) => ({
            ...pr,
            is_approved:
              newStatus === "approve"
                ? true
                : newStatus === "deny"
                ? false
                : undefined,
          })),
        };
      })
    );
  };
  return (
    <Button
      leftIcon={
        type === "approve" ? (
          <CheckIcon size="1rem" />
        ) : type === "deny" ? undefined : (
          <TimeIcon size="1rem" />
        )
      }
      isDisabled={
        !selectedRows.length ||
        !userResources?.includes(RESOURCES.STORES_UPDATE) ||
        selectedRows.every((row) => row.status === type)
      }
      aria-label={"Batch store recommendation approval or denial button"}
      onClick={() => batchUpdateStores(selectedRows, type)}
    >
      {type
        ? (type === "approve" ? "Mark to " : "Deny: wont ") +
          (recommendationType === "new" ? "add" : "disable")
        : "Ignore"}
    </Button>
  );
};

const StatusSelect = (
  props: ICellEditorProps & {
    isEditing?: boolean;
    data?: any[];
    setData: (data: any[] | undefined) => void;
    type: string;
  }
) => {
  const { rowData, data, setData, type } = props;
  return (
    <Select
      defaultValue={rowData.status}
      value={rowData?.status}
      onChange={(event) => {
        const newData = { ...(rowData || {}) };
        newData.status = event.currentTarget.value;
        newData.productRelations = newData.productRelations?.map(
          (pr: StoreLocatorStoreRecommendation) => ({
            ...pr,
            is_approved:
              newData?.status === "approve"
                ? true
                : newData?.status === "deny"
                ? false
                : undefined,
          })
        );
        const dataToSet = [...(data || [])];
        dataToSet[(data || []).findIndex((item) => item.id === rowData.id)] =
          newData;
        setData(dataToSet);
      }}
    >
      <option value="">Ignore this time</option>
      {["approve", "deny"].map((option) => (
        <option key={option} value={option}>
          {option === "approve"
            ? "Mark to " + (type === "new" ? "add" : "disable")
            : "Deny (wont " + (type === "new" ? "add" : "disable") + ")"}
        </option>
      ))}
    </Select>
  );
};

const ManageProductsMessage = ({
  productRelations,
  store,
  setProductRelations,
  storeLabel,
  approvedAction,
  type,
}: {
  productRelations: any;
  store: any;
  setProductRelations: (relations: any[] | undefined) => void;
  storeLabel: string;
  approvedAction: string;
  type: string;
}) => {
  const batchActions = useMemo(() => {
    return [
      {
        Render: ({
          selectedRows,
          data,
          dispatch,
        }: BatchActionRenderProps<Partial<Store>>) => {
          const selectedIds = selectedRows.map((s) => s.id);
          const notSelectedIgnored =
            data?.filter(
              (s) =>
                s.status !== "approve" &&
                s.status !== "deny" &&
                !selectedIds.includes(s.id)
            ) || [];
          return notSelectedIgnored.length ? (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<ChevronDownIcon size="1rem" />}
                aria-label={
                  "Batch product recommendation approval or denial button"
                }
                variant="link"
              ></MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() =>
                    notSelectedIgnored.forEach((s) => dispatch(selectRow(s.id)))
                  }
                >
                  Select all ignored
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <></>
          );
        },
      },
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <ToggleStoreApproval
            selectedRows={
              productRelations?.filter((store: { id: any }) =>
                selectedRows.find((s) => s.id === store.id)
              ) || []
            }
            onToggleFinish={(newRows) => {
              setProductRelations(
                data?.map((d) => newRows?.find((n) => n.id === d.id) || d)
              );
            }}
            type={type}
          />
        ),
      },
    ];
  }, [productRelations, setProductRelations, type]);

  const columns: CustomColumn<any>[] = [
    {
      title: "ID",
      key: "id",
      visible: false,
    },
    {
      title: "Status",
      dataType: DataType.String,
      key: "status",
      info: `Products that you approve will be ${approvedAction} to the ${storeLabel}`,
      filterOptions: ["approve", "deny"].map((status) => ({
        value: status,
        label: status === "approve" ? "Approved" : "Denied",
      })),
      Render: (p: any) => (
        <StatusSelect {...p} setData={setProductRelations} type={type} />
      ),
      format: (value: string) => camelCaseWords(value),
      default: "None",
    },
    {
      title: "Name",
      key: "name",
    },
  ];
  return (
    <VStack w="100%">
      <Text>
        For {storeLabel}: {store.chain}, {store.address}
      </Text>
      <EntityTable
        initialTableProps={{
          columns,
        }}
        containerProps={{ height: "20rem" }}
        allowBatchActions
        additionalBatchActions={batchActions}
        entityName="Product"
        dataFromOutside={productRelations}
      />
    </VStack>
  );
};

const ManageProductsButton = ({
  rowData,
  type,
  storeLabel,
  onFinish,
  approvedAction,
}: ICellEditorProps & {
  type: Props["type"];
  storeLabel: string;
  onFinish: (relations: any[] | undefined) => void;
  approvedAction: string;
}) => {
  const products = useAppSelector(selectProducts);
  const messageModalContext = useContext(MessageModalContext);
  return rowData.productRelations?.length ? (
    <Button
      onClick={() => {
        messageModalContext.showModal({
          title: `Mark to ${type === "new" ? "add" : "disable"} products`,
          message: (dialogState, setDialogState) => (
            <ManageProductsMessage
              store={dialogState?.store}
              productRelations={dialogState?.productRelations}
              setProductRelations={(relations: any[] | undefined) => {
                setDialogState((oldState: any) => ({
                  ...(oldState || {}),
                  productRelations: relations,
                }));
              }}
              approvedAction={approvedAction}
              storeLabel={storeLabel}
              type={type}
            />
          ),
          initialDialogState: {
            store: rowData,
            productRelations: rowData.productRelations?.map(
              (r: RecommendationProductStore) => {
                return {
                  ...(products.find((p) => p.id === r.product_id) || {}),
                  confidence_level: r.confidence_level,
                  status: r.is_approved
                    ? "approve"
                    : typeof r.is_approved === "boolean" && !r.is_approved
                    ? "deny"
                    : "",
                };
              }
            ),
          },
          actions: [
            {
              label: "Confirm",
              callback: (_, dialogState) =>
                onFinish(dialogState.productRelations),
            },
            { label: "Cancel", isLeastDestructive: true },
          ],
        });
      }}
    >
      {pluralize("Product", rowData.productRelations?.length, true)}
    </Button>
  ) : (
    <Text>
      No {type} products in this {storeLabel}
    </Text>
  );
};

type Props = {
  onFinish?: Function;
  onClose?: Function;
  type: "new" | "discontinued";
  confidenceLevelsDescription: { [key: string]: string };
};

type ConfirmMessageProps = {
  storeLabel: string;
  approvedAction: string;
  stores?: Store[];
};

function ConfirmMessage({
  storeLabel,
  stores,
  approvedAction,
}: ConfirmMessageProps) {
  const ignoredStores = stores?.filter(
    (s) => s.status !== "deny" && s.status !== "approve"
  );

  return (
    <VStack divider={<Divider />}>
      <VStack>
        <Text fontSize={28} fontWeight="bold" color="blue.400" w="100%">
          {stores?.filter((s) => s.status === "approve")?.length || 0} Approved{" "}
          <CheckIcon />
        </Text>
        <Text>
          {pluralize(storeLabel)} you approve will be {approvedAction}{" "}
          {approvedAction === "disabled" ? "from" : "to"} your locations.
        </Text>
      </VStack>
      <VStack>
        <Text fontSize={28} fontWeight="bold" color="blue.400" w="100%">
          {stores?.filter((s) => s.status === "deny")?.length || 0} Denied
        </Text>
        <Text>
          {pluralize(storeLabel)} you deny will not be {approvedAction}{" "}
          {approvedAction === "disabled" ? "from" : "to"} your locations and
          will be hidden from future recommendations.
        </Text>
      </VStack>
      <VStack>
        <Text fontSize={28} fontWeight="bold" color="blue.400" w="100%">
          {ignoredStores?.length || 0} Pending <TimeIcon />
        </Text>
        <Text>
          {pluralize(storeLabel)} pending will not be {approvedAction}{" "}
          {approvedAction === "disabled" ? "from" : "to"} your locations and can
          be reviewed later.
        </Text>
      </VStack>
    </VStack>
  );
}

const joinNewAndOldStores = (newRows: Store[], oldRows: Store[]) =>
  (Array.from(new Set([...newRows, ...(oldRows || [])].map((s) => s.id)))
    .map((id) => [...newRows, ...(oldRows || [])].find((s) => s.id === id))
    .filter((s) => !!s) as Store[]) || [];

const ConfidenceDescriptionInfo = ({
  confidenceLevelsDescription,
}: {
  confidenceLevelsDescription: { [key: string]: string };
}) => {
  const { High, Medium, Low, ...rest } = confidenceLevelsDescription;
  return (
    <VStack fontWeight={"normal"}>
      <Text>
        <strong>High</strong>: {High}
      </Text>
      <Text>
        <strong>Medium</strong>: {Medium}
      </Text>
      <Text>
        <strong>Low</strong>: {Low}
      </Text>
      {Object.entries(rest).map((entry) => (
        <Text>
          <strong>{entry[0]}</strong>: {entry[1]}
        </Text>
      ))}
    </VStack>
  );
};

export const ConfidenceFilterIcon = ({
  confidenceLevel,
  gradientKey,
}: {
  confidenceLevel: "High" | "Medium" | "Low";
  gradientKey: any;
}) => {
  // Define gradient stops based on confidence level
  const getGradientStops = (level: "High" | "Medium" | "Low") => {
    switch (level) {
      case "Low":
        return ["66%", "lightgray", "gray"]; // Bottom 33% filled
      case "Medium":
        return ["33%", "lightgray", "orange"]; // Bottom 66% filled
      case "High":
        return ["0%", "red", "red"]; // Fully filled
      default:
        return ["100%", "lightgray", "lightgray"]; // Default
    }
  };

  const [offset, color1, color2] = getGradientStops(confidenceLevel);

  return (
    <Box
      as="svg"
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fontSize={24}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Define a linear gradient */}
      <defs>
        <linearGradient
          id={gradientKey}
          gradientTransform="rotate(90, 0.5, 0.5)"
        >
          <stop offset={offset} stopColor={color1} />
          <stop offset={offset} stopColor={color2} />
        </linearGradient>
      </defs>
      <IoFilter
        style={{
          fill: "url(#" + gradientKey + ")",
        }}
      />
    </Box>
  );
};

const StoreFilterByChain: FunctionComponent<{
  stores: Store[];
  exclude?: string[];
  only?: string[];
  value: string[];
  onChange: (newFiltered: string[]) => void;
}> = ({ value, onChange, exclude, only, stores }) => {
  const [searchText, setSearchText] = useState<string>("");
  const [chains] = useState(
    Object.entries(
      stores.reduce((acc, s) => {
        return {
          ...acc,
          [s.chain || "Independent"]: [
            (acc?.[s.chain || "Independent"]?.[0] || 0) +
              (s.confidence_level === "High" ? 1 : 0),
            (acc?.[s.chain || "Independent"]?.[1] || 0) +
              (s.confidence_level === "Medium" ? 1 : 0),
            (acc?.[s.chain || "Independent"]?.[2] || 0) +
              (s.confidence_level === "Low" ? 1 : 0),
          ] as [number, number, number],
        };
      }, {} as { [key: string]: [number, number, number] })
    ).sort(
      (a, b) => b[1][0] + b[1][1] + b[1][2] - (a[1][0] + a[1][1] + a[1][2])
    )
  );

  return (
    <Menu closeOnSelect={false} placement="bottom-start">
      <MenuButton as={Button}>
        By chain{value.length ? ` (${value.length})` : ""}
      </MenuButton>
      <MenuList
        width="300px"
        maxH="400px"
        display="flex"
        flexDirection="column"
      >
        <Box h={8} w="100%">
          <Input
            placeholder="Search chain"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            h={8}
          />
        </Box>
        <MenuDivider />
        <Box w="100%" flex={1} overflowY="auto">
          <MenuOptionGroup
            title="Chains"
            type="checkbox"
            value={value}
            onChange={(newFilteredProducts) =>
              onChange(
                typeof newFilteredProducts === "string"
                  ? [newFilteredProducts]
                  : newFilteredProducts
              )
            }
          >
            {chains
              .filter((p) => value.includes(`${p[0]}`))
              .map((p) => (
                <MenuItemOption value={`${p[0]}`}>
                  <Text
                    noOfLines={3}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxH="4.5em"
                    fontSize="sm"
                  >
                    {p[0]} ({p[1][0]} High, {p[1][1]} Medium, {p[1][2]} Low)
                  </Text>
                </MenuItemOption>
              ))}
            {chains
              .filter(
                (p) =>
                  p[0].toLowerCase().includes(searchText.toLowerCase()) &&
                  !value.includes(`${p[0]}`) &&
                  (!exclude?.length || exclude.find((e) => e !== p[0])) &&
                  (!only || only.includes(p[0]))
              )
              .map((p) => (
                <MenuItemOption value={`${p[0]}`}>
                  <Text
                    noOfLines={3}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxH="4.5em"
                  >
                    {p[0]} ({p[1][0]} High, {p[1][1]} Medium, {p[1][2]} Low)
                  </Text>
                </MenuItemOption>
              ))}
          </MenuOptionGroup>
        </Box>
      </MenuList>
    </Menu>
  );
};

export const StoreRecommendationsProxy = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { onClose, onFinish, type, confidenceLevelsDescription } = props;
  const dispatch = useAppDispatch();
  const {
    getStores,
    storeAttrName,
    proxyName,
    fulfill,
    approvedAction,
    approveOrDeny,
    approveOrDenyRelations,
  } = useStoreRecommendations(type);
  const messageModalContext = useContext(MessageModalContext);
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const [stores, setStores] = useState<Store[] | undefined>();
  const [confidenceLevelFilter, setConfidenceLevelFilter] =
    useState<string>("");
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [filteredProducts, setFilteredProducts] = useState<string[]>([]);
  const [filteredChains, setFilteredChains] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const statesLabel = useMemo(
    () => orgProperties?.properties?.statesNameReplacement || "State",
    [orgProperties]
  );
  const storeLabel = useMemo(
    () => orgProperties?.properties?.storeNameReplacement || "Store",
    [orgProperties]
  );
  const zipcodeLabel = useMemo(
    () => orgProperties?.properties?.zipcodeNameReplacement || "Zipcode",
    [orgProperties]
  );

  const columns: CustomColumn<any>[] = useMemo(
    () => [
      {
        title: "ID",
        key: "id",
        visible: false,
      },
      ...(type === "discontinued"
        ? [
            {
              title: "Delisted Likelihood",
              key: "confidence_level",
              info: confidenceLevelsDescription ? (
                <ConfidenceDescriptionInfo
                  confidenceLevelsDescription={confidenceLevelsDescription}
                />
              ) : undefined,
              filterOptions: ["High", "Medium", "Low"].map((level) => ({
                value: level,
                label: level,
              })),
              Render: (p: any) => {
                return (
                  <VStack spacing={0}>
                    <Text>{p.rowData.confidence_level}</Text>
                    <ConfidenceFilterIcon
                      confidenceLevel={p.rowData.confidence_level}
                      gradientKey={p.rowData.id + "-confidence-filter"}
                    />
                  </VStack>
                );
              },
            },
          ]
        : []),
      {
        title: "Status",
        dataType: DataType.String,
        key: "status",
        info: `${pluralize(
          storeLabel
        )} that you approve will be ${approvedAction} to your ${storeLabel} Locator`,
        filterOptions: ["approve", "deny"].map((status) => ({
          value: status,
          label: status === "approve" ? "Approved" : "Denied",
        })),
        Render: (p: any) => (
          <StatusSelect
            {...p}
            setData={(data) => setStores(data)}
            type={type}
          />
        ),
        format: (value: string) => camelCaseWords(value),
        default: "None",
      },
      {
        title: "Name",
        key: "name",
      },
      {
        title: "Chain",
        key: "chain",
      },
      {
        title: "Address",
        key: "address",
      },
      {
        title: pluralize(statesLabel, 1),
        key: "state",
      },
      {
        title: "City",
        key: "city",
      },
      {
        title: pluralize(zipcodeLabel, 1),
        key: "zipcode",
      },
      {
        title: "Notification date",
        key: "created_at",
        dataType: DataType.Date,
      },
      {
        title: startCase(`Products`),
        key: "productRelations",
        hideFilter: true,
        Render: (p: ICellEditorProps) => (
          <ManageProductsButton
            {...p}
            type={type}
            storeLabel={storeLabel}
            approvedAction={approvedAction}
            onFinish={(relations) =>
              setStores((oldStores) => {
                const storeIndex = oldStores?.findIndex(
                  (s) => s.id === p.rowData.id
                );
                if (storeIndex && storeIndex > -1) {
                  const newStores = [...(oldStores || [])];
                  newStores.splice(storeIndex, 1, {
                    ...newStores[storeIndex],
                    productRelations: newStores[
                      storeIndex
                    ].productRelations?.map((pr) => {
                      const relation = relations?.find(
                        (r) => r.id === pr.product_id
                      );
                      return {
                        ...pr,
                        is_approved:
                          relation?.status === "approve"
                            ? true
                            : relation?.status === "deny"
                            ? false
                            : undefined,
                      };
                    }),
                  } as unknown as Store);
                  return newStores;
                }
                return [];
              })
            }
          />
        ),
      },
    ],
    [approvedAction, statesLabel, storeLabel, type, zipcodeLabel]
  );

  useEffect(() => {
    if (!stores && !isLoading && accessToken) {
      setIsLoading(true);
      getStores(accessToken)
        .then((recommendations?: StoreLocatorRecommendationStore[]) => {
          if (!recommendations) {
            setStores([]);
            return;
          }
          const newStores = recommendations.map((r) => {
            return pick(
              {
                ...(r[storeAttrName] || {}),
                confidence_level: r.confidence_level,
                status: r.is_approved
                  ? "approve"
                  : typeof r.is_approved === "boolean" && !r.is_approved
                  ? "deny"
                  : "",
                productRelations: r.productRelations?.map((pr) => ({
                  ...pr,
                  confidence_level: r.confidence_level,
                })),
                created_at:
                  typeof r.created_at === "string"
                    ? DateTime.fromISO(r.created_at).toJSDate()
                    : r.created_at,
              },
              ["confidence_level", ...columns.map((c) => c.key)]
            ) as Store;
          });
          setStores(newStores);
        })
        .catch(() => setStores([]))
        .finally(() => setIsLoading(false));
    }
  }, [accessToken, columns, getStores, isLoading, storeAttrName, stores]);

  const approveOrDenyStores = async () => {
    const toApprove = stores
      ?.filter((s) => {
        return s.status === "approve";
      })
      .map((s) => s.id);
    const toDeny = stores
      ?.filter((s) => {
        return s.status === "deny";
      })
      .map((s) => s.id);
    if (toApprove?.length) {
      await approveOrDeny(true, accessToken, toApprove);
    }
    if (toDeny?.length) {
      await approveOrDeny(false, accessToken, toDeny);
    }
    const relationsToApprove = stores
      ?.flatMap((s) => s.productRelations || [])
      ?.filter((s) => {
        return !!s.is_approved;
      })
      .map((s) => s.id);
    const relationsToDeny = stores
      ?.flatMap((s) => s.productRelations || [])
      ?.filter((s) => {
        return s.is_approved === false;
      })
      .map((s) => s.id);
    if (relationsToApprove?.length) {
      await approveOrDenyRelations(true, accessToken, relationsToApprove);
    }
    if (relationsToDeny?.length) {
      await approveOrDenyRelations(false, accessToken, relationsToDeny);
    }
    return {
      approved: toApprove || [],
      denied: toDeny || [],
      relationsApproved: relationsToApprove || [],
      relationsDenied: relationsToDeny || [],
    };
  };

  const _onClose = () => {
    approveOrDenyStores().then();
    onClose?.();
    dispatch(removeOpenUploadProxy(proxyName));
  };
  const onFinishBatchAction = useCallback(
    (newRows: Store[], oldRows?: Store[]) => {
      setStores(joinNewAndOldStores(newRows, oldRows || []));
    },
    []
  );

  const batchActions = useMemo(() => {
    return [
      {
        Render: ({
          selectedRows,
          data,
          dispatch,
        }: BatchActionRenderProps<Partial<Store>>) => {
          const selectedIds = selectedRows.map((s) => s.id);
          const notSelectedIgnored =
            data?.filter(
              (s) =>
                s.status !== "approve" &&
                s.status !== "deny" &&
                !selectedIds.includes(s.id)
            ) || [];
          return notSelectedIgnored.length ? (
            <Menu>
              <MenuButton
                as={IconButton}
                icon={<ChevronDownIcon size="1rem" />}
                aria-label={
                  "Batch store recommendation approval or denial button"
                }
                variant="link"
              ></MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() =>
                    notSelectedIgnored.forEach((s) => dispatch(selectRow(s.id)))
                  }
                >
                  Select all ignored
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <></>
          );
        },
      },
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <BatchStoreAction
            selectedRows={
              stores?.filter((store) =>
                selectedRows.find((s) => s.id === store.id)
              ) || []
            }
            onToggleFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
            type="approve"
            recommendationType={type}
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <BatchStoreAction
            recommendationType={type}
            selectedRows={
              stores?.filter((store) =>
                selectedRows.find((s) => s.id === store.id)
              ) || []
            }
            onToggleFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
            type="deny"
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <BatchStoreAction
            recommendationType={type}
            selectedRows={
              stores?.filter((store) =>
                selectedRows.find((s) => s.id === store.id)
              ) || []
            }
            onToggleFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
            type=""
          />
        ),
      },
    ];
  }, [onFinishBatchAction, stores, type]);

  function navigateToStores(index: number) {
    if (location.pathname.includes("storelocator")) {
      navigate(`/storelocator/stores`, {
        state: {
          tabIndex: index,
        },
      });
      return;
    }
    navigate(`/StoresTabs/stores-manage`, {
      state: {
        tabIndex: index,
      },
    });
  }

  const handleAdd = async () => {
    setIsLoading(true);
    let approvalResult: { approved: number[]; denied: number[] };
    approveOrDenyStores()
      .then((_approvalResult) => {
        approvalResult = _approvalResult;
        return fulfill(accessToken);
      })
      .then(() => {
        dispatch(removeStatusMessage("recommendedstores"));
        messageModalContext.showModal({
          title: "Success",
          variant: "success",
          message: `Successfully ${approvedAction} ${pluralize(
            storeLabel,
            approvalResult.approved.length,
            true
          )}, denied ${pluralize(
            storeLabel,
            approvalResult.denied.length,
            true
          )}, and ignored ${pluralize(
            storeLabel,
            (stores?.length || 0) -
              approvalResult.approved.length -
              approvalResult.denied.length,
            true
          )} to review later.`,
          actions: [
            {
              label: `Manage ${pluralize(storeLabel)}`,
              callback: () => {
                navigateToStores(1);
              },
            },
          ],
        });
        if (window.location.pathname.includes("stores")) {
          navigateToStores(0);
          navigateToStores(1);
        }
        _onClose();
        onFinish?.();
      })
      .catch(() => {
        messageModalContext.showModal({
          title: `There was a problem approving or adding your stores`,
          message: "If you continue to have problems, please contact support.",
          variant: "error",
          actions: [
            {
              label: `Try again`,
            },
          ],
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const extendedFilter = useCallback(
    (rows: Partial<Store>[]) =>
      rows.filter((s) => {
        const storeProducts =
          stores?.find((store) => store.id === s.id)?.productRelations || [];
        return !(
          (filteredProducts.length &&
            !filteredProducts.every((fp) =>
              storeProducts.find((ps: any) => ps.product_id === +fp)
            )) ||
          (filteredChains.length &&
            !filteredChains.includes(s.chain || "Independent"))
        );
      }),
    [filteredChains, filteredProducts, stores]
  );

  return (
    <ResponsiveModal
      isOpen
      onClose={_onClose}
      isCentered
      scrollBehavior="inside"
      size="full"
    >
      <ModalOverlay />
      <ModalContent h="100%">
        <ModalCloseButton
          onClick={() => {
            _onClose();
          }}
        />

        <ModalHeader textAlign="center">
          {startCase(type)} locations recommended for your review
        </ModalHeader>

        <ModalBody>
          <VStack spacing={5} h="100%">
            <HStack justifyContent="space-between" w="100%">
              <HStack spacing={5}>
                <Tooltip
                  label={`The total of recommendations items for you to review`}
                >
                  <Tag
                    variant="subtle"
                    colorScheme={selectedFilter === "" ? "blue" : undefined}
                    size="lg"
                    onClick={() => {
                      setSelectedFilter("");
                      setConfidenceLevelFilter("");
                    }}
                    cursor="pointer"
                    border={selectedFilter === "" ? "2px" : undefined}
                  >
                    <Text fontSize={18} fontWeight="bold" mr={2}>
                      {stores?.filter(
                        (s) => s.status !== "deny" && s.status !== "approve"
                      )?.length || 0}
                    </Text>
                    <TagLabel>for review</TagLabel>
                    <TagRightIcon as={IoMdAlert} />
                  </Tag>
                </Tooltip>
                <Tooltip
                  label={`Approved items will be ${approvedAction} ${
                    approvedAction === "disabled" ? "from" : "to"
                  } your locations.`}
                >
                  <Tag
                    variant="subtle"
                    size="lg"
                    colorScheme={
                      selectedFilter === "approve" ? "blue" : undefined
                    }
                    onClick={() => {
                      setSelectedFilter("approve");
                      setConfidenceLevelFilter("");
                    }}
                    cursor="pointer"
                    border={selectedFilter === "approve" ? "2px" : undefined}
                  >
                    <TagLabel>
                      <Badge fontSize="18">
                        {stores?.filter((s) => s.status === "approve")
                          ?.length || 0}
                      </Badge>{" "}
                      marked to {type === "new" ? "add" : "disable"}
                    </TagLabel>
                    <TagRightIcon as={IoCheckmarkCircle} />
                  </Tag>
                </Tooltip>
                <Tooltip
                  label={`Denied items will be hidden from future recommendations`}
                >
                  <Tag
                    variant="subtle"
                    size="lg"
                    colorScheme={selectedFilter === "deny" ? "blue" : undefined}
                    onClick={() => {
                      setSelectedFilter("deny");
                      setConfidenceLevelFilter("");
                    }}
                    cursor="pointer"
                    border={selectedFilter === "deny" ? "2px" : undefined}
                  >
                    <TagLabel>
                      <Badge fontSize="18">
                        {stores?.filter((s) => s.status === "deny")?.length ||
                          0}
                      </Badge>{" "}
                      denied (wont {type === "new" ? "add" : "disable"})
                    </TagLabel>
                  </Tag>
                </Tooltip>
              </HStack>
              <Text>
                Don't forget to click{" "}
                <Tag variant="subtle" size="lg">
                  <TagLabel fontSize={18}>save and confirm</TagLabel>
                </Tag>{" "}
                👇 to confirm your review.
              </Text>
            </HStack>
            {type === "discontinued" && (
              <HStack justifyContent="space-between" w="100%">
                <HStack spacing={5}>
                  <Tooltip label={confidenceLevelsDescription?.High}>
                    <Tag
                      variant="subtle"
                      colorScheme="red"
                      size="lg"
                      onClick={() =>
                        setConfidenceLevelFilter((oldValue) => {
                          if (oldValue === "High") return "";
                          return "High";
                        })
                      }
                      border={
                        confidenceLevelFilter === "High" ? "2px" : undefined
                      }
                      cursor="pointer"
                    >
                      <Text fontSize={18} fontWeight="bold" mr={2}>
                        {stores?.filter(
                          (s) =>
                            s.confidence_level === "High" &&
                            s.status !== "deny" &&
                            s.status !== "approve"
                        )?.length || 0}
                      </Text>
                      <TagLabel>most likely delisted</TagLabel>
                      <TagRightIcon as={IoMdAlert} />
                    </Tag>
                  </Tooltip>
                  <Tooltip label={confidenceLevelsDescription?.Medium}>
                    <Tag
                      variant="subtle"
                      colorScheme="orange"
                      size="lg"
                      onClick={() => {
                        setConfidenceLevelFilter((oldValue) => {
                          if (oldValue === "Medium") return "";
                          messageModalContext.showModal({
                            title: `Medium likelihood`,
                            message: confidenceLevelsDescription?.Medium,
                          });
                          return "Medium";
                        });
                      }}
                      border={
                        confidenceLevelFilter === "Medium" ? "2px" : undefined
                      }
                      cursor="pointer"
                    >
                      <Text fontSize={18} fontWeight="bold" mr={2}>
                        {stores?.filter(
                          (s) =>
                            s.confidence_level === "Medium" &&
                            s.status !== "deny" &&
                            s.status !== "approve"
                        )?.length || 0}
                      </Text>
                      <TagLabel>could be delisted</TagLabel>
                      <TagRightIcon as={IoMdAlert} />
                    </Tag>
                  </Tooltip>
                  <Tooltip label={confidenceLevelsDescription?.Low}>
                    <Tag
                      variant="subtle"
                      size="lg"
                      onClick={() => {
                        setConfidenceLevelFilter((oldValue) => {
                          if (oldValue === "Low") return "";
                          messageModalContext.showModal({
                            title: `Low likelihood`,
                            message: confidenceLevelsDescription?.Low,
                          });
                          return "Low";
                        });
                      }}
                      border={
                        confidenceLevelFilter === "Low" ? "2px" : undefined
                      }
                      cursor="pointer"
                    >
                      <Text fontSize={18} fontWeight="bold" mr={2}>
                        {stores?.filter(
                          (s) =>
                            s.confidence_level === "Low" &&
                            s.status !== "deny" &&
                            s.status !== "approve"
                        )?.length || 0}
                      </Text>
                      <TagLabel>least likely delisted</TagLabel>
                      <TagRightIcon as={IoMdAlert} />
                    </Tag>
                  </Tooltip>
                </HStack>
              </HStack>
            )}
            <Box flex={1} w="100%">
              <EntityTable
                initialTableProps={{
                  columns,
                }}
                containerProps={{ height: "100%" }}
                allowBatchActions
                additionalBatchActions={batchActions}
                loading={{ enabled: !stores }}
                entityName={storeLabel}
                extraFilters={[
                  <StoreFilterByProduct
                    value={filteredProducts}
                    onChange={setFilteredProducts}
                    only={
                      Array.from(
                        new Set(
                          stores
                            ?.flatMap((s) => s.productRelations)
                            .map((pr) => pr?.product_id)
                            .filter((id) => !!id) || []
                        )
                      ) as number[]
                    }
                  />,
                  <StoreFilterByChain
                    value={filteredChains}
                    onChange={(newValue) => {
                      setFilteredChains(newValue);
                      console.log(newValue);
                    }}
                    stores={stores || []}
                  />,
                ]}
                onClearFilters={() => setFilteredProducts([])}
                extendedFilter={
                  filteredProducts?.length || filteredChains?.length
                    ? extendedFilter
                    : undefined
                }
                dataFromOutside={stores?.filter(
                  (s) =>
                    (!confidenceLevelFilter ||
                      s.confidence_level === confidenceLevelFilter) &&
                    (!selectedFilter
                      ? !["approve", "deny"].includes(s.status || "")
                      : s.status === selectedFilter)
                )}
                setOutsideData={(newData) =>
                  setStores((oldData) =>
                    (newData || []).reduce<Store[]>((acc, s) => {
                      const foundStore = (oldData || []).find(
                        (store) => s.id === store.id
                      );
                      return foundStore
                        ? [...acc, { ...foundStore, ...s } as Store]
                        : acc;
                    }, [])
                  )
                }
              />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter borderTop="1px solid gainsboro" as={HStack}>
          <HStack flex={1} justifyContent="flex-end">
            <Button onClick={_onClose}>Cancel</Button>
            <Button
              onClick={() =>
                trackClick("recommendedstores-submit", "", () =>
                  messageModalContext.showModal({
                    title: `Summary`,
                    message: (
                      <ConfirmMessage
                        storeLabel={storeLabel}
                        stores={stores}
                        approvedAction={approvedAction}
                      />
                    ),
                    actions: [
                      { label: "Cancel" },
                      {
                        label: "Save and confirm",
                        props: { colorScheme: "blue" },
                        callback: () =>
                          trackClick("recommendedstores-confirm", "", () =>
                            handleAdd()
                          ),
                      },
                    ],
                  })
                )
              }
              colorScheme="blue"
              isLoading={isLoading}
            >
              Save and confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
};
