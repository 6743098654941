import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import {
  addOpenUploadProxy,
  addStatusMessage,
  selectAccessToken,
  selectCurrentUser,
  selectOrgProperties,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  getStoreLocatorBckwRecommendationsSummary,
  getStoreLocatorRecommendationsSummary,
} from "../../services/api.service";
import { PopoverTrigger } from "../org-screen/OrgOverview";

const DathicAutopilotPopover = () => (
  <Popover trigger="hover">
    <PopoverTrigger>
      <Button variant="link" color="white" textDecor="underline">
        Dathic Copilot
      </Button>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          Auto-populate your store locator in a few clicks scanning your brand
          in over 50k stores.
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
);

export const useRecommendationAlerts = (addAlerts = true) => {
  const currentUser = useAppSelector(selectCurrentUser);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const dispatch = useAppDispatch();
  const [discontinuedSummary, setDiscontinuedSummary] = useState<any>();

  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";

  useEffect(() => {
    const checkRecommendationSummary = async () => {
      const newStoresSummary = await getStoreLocatorRecommendationsSummary(
        accessToken
      );
      if (newStoresSummary?.new_stores) {
        dispatch(
          addStatusMessage({
            id: "recommendedstores",
            title: (
              <VStack>
                <Text>
                  New recommendations from <DathicAutopilotPopover />
                </Text>
                <Text fontWeight="extrabold" fontSize="18">
                  🎉 {newStoresSummary.new_stores} new{" "}
                  {pluralize(storeLabel, newStoresSummary.new_stores)}
                </Text>
              </VStack>
            ),
            action: {
              label: "Review & Approve",
              callback: () =>
                dispatch(
                  addOpenUploadProxy({
                    name: "StoreRecommendationsProxy",
                    props: { type: "new" },
                  })
                ),
            },
          })
        );
      }
    };
    const checkDiscontinuedSummary = async () => {
      const discontinuedStoresSummary =
        await getStoreLocatorBckwRecommendationsSummary(accessToken);
      setDiscontinuedSummary(discontinuedStoresSummary);
      if (
        discontinuedStoresSummary?.store_summary?.de_listed?.total_stores &&
        addAlerts
      ) {
        dispatch(
          addStatusMessage({
            id: "discontinuedstores",
            title: (
              <Text>
                Your brand may be delisted from{" "}
                {
                  discontinuedStoresSummary?.store_summary?.de_listed
                    ?.total_stores
                }{" "}
                {pluralize(storeLabel)}. Found by <DathicAutopilotPopover />
              </Text>
            ),
            action: {
              label: "Review & Disable",
              callback: () =>
                dispatch(
                  addOpenUploadProxy({
                    name: "StoreDiscontinuedProxy",
                    props: {
                      type: "discontinued",
                      confidenceLevelsDescription:
                        discontinuedStoresSummary.confidence_levels
                          ?.short_description,
                    },
                  })
                ),
            },
            bg: "tomato",
          })
        );
      }
    };

    if (
      currentUser?.is_super ||
      !orgProperties?.properties?.hideStoreRecommendations
    ) {
      checkRecommendationSummary();
    }
    if (
      currentUser?.is_super ||
      orgProperties?.properties?.showDiscontinueRecommendations
    ) {
      checkDiscontinuedSummary();
    }
  }, [
    accessToken,
    dispatch,
    orgProperties,
    storeLabel,
    currentUser,
    addAlerts,
  ]);

  return { discontinuedSummary };
};
