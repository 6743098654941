import { CheckIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Code,
  Divider,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FilteringMode, PagingPosition } from "ka-table/enums";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import {
  selectAccessToken,
  selectCategories,
  selectChains,
  selectOrgProperties,
  selectStates,
  selectUserResources,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import { EntityTable } from "../../components/entity-table/EntityTable";
import LimitationsMessage from "../../components/LimitationsMessage";
import LocationSearchInput from "../../components/LocationSearchInput";
import MultiSelectFormControl from "../../components/MultiSelectFormControl";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Pagination } from "../../domain/Pagination";
import { Store } from "../../domain/Store";
import {
  getProperties,
  getStores,
  getStoresPaginated,
  postStoresBatch,
} from "../../services/api.service";
import { storeUploadValidation } from "./store-validators";
import { useUpload } from "./UploadHooks";

const pluralize = require("pluralize");

type StoreFilter = {
  selected_categories: ({ category: string; count: number } | string)[];
  selected_chains: string[];
  selected_stores: Store[];
  selected_states: string[];
  selected_cities: string[];
  selected_zipcodes: string[];
  search_query: string;
  state_input: string;
  city_input: string;
  selected_latitude?: number;
  selected_longitude?: number;
};

interface ProxyItemType extends Store {
  searched: any;
}

const ConfirmationMessage = ({
  selectedStores = [],
  existingStores = [],
  possibleDuplicates = [],
}: {
  selectedStores?: any[];
  existingStores?: any[];
  possibleDuplicates?: any[];
}) => {
  return (
    <VStack
      divider={<Divider />}
      textAlign={"start"}
      spacing={5}
      alignItems="flex-start"
    >
      <Text fontSize="sm">
        To add products for newly added locations, go to Manage
      </Text>
      {!!(existingStores.length + possibleDuplicates.length) && (
        <VStack alignItems={"flex-start"} w="100%">
          <VStack
            p={2}
            spacing={2}
            alignItems={"flex-start"}
            alignSelf="flex-start"
            fontSize="sm"
            bg="#F7F7F7"
            rounded="md"
            w={"100%"}
          >
            {!!existingStores.length && (
              <Text>
                <Code colorScheme="orange">{existingStores.length}</Code> were
                already in your locator
              </Text>
            )}
            {!!possibleDuplicates.length && (
              <Text>
                <Code colorScheme="orange">{possibleDuplicates.length}</Code>{" "}
                duplicated
              </Text>
            )}
          </VStack>
        </VStack>
      )}
      <VStack alignItems={"flex-start"} w="100%">
        <Text fontSize="lg" fontWeight="bold" color="blue.400">
          {selectedStores.length || 0} were added to your locator
          <CheckIcon />
        </Text>
      </VStack>
    </VStack>
  );
};

const pageSize = 2000;

function StoreSearchGrid({
  onFinish,
  storeTypes,
}: {
  onFinish?: () => void;
  storeTypes?: (string | undefined)[];
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const formMethods = useForm<StoreFilter>({
    defaultValues: {
      selected_categories: storeTypes?.includes("restaurant")
        ? ["Restaurants"]
        : [],
      selected_chains: [],
      selected_stores: [],
      selected_states: [],
      selected_cities: [],
      selected_zipcodes: [],
    },
  });
  const {
    watch,
    setValue,
    register,
    formState,
    reset,
    handleSubmit,
    getValues,
  } = formMethods;
  const [isLoading, setIsLoading] = useState(false);
  const [paging, setPaging] = useState<Pagination<Store>>();
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [searchFilter, setSearchFilter] = useState<Partial<StoreFilter>>();
  const [forceFetchKey, setForceFetchKey] = useState(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalDataSelected, setTotalDataSelected] = useState(false);
  const messageModalContext = useContext(MessageModalContext);

  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const states = useAppSelector(selectStates);
  const categories = useAppSelector(selectCategories);
  const chains = useAppSelector(selectChains);
  const userResources = useAppSelector(selectUserResources);

  const statesLabel: string =
    orgProperties?.properties?.statesNameReplacement || "States";
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";
  const storeLabel = storeTypes?.includes("restaurant")
    ? "Restaurant"
    : orgProperties?.properties?.storeNameReplacement || "Store";

  const { onValidate, uploadItems } = useUpload<Store>({
    validator: (itemsToUpload: Store[], accessToken: string) =>
      storeUploadValidation(
        itemsToUpload,
        accessToken || "",
        [],
        orgProperties?.properties?.country,
        storeLabel,
        orgProperties?.organization_id
      ),
    entityName: storeLabel,
    schema: Store.getSchemaForTable(statesLabel, zipcodeLabel, storeTypes),
    postBatch: async (items: any[], accessToken: string) => {
      const { store_id } = await postStoresBatch(
        accessToken,
        items.map((i) =>
          new Store({
            ...i,
            type: storeTypes?.includes("restaurant") ? "restaurant" : i.type,
            chain: i.chain,
            sources: ["search_from_dathic"],
          }).buildForPost()
        )
      );
      return store_id;
    },
  });

  const selectedCategories = watch("selected_categories");
  const selectedChains = watch("selected_chains");
  const selectedStores = watch("selected_stores");
  const selectedStates = watch("selected_states");
  const selectedCities = watch("selected_cities");
  const selectedZipcodes = watch("selected_zipcodes");

  const country = useMemo(
    () => orgProperties?.properties?.country,
    [orgProperties]
  );

  const onSubmit: SubmitHandler<StoreFilter> = (formValues) => {
    setSearchFilter(formValues);
    if (searchFilter) {
      setForceFetchKey(new Date().getTime());
    }
    const {
      selected_states,
      selected_chains,
      selected_zipcodes,
      selected_categories,
      search_query,
      selected_cities,
    } = formValues || {};
    getStoresPaginated(
      selected_states,
      selected_chains,
      selected_zipcodes,
      selected_categories?.map((cat) =>
        typeof cat === "string" ? cat : cat?.category
      ),
      [],
      search_query,
      undefined,
      accessToken,
      country,
      [],
      [],
      [],
      selected_cities,
      undefined,
      undefined,
      undefined,
      1,
      0
    ).then((paginatedRes) => {
      const { total } = paginatedRes || {};
      setTotalCount(total);
    });
  };

  useEffect(() => {
    if (selectedStates?.length) {
      getProperties(accessToken, "for_stores", {
        states: selectedStates,
        selectedChains,
      }).then((properties) => {
        const newCategoryOptions = (
          properties?.find(
            (properties: { name: string }) => properties.name === "categories"
          )?.value || []
        ).map((str: string) => {
          try {
            return JSON.parse(str.replaceAll("'", '"'));
          } catch (error) {
            return undefined;
          }
        });
        setCategoryOptions(
          newCategoryOptions?.length ? newCategoryOptions : []
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, selectedChains, selectedStates]);

  const getPropertiesForStates = async (newStates?: string[]) => {
    if (!newStates?.length) {
      return;
    }
    const properties = await getProperties(accessToken, "for_states", {
      states: newStates,
    });
    const newCityOptions =
      properties?.find((properties: any) => properties.name === "city")
        ?.value || [];
    setCityOptions(newCityOptions);
    setValue(
      "selected_cities",
      (selectedCities || []).filter(
        (city) => !!newCityOptions.find((newCity: string) => newCity === city)
      )
    );
  };

  const onSelectedChange = useCallback(
    (selected?: Store[], rowKey?: any, allSelectedKeys?: any[]) => {
      const currentSelectedStores = getValues("selected_stores");
      setValue(
        "selected_stores",
        allSelectedKeys
          ?.map<Store | undefined>(
            (id) =>
              currentSelectedStores.find((s) => s.id === id) ||
              selected?.find((s) => s.id === id)
          )
          .filter<Store>((s): s is Store => !!s) || []
      );
    },
    [getValues, setValue]
  );

  return (
    <VStack w="100%" flex={1} zIndex={1} bg="white" p={3} rounded="lg">
      {onFinish && (
        <Button
          variant="link"
          alignSelf="flex-start"
          leftIcon={<ChevronLeftIcon />}
          onClick={() => {
            if (onFinish) onFinish();
          }}
        >
          Back
        </Button>
      )}
      <FormProvider {...formMethods}>
        <form
          style={{ width: "100%", zIndex: 3 }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <LimitationsMessage
            includedInMessage={[statesLabel, "county", "city", zipcodeLabel]}
          />
          <SimpleGrid w="100%" columns={4} spacing={2}>
            <GridItem colSpan={4}>
              <HStack justifyContent="stretch" alignItems="flex-start">
                <MultiSelectFormControl
                  label={undefined}
                  required
                  inputProps={{
                    ...register("state_input", {
                      required: false,
                    }),
                    placeholder: statesLabel,
                  }}
                  getOptionValue={(item: any) => item.label}
                  disabledReason={`Purchase plan to select more ${statesLabel}`}
                  value={selectedStates?.map((key) => ({
                    key,
                    label:
                      states?.find((state) => state?.abbr === key)?.name || "",
                  }))}
                  onChange={(selection: any[]) => {
                    const selectedAll = selection?.find(
                      (item) => item.key === "all"
                    );
                    const newStates = selectedAll
                      ? states?.map((state) => state?.abbr)
                      : (selection || []).map((variable) => variable.key);
                    setValue("selected_states", newStates);
                    getPropertiesForStates(newStates).then(() => {});
                  }}
                  autocompleteProps={{
                    options: [{ key: "all", label: "All" }].concat(
                      states?.map((state) => ({
                        key: state?.abbr || "",
                        label: state?.name || "",
                      })) || []
                    ),
                    exclude: selectedStates?.map((key) => ({
                      key,
                      label:
                        states?.find((state) => state?.abbr === key)?.name ||
                        "",
                    })),
                    focusInputOnSuggestionClick: true,
                  }}
                  formControlProps={{
                    isInvalid: !!formState.errors["state_input"],
                    maxW: "33%",
                  }}
                />
                <MultiSelectFormControl
                  disabledReason="Purchase plan to select more cities"
                  value={selectedCities}
                  onChange={(selection: any[]) => {
                    setValue("selected_cities", selection);
                  }}
                  inputProps={{
                    ...register("city_input", {
                      required: false,
                    }),
                    placeholder: "Cities",
                  }}
                  autocompleteProps={{
                    options: cityOptions,
                    exclude: selectedCities,
                    focusInputOnSuggestionClick: true,
                  }}
                />
                <MultiSelectFormControl
                  disabledReason={`Purchase plan to select more ${pluralize(
                    zipcodeLabel,
                    2
                  )}`}
                  value={selectedZipcodes}
                  onChange={(selection: any[]) => {
                    setValue("selected_zipcodes", selection);
                  }}
                  inputProps={{
                    ...register(pluralize(zipcodeLabel, 2), {
                      required: false,
                    }),
                    placeholder: pluralize(zipcodeLabel),
                  }}
                  autocompleteProps={{
                    options: undefined,
                    exclude: selectedZipcodes,
                    focusInputOnSuggestionClick: true,
                  }}
                />
              </HStack>
            </GridItem>
            <GridItem colSpan={2}>
              <MultiSelectFormControl
                inputProps={{
                  placeholder: `${storeLabel} Chain`,
                }}
                value={selectedChains}
                onChange={(selection) => {
                  setValue("selected_chains", selection);
                }}
                required={false}
                formControlProps={{ flex: 1 }}
                autocompleteProps={{
                  options: chains || [],
                  exclude: selectedChains,
                  focusInputOnSuggestionClick: true,
                }}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <MultiSelectFormControl
                getOptionValue={(suggestion) => {
                  return typeof suggestion === "object"
                    ? `${suggestion.category}: ${suggestion.count}`
                    : suggestion;
                }}
                isDisabled={storeTypes?.includes("restaurant")}
                inputProps={{
                  placeholder: `${storeLabel} Categories`,
                }}
                value={selectedCategories}
                onChange={(selection) => {
                  setValue("selected_categories", selection);
                }}
                required={false}
                formControlProps={{ flex: 1 }}
                autocompleteProps={{
                  options: [...categoryOptions, ...categories],
                  exclude: selectedCategories,
                  focusInputOnSuggestionClick: true,
                }}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <LocationSearchInput
                style={{ width: "100%" }}
                onGeocode={async (res: any) => {
                  const state: string =
                    res.address_components.find((component: any) =>
                      component.types.includes("administrative_area_level_1")
                    )?.short_name ?? "";
                  const city: string =
                    res.address_components.find((component: any) =>
                      component.types.includes("locality")
                    )?.long_name ?? "";
                  const country: string =
                    res.address_components.find((component: any) =>
                      component.types.includes("country")
                    )?.short_name ?? "";
                  const zipcode: string = (
                    res.address_components.find((component: any) =>
                      component.types.includes("postal_code")
                    )?.long_name ?? ""
                  )
                    .replaceAll(" ", "")
                    .slice(0, country === "CA" ? 3 : undefined);
                  const latitude = res.geometry.location.lat() ?? "";
                  const longitude = res.geometry.location.lng() ?? "";
                  reset({
                    search_query: res.formatted_address,
                    selected_categories: selectedCategories,
                    selected_chains: selectedChains,
                    selected_stores: selectedStores,
                    city_input: "",
                    state_input: "",
                    selected_cities: [city],
                    selected_zipcodes: [zipcode],
                    selected_states: [state],
                    selected_latitude: latitude,
                    selected_longitude: longitude,
                  });
                }}
                inputProps={{
                  placeholder: `Search by ${storeLabel} name or address`,
                }}
                onTimeout={(newSearchText: string) => {
                  setValue("search_query", newSearchText);
                }}
                value={undefined}
                onChange={undefined}
              />
            </GridItem>
            <GridItem colSpan={2} colStart={2}>
              <Button variant="solid" w="100%" colorScheme="blue" type="submit">
                Search
              </Button>
            </GridItem>
          </SimpleGrid>
        </form>
      </FormProvider>
      {(searchFilter || !!selectedStores.length) && (
        <Box w="100%" zIndex={2}>
          <Text>
            Sort or filter the table, check desired {pluralize(storeLabel)} from
            the list, then click add selected {pluralize(storeLabel)}
          </Text>
          <EntityTable
            onTotalDataSelected={(totalDataSelected) =>
              setTotalDataSelected(totalDataSelected)
            }
            hideFilterButton={!!(paging?.pages && paging.pages > 1)}
            fetch={async (pageIndex?: number) => {
              const {
                selected_states,
                selected_chains,
                selected_zipcodes,
                selected_categories,
                search_query,
                selected_cities,
              } = searchFilter || {};
              const paginatedRes = await getStoresPaginated(
                selected_states,
                selected_chains,
                selected_zipcodes,
                selected_categories?.map((cat) =>
                  typeof cat === "string" ? cat : cat?.category
                ),
                [],
                search_query,
                undefined,
                accessToken,
                country,
                [],
                [],
                [],
                selected_cities,
                undefined,
                undefined,
                undefined,
                (pageIndex ?? 0) + 1,
                pageSize
              );
              const { items, ..._paging } = paginatedRes || {};
              setTotalCount(_paging.total);
              const res = [
                ...items.map((b: Store) => ({
                  ...b,
                  selectedInMap: !!selectedStores
                    .map((s) => s.id)
                    .includes(b.id),
                })),
              ].map(
                (s) =>
                  new Store({
                    ...s,
                    found_in_org:
                      s.searched?.store_validation === "found_in_org"
                        ? "found"
                        : "not_found",
                  })
              ) as Store[];
              setPaging(
                new Pagination({
                  ..._paging,
                  current_page: Math.min(_paging.current_page, _paging.pages),
                })
              );
              return res || [];
            }}
            allowBatchActions
            onSelectedChange={onSelectedChange}
            isRowDisabled={(rowData) =>
              rowData.searched?.store_validation === "found_in_org"
            }
            forceFetchKey={forceFetchKey}
            filteredCount={paging?.total}
            totalCount={totalCount}
            paging={{
              enabled: !!paging,
              pageSize: pageSize,
              position: PagingPosition.Bottom,
              pagesCount: paging?.pages,
              pageIndex: (paging?.current_page || 1) - 1,
            }}
            initialTableProps={{
              filteringMode: FilteringMode.None,
              columns: [
                {
                  title: "Status",
                  key: "found_in_org",
                  Render: ({ rowData }) =>
                    rowData.searched?.store_validation === "found_in_org" ? (
                      <Text color="green">Added</Text>
                    ) : (
                      <></>
                    ),
                  filterOptions: [
                    { value: "found", label: "Added" },
                    { value: "not_found", label: "Not Added" },
                  ],
                  width: 100,
                },
                {
                  title: "Name",
                  key: "name",
                },
                {
                  title: "Address",
                  key: "address",
                  style: { width: 200 },
                },
                {
                  title: "Chain",
                  key: "chain",
                },
                {
                  title: "State",
                  key: "state",
                },
                {
                  title: "City",
                  key: "city",
                },
                {
                  title: "Zipcode",
                  key: "zipcode",
                },
              ],
            }}
          />
        </Box>
      )}
      <Button
        onClick={async () => {
          setIsLoading(true);
          let storesToValidate = [];
          if (totalDataSelected) {
            const {
              selected_states,
              selected_chains,
              selected_zipcodes,
              selected_categories,
              search_query,
              selected_cities,
            } = searchFilter || {};
            storesToValidate = await getStores(
              selected_states,
              selected_chains,
              selected_zipcodes,
              selected_categories?.map((cat) =>
                typeof cat === "string" ? cat : cat?.category
              ),
              [],
              search_query,
              undefined,
              accessToken,
              country,
              [],
              [],
              [],
              selected_cities,
              undefined,
              undefined,
              undefined
            );
          } else {
            storesToValidate = selectedStores;
          }
          onValidate(
            storesToValidate?.map((s: Store) => {
              const _s = s as ProxyItemType;
              _s.searched = {
                ...s,
                store: s,
                store_validation: "found",
                associate: true,
                store_id: s.id,
              };
              return _s;
            }),
            undefined,
            [],
            () => setIsLoading(false),
            { storeTypes }
          )
            .then((validationResult) => {
              const { validatedItems } = validationResult;
              const selectedItems = validatedItems?.filter(
                (s) =>
                  !["duplicated", "found_in_org"].includes(
                    s.searched?.store_validation
                  )
              );
              if (selectedItems?.length) {
                const messageModal = (data: any) => ({
                  message: (
                    <ConfirmationMessage
                      existingStores={validatedItems?.filter(
                        (s) => s.searched?.store_validation === "found_in_org"
                      )}
                      possibleDuplicates={validatedItems?.filter(
                        (s) => s.searched?.store_validation === "duplicated"
                      )}
                      selectedStores={selectedItems}
                    />
                  ),
                  actions: [
                    {
                      label: `Add more`,
                    },
                    {
                      label: `Manage ${pluralize(storeLabel)}`,
                      callback: () => {
                        if (location.pathname.includes("storelocator")) {
                          navigate(`/storelocator/stores`, {
                            state: {
                              tabIndex: 1,
                              selectedStores: data?.map(
                                (d: any) => d?.item?.id
                              ),
                            },
                          });
                          return;
                        }
                        navigate(`/StoresTabs/stores-manage`, {
                          state: {
                            tabIndex: 1,
                            selectedStores: data?.map((d: any) => d?.item?.id),
                          },
                        });
                      },
                    },
                  ],
                });
                return uploadItems(selectedItems, messageModal);
              }
              return { goodItems: [], badItems: [], existingItems: [] };
            })
            .catch(() => setIsLoading(false));
        }}
        colorScheme="blue"
        isLoading={isLoading}
        isDisabled={
          !selectedStores.length ||
          !userResources?.includes(RESOURCES.STORES_CREATE)
        }
      >
        Add Selected{" "}
        {pluralize(
          storeLabel,
          totalDataSelected ? totalCount : selectedStores.length,
          true
        )}
      </Button>
    </VStack>
  );
}

export default StoreSearchGrid;
