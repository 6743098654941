import { Box, Button } from "@chakra-ui/react";
import { DataType } from "ka-table/enums";
import { DateTime } from "luxon";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { WorkBook } from "xlsx";
import {
  FinishMessage,
  S3Params,
  useUpload,
} from "../features/stores/UploadHooks";
import { CustomColumn } from "./entity-table/EntityTable";
import FileUploadProxy from "./file-upload-proxy";

type Props<T> = {
  entityName?: string;
  postItem?: Function;
  postBatch?: Function;
  putBatch?: Function;
  filterForUpdate?: (value: T, index: number, array: T[]) => boolean;
  mapForUpdate?: (value: any, index: number, array: any[]) => any;
  schema?: CustomColumn<T>[];
  Icon?: React.ElementType;
  isDisabled?: boolean;
  onFinish?: (
    goodItems: { item: T; original: any }[],
    badItems: any[],
    file?: File,
    workbook?: WorkBook,
    validatedItems?: T[],
    selectedFields?: Object
  ) => void;
  validator?: Function;
  onProxyPass?: Function;
  defaultSheet?: string;
  proxyName?: string;
  CustomButton?: () => React.ReactElement;
  fileFromOutside?: File;
  proxyParams?: { [key: string]: any };
  onClose?: () => void;
  s3Params?: Partial<S3Params>;
  finishMessage?: FinishMessage;
};

function UploadBtn<T>({
  entityName,
  postItem,
  putBatch,
  postBatch,
  schema,
  Icon,
  isDisabled,
  onFinish,
  validator,
  defaultSheet,
  proxyName,
  proxyParams,
  onClose = () => {},
  fileFromOutside,
  CustomButton,
  s3Params,
  finishMessage,
  onProxyPass,
  filterForUpdate,
  mapForUpdate,
}: Props<T>) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const { uploadItems, onValidate, uploadFile, updateItems } = useUpload<T>({
    validator,
    entityName,
    postBatch,
    postItem,
    putBatch,
    schema,
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    setFile(e.target.files?.[0]);

    e.target.value = "";
  };

  const _uploadItems = (
    validationResult: {
      selectedItems?: T[];
      validatedItems?: T[];
    },
    selectedFields: Object
  ) => {
    const { selectedItems = [], validatedItems } = validationResult;
    const toUpdate = filterForUpdate
      ? mapForUpdate
        ? selectedItems.filter(filterForUpdate).map(mapForUpdate)
        : selectedItems.filter(filterForUpdate)
      : [];
    updateItems(toUpdate)
      .then(() => {
        return uploadItems(
          selectedItems.filter((item, index, array) =>
            filterForUpdate ? !filterForUpdate(item, index, array) : true
          ),
          finishMessage
        );
      })
      .then(({ goodItems, badItems }) => {
        const _file = fileFromOutside || file;
        onFinish &&
          onFinish(
            [
              ...goodItems,
              ...toUpdate.map((item) => ({ item, original: item })),
            ],
            badItems,
            _file,
            undefined,
            validatedItems,
            selectedFields
          );
        if (s3Params && _file)
          uploadFile({
            file: _file,
            getFileName: "uploadedFile",
            folderName: "test-files",
            ...s3Params,
          });
      });
  };

  const handleProxyPass = async (
    selectedFields: any,
    data?: any[],
    dataCsv?: any,
    dataWithProblems?: any
  ) => {
    let _data = data;
    if (data?.length) {
      if (onProxyPass) {
        try {
          _data = await onProxyPass(data, selectedFields);
        } catch (e) {
          console.log(e);
          return;
        }
      }
      const validationResult = await onValidate(
        _data,
        proxyName,
        dataWithProblems,
        onClose,
        proxyParams
      );
      _uploadItems(validationResult, selectedFields);
    } else {
      toast.error("No items to upload");
    }
  };

  const getButton = () => {
    return CustomButton ? (
      <CustomButton />
    ) : (
      <Button
        leftIcon={Icon && <Icon />}
        onClick={() => hiddenFileInput.current?.click()}
        isDisabled={isDisabled}
        w="100%"
        colorScheme="blue"
      >
        Upload {entityName || "Items"}
      </Button>
    );
  };

  return (
    <Box>
      <>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        {getButton()}
      </>
      <FileUploadProxy
        file={fileFromOutside || file}
        schemas={{
          One:
            schema?.map((item) => ({
              name: item.key,
              helperText: item.title,
              isRequired: item.isRequired,
              format: (value: any) => {
                switch (item.dataType) {
                  case DataType.Boolean:
                    return Boolean(value);
                  case DataType.Date:
                    return DateTime.fromISO(value).toJSDate();
                  case DataType.Number:
                    return Number(value);
                  case DataType.String:
                    return value ? `${value}` : undefined;

                  default:
                    return value;
                }
              },
            })) || [],
        }}
        defaultSheet={defaultSheet}
        onFinishProxy={handleProxyPass}
      />
    </Box>
  );
}

export default UploadBtn;
