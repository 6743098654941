import { ChevronLeftIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  Progress,
  Select,
  SimpleGrid,
  Tag,
  TagLabel,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { startCase } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectCategories,
  selectChains,
  selectIsShopifyApp,
  selectOrgProperties,
  selectProducts,
  selectUserResources,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import AutocompleteInput from "../../components/AutocompleteInput";
import { DathicFileDrop } from "../../components/DathicFileDrop";
import DraggableMapPin from "../../components/DraggableMapPin";
import DynamicInput from "../../components/DynamicInput";
import LocationSearchInput from "../../components/LocationSearchInput";
import MultiSelectFormControl from "../../components/MultiSelectFormControl";
import { RecurringEventForm } from "../../components/RecurringEventForm";
import { DEFAULT_STORE_TYPES } from "../../constants/data-constants";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Product } from "../../domain/Product";
import { ProductStore } from "../../domain/ProductStore";
import { SearchStores } from "../../domain/SearchStores";
import { Store } from "../../domain/Store";
import { StoreReview } from "../../domain/StoreReview";
import {
  deleteProductStore,
  getStores,
  postProductStoresBatch,
  postSearchOneStore,
  postStore,
  postStoreReview,
  putProductStore,
  putStore,
} from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { urlPattern } from "../../utils/compareUtils";
import { cancellablePromise } from "../../utils/promiseUtils";
import { replacePlaceholders } from "../../utils/stringUtils";
import ProductForm from "../products/ProductForm";
import FeaturedContentList, {
  FeaturedContent,
} from "../store-locator/FeaturedContentList";
import { refreshIframe } from "../store-locator/storeLocatorSlice";
import StoreImagesInput from "./StoreImagesInput";
import StoreReviews from "./StoreReviews";

var pluralize = require("pluralize");

type Props = {
  store?: Store;
  onFinish?: (store: Store | undefined) => void;
  storeTypes?: (string | undefined)[];
};

type FormType = {
  id: number;
  address: string;
  short_address: string;
  name: string;
  description: string;
  state: string;
  city: string;
  zipcode: string;
  store_id: string;
  email: string;
  phone: string;
  image_url: string;
  chain: string;
  county: string;
  country: string;
  google_place_id: string;
  type: string;
  latitude: number;
  longitude: number;
  category: string[];
  reviews: StoreReview[];
  products: ProductStore[];
  parent_id: number;
  secondary_images: string[];
  start_date?: string;
  end_date?: string;
  recurrence_rule?: string;
};

function StoreForm({ store, onFinish, storeTypes }: Props) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const chains = useAppSelector(selectChains);
  const categories = useAppSelector(selectCategories);
  const searchStoresTimeout = useRef<NodeJS.Timeout>();
  const cancelSearch = useRef<Function>();
  const userResources = useAppSelector(selectUserResources);
  const products = useAppSelector(selectProducts);
  const isShopifyApp = useAppSelector(selectIsShopifyApp);
  const messageContext = useContext(MessageModalContext);
  const [loading, setLoading] = useState(false);
  const [editingProduct, setEditingProduct] = useState<Product>();
  const [featuredContent, setFeaturedContent] = useState<FeaturedContent[]>(
    (store?.featured_content &&
      (typeof store.featured_content === "string"
        ? JSON.parse(store.featured_content)
        : store.featured_content)) ||
      []
  );
  const [clearingProducts, setClearingProducts] = useState(false);
  const [foundSearched, setFoundSearched] = useState<number | undefined>();
  const [searchingForStore, setSearchingForStore] = useState<boolean>(false);
  const [nameChanged, setNameChanged] = useState(!!store?.name);
  const [foundSearchedOrg, setFoundSearchedOrg] = useState<
    number | undefined
  >();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: store || {
      address: "",
      short_address: "",
      name: "",
      description: "",
      state: "",
      city: "",
      zipcode: "",
      store_id: "",
      email: "",
      phone: "",
      image_url: "",
      chain: "No chain",
      google_place_id: "",
      type:
        !!storeTypes?.[0] && storeTypes?.[0] !== "store"
          ? storeTypes[0]
          : "megastore",
      category: storeTypes?.includes("restaurant") ? ["Restaurants"] : [],
      reviews: [],
      products: [],
      secondary_images: [],
      start_date:
        storeTypes && storeTypes.includes("popup_store")
          ? new Date()
          : undefined,
      end_date:
        storeTypes && storeTypes.includes("popup_store")
          ? DateTime.now().plus({ month: 1 }).toJSDate()
          : undefined,
    },
  });
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const statesLabel =
    orgProperties?.properties?.statesNameReplacement || "State";
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zipcode";
  const id = watch("id");
  const address = watch("address");
  const state = watch("state");
  const county = watch("county");
  const city = watch("city");
  const zipcode = watch("zipcode");
  const country = watch("country");
  const name = watch("name");
  const imageUrl = watch("image_url");
  const selectedChain = watch("chain");
  const selectedCategory = watch("category");
  const selectedReviews = watch("reviews");
  const selectedProducts = watch("products");
  const latitude = watch("latitude");
  const longitude = watch("longitude");
  const parent_id = watch("parent_id");
  const secondaryImages = watch("secondary_images");
  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const recurringRule = watch("recurrence_rule");
  const currentFormValues = watch();

  const clearForm = () => {
    reset();
    setFoundSearched(undefined);
    setFoundSearchedOrg(undefined);
  };

  const onSubmit: SubmitHandler<FormType> = async (formData) => {
    setLoading(true);
    const _store = new Store(formData);
    _store.featured_content = featuredContent.length
      ? featuredContent
      : undefined;
    const productsToAssociate = _store.products;
    let result: Store | undefined;
    try {
      _store.type =
        !!storeTypes?.[0] && storeTypes?.[0] !== "store"
          ? storeTypes[0]
          : _store.type;
      _store.chain = _store.chain === "No chain" ? undefined : _store.chain;
      if (store) {
        trackClick("store-update-confirm", storeTypes?.[0] || "");
        result = await putStore(_store.buildForUpdate(), accessToken);
      } else {
        trackClick("store-add-manually-confirm", storeTypes?.[0] || "");
        const toPost = new Store(_store).buildForPost();
        toPost.sources = ["store_form"];
        result = await postStore(toPost, accessToken);
        if (result) {
          if (productsToAssociate?.length) {
            await postProductStoresBatch(
              accessToken,
              productsToAssociate.map((product) => ({
                store_id: result?.id,
                product_id: product.product_id,
                units: product.units,
                origin: "Manual",
                sources: ["store_form"],
              }))
            );
          }
          if (selectedReviews?.length) {
            await Promise.all(
              selectedReviews.map((review) =>
                postStoreReview(
                  `${result?.id}`,
                  review.buildForPost(),
                  accessToken
                )
              )
            );
          }
        }
        clearForm();
      }
      dispatch(refreshIframe());
      messageContext.showModal({
        title: `Your ${storeLabel} was ${
          store ? "updated" : "created"
        } successfully`,
        variant: "success",
        actions: [
          ...(!store
            ? [
                {
                  label: `Add another`,
                  callback: () => {
                    trackClick("store-add-manually", storeTypes?.[0] || "");
                  },
                },
                {
                  label: `Finish`,
                  callback: () => {
                    if (onFinish) onFinish(result);
                  },
                },
              ]
            : [
                {
                  label: `Finish`,
                  callback: () => {
                    if (onFinish) onFinish(result);
                  },
                },
              ]),
        ],
      });
    } catch (e: any) {
      console.log(e);
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  const resetToExistingStore = useCallback(
    async (_foundSearched?: number) => {
      if (!_foundSearched) {
        return;
      }
      const foundStores = await getStores(
        [],
        [],
        [],
        [],
        [],
        undefined,
        undefined,
        accessToken,
        undefined,
        [],
        [_foundSearched]
      );
      if (foundStores?.length) {
        const { id: foundId, ...foundStore } = foundStores[0];
        reset({
          ...foundStore,
          id: id,
          start_date: startDate,
          end_date: endDate,
          recurrence_rule: recurringRule,
        });
        setValue("parent_id", foundId);
        setValue("chain", foundStore.chain ?? "");
        setValue("category", foundStore.category ?? []);
        setValue("reviews", foundStore.reviews ?? []);
      }
    },
    [accessToken, endDate, id, recurringRule, reset, setValue, startDate]
  );

  function ProductTab({ item, index }: { item: ProductStore; index: number }) {
    const [deletingProductItem, setDeletingProductItem] = useState(false);
    const products = useAppSelector(selectProducts);
    const product = products.find((p) => p.id === Number(item.product_id));
    return (
      <Tag key={item.id} mr={1} mb={2} p={3} minW={20}>
        <IconButton
          icon={<EditIcon />}
          mr={3}
          aria-label="edit product button"
          onClick={() => {
            setEditingProduct(product);
          }}
        />
        <TagLabel>{product?.name}</TagLabel>
        <Input
          placeholder="Units"
          type="number"
          w={75}
          ml="auto"
          mr={3}
          min={0}
          value={item.units}
          onChange={async (e) => {
            const updatedItem = {
              ...item,
              units: Number(e.target.value),
            } as ProductStore;
            let newList = [...(selectedProducts || [])];
            newList.splice(index, 1, updatedItem);
            if (store && updatedItem.id) {
              putProductStore(
                new ProductStore(updatedItem).buildForPost(),
                accessToken,
                updatedItem.id
              )
                .then(() => {
                  dispatch(refreshIframe());
                  toast.success(`Products updated`);
                })
                .catch((e: any) => {
                  console.log(e);
                });
            }
            setValue("products", newList);
          }}
        />
        <IconButton
          aria-label="Remove product from store"
          isLoading={deletingProductItem}
          onClick={() => {
            let newList = [...(selectedProducts || [])];
            newList.splice(index, 1);
            setValue("products", newList);
            if (item?.id) {
              setDeletingProductItem(true);
              deleteProductStore(item.id, accessToken).finally(() =>
                setDeletingProductItem(false)
              );
            }
          }}
          icon={<CloseIcon />}
        />
      </Tag>
    );
  }

  const onRecurringRuleChange = useCallback(
    (start: Date, end: Date, rule?: string) => {
      setValue("start_date", DateTime.fromJSDate(start).toSQLDate());
      setValue("end_date", DateTime.fromJSDate(end).toSQLDate());
      setValue("recurrence_rule", rule);
    },
    [setValue]
  );

  useEffect(() => {
    if (!nameChanged) {
      setValue(
        "name",
        selectedChain || address
          ? [
              selectedChain !== "No chain" ? selectedChain || "" : "",
              address || "",
            ].join(" ")
          : ""
      );
    }
  }, [address, nameChanged, selectedChain, setValue]);

  useEffect(() => {
    const searchForStore = async () => {
      const addressData = {
        state,
        county,
        city,
        zipcode,
        address,
        latitude,
        longitude,
        country,
      };
      if (address) {
        setSearchingForStore(true);
        const chain = selectedChain || undefined;

        cancelSearch?.current?.();
        const { promise, cancel } = cancellablePromise<
          SearchStores[] | undefined
        >(
          postSearchOneStore(
            accessToken,
            new Store({ chain, ...addressData }).buildForSearch(),
            true,
            orgProperties?.organization_id
          )
        );
        cancelSearch.current = cancel;
        const searched = await promise;
        const foundOrg = searched?.find(
          (s) => !!s.parent_id && s.store_validation === "found_in_org"
        )?.parent_id;
        if (!store && foundOrg) {
          setFoundSearchedOrg(foundOrg);
          setError("address", {
            type: "existing store",
            message: "Please confirm you want to create a duplicate store",
          });
        }
        const _foundSearched = searched?.find(
          (s) => !!s.parent_id && s.store_validation === "found"
        )?.parent_id;
        setFoundSearched((old) => {
          if (
            _foundSearched &&
            old !== _foundSearched &&
            (store || !foundOrg)
          ) {
            resetToExistingStore(_foundSearched);
          }
          return _foundSearched;
        });
        setSearchingForStore(false);
      }
    };

    if (searchStoresTimeout.current) {
      clearTimeout(searchStoresTimeout.current);
    }
    searchStoresTimeout.current = setTimeout(() => {
      searchForStore();
    }, 1500);
  }, [
    accessToken,
    address,
    city,
    clearErrors,
    country,
    county,
    latitude,
    longitude,
    selectedChain,
    setError,
    state,
    zipcode,
    resetToExistingStore,
    store,
  ]);

  return (
    <>
      {editingProduct && (
        <AlertDialog
          leastDestructiveRef={undefined}
          isOpen={!!editingProduct}
          onClose={() => setEditingProduct(undefined)}
          size="4xl"
          closeOnOverlayClick={false}
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Editing Product</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              <ProductForm
                product={editingProduct}
                skipSuccessMessage
                onFinish={() => setEditingProduct(undefined)}
              />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialog>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack
          h="100%"
          w="100%"
          bg="white"
          p={3}
          rounded="lg"
          textAlign={"start"}
        >
          {onFinish && (
            <Button
              variant="link"
              alignSelf="flex-start"
              leftIcon={<ChevronLeftIcon />}
              onClick={() => {
                if (onFinish) onFinish(undefined);
              }}
            >
              Back
            </Button>
          )}
          <Text alignSelf="flex-start">
            Input information to {store ? "edit the" : "add a new"}{" "}
            {storeTypes?.length ? storeTypes[0] : storeLabel}{" "}
            {store ? "" : "to your organization"}
          </Text>
          <SimpleGrid columns={2} w="100%" columnGap={20} rowGap={5}>
            {storeTypes && storeTypes.includes("popup_store") && (
              <GridItem colSpan={2}>
                <FormControl>
                  <FormLabel>Popup {storeLabel} schedule</FormLabel>
                  <FormHelperText textAlign="start">
                    Specify the schedule for the popup {storeLabel}.
                  </FormHelperText>
                  {startDate && endDate && (
                    <RecurringEventForm
                      allowCustom={false}
                      rrule={recurringRule}
                      startDateProp={
                        typeof startDate === "string"
                          ? DateTime.fromSQL(startDate).toJSDate()
                          : startDate
                      }
                      endDateProp={
                        typeof endDate === "string"
                          ? DateTime.fromSQL(endDate).toJSDate()
                          : endDate
                      }
                      onChange={onRecurringRuleChange}
                    />
                  )}
                </FormControl>
              </GridItem>
            )}
            <FormControl isRequired>
              <FormLabel>Chain</FormLabel>
              {selectedChain === "No chain" && (
                <FormHelperText>
                  It is suggested to choose a chain other than "No chain"
                </FormHelperText>
              )}
              <MultiSelectFormControl
                inputProps={{
                  value: selectedChain || "",
                  onChange: (e) => setValue("chain", e.target.value),
                }}
                value={selectedChain ? [selectedChain] : []}
                onChange={(selection: string[]) => {
                  setValue("chain", selection[selection.length - 1]);
                }}
                autocompleteProps={{
                  options: ["No chain"].concat(chains),
                  exclude: selectedChain ? [selectedChain] : [],
                  focusInputOnSuggestionClick: false,
                }}
                formControlProps={undefined}
                isDisabled={undefined}
                disabledReason={undefined}
                getOptionValue={undefined}
                helperText={undefined}
                required={undefined}
              />
            </FormControl>
            <GridItem rowSpan={2}>
              <FormControl
                flex={1}
                isRequired
                isInvalid={
                  !!(errors.latitude?.message || errors.longitude?.message)
                }
              >
                <FormLabel>Coordinates</FormLabel>
                <FormHelperText>
                  You can adjust the position by dragging the pin on the map.
                </FormHelperText>
                <HStack w="100%" justifyContent="space-between" spacing={5}>
                  <VStack
                    alignItems="flex-start"
                    justifyContent={"space-evenly"}
                  >
                    <Input
                      placeholder="Latitude"
                      {...register("latitude", {
                        pattern: /\d+\.?\d*/g,
                        valueAsNumber: true,
                        required: true,
                        validate: () =>
                          (+latitude >= -90 && +latitude <= 90) ||
                          "Latitude must be between -90 and 90",
                      })}
                    />
                    <Input
                      placeholder="Longitude"
                      {...register("longitude", {
                        pattern: /\d+\.?\d*/g,
                        valueAsNumber: true,
                        required: true,
                        validate: () =>
                          (+longitude >= -180 && +longitude <= 180) ||
                          "Longitude must be between -180 and 180",
                      })}
                    />
                    {(errors.latitude?.message ||
                      errors.longitude?.message) && (
                      <FormErrorMessage>
                        {errors.latitude?.message || errors.longitude?.message}
                      </FormErrorMessage>
                    )}
                  </VStack>
                  <DraggableMapPin
                    coordinates={{
                      latitude:
                        +latitude >= -90 && +latitude <= 90 ? +latitude : 0,
                      longitude:
                        +longitude >= -180 && +longitude <= 180
                          ? +longitude
                          : 0,
                    }}
                    onChange={({ latitude, longitude }) => {
                      setValue("latitude", latitude, {
                        shouldTouch: true,
                        shouldDirty: true,
                      });
                      setValue("longitude", longitude, {
                        shouldTouch: true,
                        shouldDirty: true,
                      });
                    }}
                    markerPin={
                      <Box w={3} h={3} rounded="full" shadow="base" bg="red" />
                    }
                  />
                </HStack>
              </FormControl>
            </GridItem>
            <VStack alignItems="flex-start">
              <FormControl isRequired>
                <FormLabel>Address</FormLabel>
                <LocationSearchInput
                  style={{ width: "100%" }}
                  onGeocode={async (res: any) => {
                    setFoundSearched(undefined);
                    setFoundSearchedOrg(undefined);
                    const state: string =
                      res.address_components.find((component: any) =>
                        component.types.includes("administrative_area_level_1")
                      )?.short_name ?? "";
                    const county: string =
                      res.address_components.find((component: any) =>
                        component.types.includes("administrative_area_level_2")
                      )?.long_name ?? "";
                    const city: string =
                      res.address_components.find((component: any) =>
                        component.types.includes("locality")
                      )?.long_name ?? "";
                    const country: string =
                      res.address_components.find((component: any) =>
                        component.types.includes("country")
                      )?.short_name ?? "";
                    const zipcode: string = (
                      res.address_components.find((component: any) =>
                        component.types.includes("postal_code")
                      )?.long_name ?? ""
                    )
                      .replaceAll(" ", "")
                      .slice(0, country === "CA" ? 3 : undefined);
                    const address: string = res.formatted_address ?? "";
                    const latitude: number = res.geometry.location.lat() ?? 0;
                    const longitude: number = res.geometry.location.lng() ?? 0;
                    setValue("state", state, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("county", county, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("city", city, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("zipcode", zipcode, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("address", address, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("short_address", address, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("latitude", latitude, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("longitude", longitude, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("country", country, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    setValue("google_place_id", res.place_id, {
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  inputProps={{
                    defaultValue: (address || "").replaceAll("'", "").trim(),
                  }}
                  value={address}
                  onChange={(newSearchText: string) => {
                    setValue("address", newSearchText);
                  }}
                  onTimeout={undefined}
                />
              </FormControl>
              <HStack w="100%">
                <FormControl isRequired>
                  <Input
                    placeholder={pluralize(statesLabel, 1)}
                    {...register("state")}
                  />
                </FormControl>
                <Input placeholder="County" {...register("county")} />
              </HStack>
              <HStack w="100%">
                <Input placeholder="City" {...register("city")} />
                <Input
                  placeholder={pluralize(zipcodeLabel, 1)}
                  {...register("zipcode")}
                />
              </HStack>
              {!foundSearchedOrg &&
                foundSearched &&
                foundSearched !== parent_id && (
                  <Alert
                    bg="transparent"
                    borderColor="blue.400"
                    borderWidth={2}
                    rounded="lg"
                    color="blue.400"
                  >
                    <AlertIcon color="blue.400" />
                    <VStack alignItems="flex-end">
                      <HStack>
                        <AlertTitle>{storeLabel} found!</AlertTitle>
                        <AlertDescription maxWidth="sm">
                          Autofill {storeLabel} and save time.
                        </AlertDescription>
                      </HStack>
                      <Button
                        variant="solid"
                        colorScheme="blue"
                        onClick={() => resetToExistingStore(foundSearched)}
                        ml="auto"
                      >
                        Use {storeLabel}
                      </Button>
                    </VStack>
                  </Alert>
                )}
              {!foundSearchedOrg &&
                foundSearched &&
                foundSearched === parent_id && (
                  <Alert
                    bg="transparent"
                    borderColor="blue.400"
                    borderWidth={2}
                    rounded="lg"
                    color="blue.400"
                  >
                    <AlertIcon color="blue.400" />
                    <VStack alignItems="flex-end">
                      <HStack>
                        <AlertTitle>{storeLabel} found!</AlertTitle>
                        <AlertDescription maxWidth="sm">
                          The form was autofilled for you.
                        </AlertDescription>
                      </HStack>
                      <Button
                        variant="solid"
                        onClick={() => clearForm()}
                        ml="auto"
                      >
                        Clear form
                      </Button>
                    </VStack>
                  </Alert>
                )}
              {searchingForStore &&
                !parent_id &&
                !(
                  !foundSearchedOrg &&
                  foundSearched &&
                  foundSearched !== parent_id
                ) && (
                  <Alert
                    bg="transparent"
                    borderColor="blue.400"
                    borderWidth={2}
                    rounded="lg"
                    color="blue.400"
                  >
                    <VStack alignItems="flex-end">
                      <HStack>
                        <AlertTitle>Searching for {storeLabel}...</AlertTitle>
                      </HStack>
                      <Progress size="xs" isIndeterminate />
                    </VStack>
                  </Alert>
                )}
              {!store && foundSearchedOrg && foundSearchedOrg !== parent_id && (
                <FormControl
                  isInvalid={errors.address?.type === "existing store"}
                >
                  <Alert
                    bg="transparent"
                    borderColor="orange.400"
                    borderWidth={2}
                    rounded="lg"
                    color="orange.400"
                  >
                    <AlertIcon color="orange.400" />
                    <VStack alignItems="flex-end">
                      <AlertTitle>
                        This {storeLabel} already exists in your database
                      </AlertTitle>
                      <HStack>
                        <Button
                          variant="solid"
                          onClick={() => {
                            clearErrors("address");
                            setFoundSearchedOrg(undefined);
                            setFoundSearched(undefined);
                          }}
                          colorScheme="orange"
                        >
                          Ignore & Continue
                        </Button>
                        {onFinish && (
                          <Button
                            variant="solid"
                            onClick={() => onFinish(undefined)}
                          >
                            Go back
                          </Button>
                        )}
                      </HStack>
                    </VStack>
                  </Alert>
                  <FormErrorMessage>
                    Please confirm you want to create a duplicate store
                  </FormErrorMessage>
                </FormControl>
              )}
            </VStack>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <DynamicInput
                data={currentFormValues}
                isTextArea
                placeholder={`Enter ${storeLabel} Name`}
                info={
                  <Box>
                    <strong>Customizing Name of {storeLabel}:</strong>
                    <p>{`To customize the name select the corresponding variable.`}</p>
                  </Box>
                }
                value={name}
                {...register("name", {
                  required: true,
                  onChange: (newValue) => {
                    setNameChanged(true);
                    setValue(
                      "name",
                      replacePlaceholders(newValue, currentFormValues)
                    );
                  },
                })}
                onChange={(newValue) => {
                  setNameChanged(true);
                  setValue(
                    "name",
                    replacePlaceholders(newValue, currentFormValues)
                  );
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Category</FormLabel>
              <MultiSelectFormControl
                value={
                  typeof selectedCategory === "string"
                    ? (selectedCategory as string).split(",")
                    : selectedCategory
                }
                onChange={(selection: string[]) => {
                  setValue("category", selection);
                }}
                isDisabled={storeTypes?.includes("restaurant")}
                autocompleteProps={{
                  options: categories,
                  exclude:
                    typeof selectedCategory === "string"
                      ? (selectedCategory as string).split(",")
                      : selectedCategory,
                  focusInputOnSuggestionClick: false,
                  onNewSuggestion: (selection: string) => {
                    setValue("category", [...selectedCategory, selection]);
                  },
                }}
                formControlProps={undefined}
                disabledReason={undefined}
                getOptionValue={undefined}
                helperText={undefined}
                required={undefined}
                inputProps={undefined}
              />
            </FormControl>
            <FormControl
              mb={3}
              isDisabled={
                !userResources?.includes(RESOURCES.STORE_PRODUCTS_CREATE)
              }
            >
              <FormLabel>Add In-{storeLabel} products</FormLabel>
              <AutocompleteInput
                options={[
                  { sku: "all_products", name: "All" } as unknown as Product,
                ].concat(products)}
                getSuggestionValue={(s: Product) => {
                  return s.name;
                }}
                handleSearchResultClick={async (selection: {
                  suggestion: Product;
                }) => {
                  const selectedAll =
                    selection.suggestion.sku === "all_products";
                  let productStores = selectedAll
                    ? products.map(
                        (p) =>
                          new ProductStore({
                            store_id: store?.id,
                            product_id: p.id,
                            product: p,
                            origin: "Manual",
                            sources: ["store_form_update"],
                          })
                      )
                    : [
                        new ProductStore({
                          store_id: store?.id,
                          product_id: selection.suggestion.id,
                          product: selection.suggestion,
                          origin: "Manual",
                          sources: ["store_form_update"],
                        }),
                      ];
                  if (store) {
                    try {
                      const { product_store_id } = await postProductStoresBatch(
                        accessToken,
                        productStores
                      );
                      productStores.forEach((productStore, index) => {
                        productStore.id = product_store_id[index];
                      });
                      dispatch(refreshIframe());
                      toast.success(`Products updated`);
                    } catch (e: any) {
                      console.log(e);
                    }
                  }
                  const newProducts = [
                    ...(selectedProducts || []),
                    ...productStores,
                  ];
                  setValue("products", newProducts);
                }}
                exclude={
                  (selectedProducts || []).map((ps) => ps.product) as Product[]
                }
                highlightFirstSuggestion={false}
                disabled={undefined}
                value={undefined}
                onChange={undefined}
                focusInputOnSuggestionClick={false}
                inputProps={undefined}
                fetchSuggestions={undefined}
                onNewSuggestion={undefined}
              />
              <Grid
                gridTemplateColumns="repeat(1, 1fr)"
                mt={2}
                overflowY={"auto"}
                maxH={200}
              >
                {selectedProducts?.map((item, index) => (
                  <ProductTab item={item} index={index} />
                ))}
              </Grid>
              {!!selectedProducts?.length && (
                <Button
                  variant="link"
                  isLoading={clearingProducts}
                  onClick={async () => {
                    let newList = [...(selectedProducts || [])];
                    setClearingProducts(true);
                    await Promise.all(
                      newList
                        .filter((p) => !!p.id)
                        .map((p) => deleteProductStore(p.id, accessToken))
                    );
                    setClearingProducts(false);
                    setValue("products", []);
                  }}
                >
                  Clear
                </Button>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                {...register("email")}
                placeholder={`Email`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{storeLabel} Type</FormLabel>
              <Select
                {...register("type")}
                isDisabled={!!storeTypes?.[0] && storeTypes?.[0] !== "store"}
              >
                {(
                  (orgProperties?.properties?.storeTypes as string[]) ||
                  DEFAULT_STORE_TYPES
                ).map((option) => (
                  <option key={option} value={option}>
                    {startCase(option)}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>{storeLabel} Identifier</FormLabel>
              <FormHelperText>
                If you know the ID the market chain uses, enter it here.
              </FormHelperText>
              <Input {...register("store_id")} />
            </FormControl>
            <FormControl>
              <FormLabel>Phone</FormLabel>
              <Input
                type="tel"
                {...register("phone")}
                placeholder={`Phone Number`}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Textarea
                {...register("description")}
                placeholder={`Enter ${storeLabel} Description`}
              />
            </FormControl>
            <HStack w="100%">
              <FormControl>
                <FormLabel>{storeLabel} Image</FormLabel>
                <DathicFileDrop
                  shouldUpload
                  s3Params={{
                    folderName: "store_images",
                    getFileName: `${orgProperties?.organization?.id}_`
                      .concat(`${store?.id}_`)
                      .concat(DateTime.now().toFormat("yyyy-MM-dd_hh-mm-ss")),
                    isPublic: true,
                  }}
                  onUrl={(url) => {
                    setValue("image_url", url || "");
                  }}
                  maxImageWidth={600}
                  accept="image/*"
                />
              </FormControl>
              {imageUrl && urlPattern.test(imageUrl) && (
                <Box rounded="md" shadow="lg" w={40} h={40} overflow="hidden">
                  <Image src={imageUrl} w="100%" h="100%" objectFit="contain" />
                </Box>
              )}
            </HStack>
            <FormControl>
              <FormLabel>Featured Content</FormLabel>
              <FeaturedContentList
                content={featuredContent}
                setContent={setFeaturedContent}
              />
            </FormControl>
            <StoreImagesInput
              storeImages={secondaryImages}
              onChange={(newImages) => {
                setValue("secondary_images", newImages || [], {
                  shouldDirty: true,
                });
              }}
            />
            <FormControl mt={5}>
              {!isShopifyApp && (
                <Button
                  onClick={() => {
                    messageContext.showModal({
                      title: `Reviews for ${storeLabel}`,
                      message: (
                        <StoreReviews
                          store={store}
                          outsideReviews={selectedReviews}
                          onReviewsChange={(res) => setValue("reviews", res)}
                        />
                      ),
                      actions: [
                        {
                          label: "Use reviews",
                          props: { colorScheme: "blue", mt: 5 },
                        },
                      ],
                    });
                  }}
                >
                  Add reviews and ratings to {storeLabel}
                </Button>
              )}
              {!!(
                typeof selectedReviews === "string"
                  ? (selectedReviews as string).split(",")
                  : selectedReviews
              )?.length && (
                <Text>
                  {pluralize(
                    "Review",
                    (typeof selectedReviews === "string"
                      ? (selectedReviews as string).split(",")
                      : selectedReviews
                    ).length,
                    true
                  )}{" "}
                  created for {storeLabel}
                </Text>
              )}
            </FormControl>
          </SimpleGrid>
          <Button
            type="submit"
            colorScheme="blue"
            mb={10}
            minH={8}
            isLoading={loading}
          >
            {store ? `Update ${storeLabel}` : `Create ${storeLabel}`}
          </Button>
        </VStack>
      </form>
    </>
  );
}

export default StoreForm;
