import { Button } from "@chakra-ui/button";
import {
  CheckIcon,
  EditIcon,
  NotAllowedIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import { Box, Code, Text, VStack } from "@chakra-ui/layout";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/modal";
import {
  Alert,
  Badge,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  ModalHeader,
  Select,
  SimpleGrid,
  Spinner,
  Tag,
  TagLabel,
  TagRightIcon,
  Tooltip,
} from "@chakra-ui/react";
import {
  closeEditor,
  updateCellValue,
  updateEditorValue,
} from "ka-table/actionCreators";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import { pick } from "lodash";
import pluralize from "pluralize";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FaCheck } from "react-icons/all";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import {
  selectAccessToken,
  selectOrgProperties,
  selectStatusMessages,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import {
  CustomColumn,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import { ResponsiveModal } from "../../components/responsive-modal";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Store } from "../../domain/Store";
import { postStoresBatch, putStoresBatch } from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { StoreCardPreview } from "../store-locator/StoreCardPreview";
import { StoreLocatorConfiguration } from "../store-locator/StoreLocatorConfig";
import { storeUploadValidation } from "./store-validators";
import { useUpload } from "./UploadHooks";

const locationIssues = ["zip_state_mismatch"];

const CellEditorInput = ({
  props,
}: {
  props: ICellEditorProps & {
    isEditing?: boolean | undefined;
    data?: Store[] | undefined;
  };
}) => (
  <Input
    autoFocus
    onChange={(e) =>
      props.dispatch(
        updateEditorValue(props.rowKeyValue, props.column.key, e.target.value)
      )
    }
    onFocus={() => {
      props.dispatch(
        updateEditorValue(props.rowKeyValue, props.column.key, props.value)
      );
    }}
    onBlur={() => {
      props.dispatch(
        updateCellValue(props.rowKeyValue, props.column.key, props.editorValue)
      );
      props.dispatch(closeEditor(props.rowKeyValue, props.column.key));
    }}
    value={props.editorValue}
  />
);

const CompareStores = ({ storeToCompare }: { storeToCompare: Store }) => {
  const previewConfig = {
    storeDetails: {
      nameFormat: "{chain}",
      contactInfo: true,
      showAddress: true,
      showDescription: true,
      showStoreImage: false,
      showType: true,
    },
  } as StoreLocatorConfiguration;
  return (
    <HStack flex={1} justifyContent="center">
      <SimpleGrid columns={2} gap={3}>
        <Text fontSize="md">Original</Text>
        <Text fontSize="md">Found by Dathic</Text>
        <StoreCardPreview
          store={storeToCompare.original}
          config={previewConfig}
        />
        <StoreCardPreview
          store={storeToCompare.searched}
          config={previewConfig}
        />
      </SimpleGrid>
    </HStack>
  );
};

const ConfirmationMessage = ({
  stores,
  uploadedStores,
  selectedStores,
  existingStores,
  warningStores,
  locationIssueStores,
  possibleDuplicates,
  storeLabel,
  hideDetails = false,
}: {
  stores: Store[];
  uploadedStores: Store[];
  selectedStores: number[];
  existingStores: number[];
  warningStores: number[];
  locationIssueStores: number[];
  possibleDuplicates: number[];
  storeLabel: string;
  hideDetails?: boolean;
}) => {
  const existingStore = stores.filter(
    (s) => existingStores.includes(s.id) && !s.issueSolution
  );
  const possibleDuplicate = stores.filter(
    (s) => possibleDuplicates.includes(s.id) && !s.issueSolution
  );
  const notFound = stores.filter(
    (s) => selectedStores.includes(s.id) && !s.searched.parent_id
  );
  const ignored = stores.filter((s) => s.issueSolution === "ignore");
  return (
    <VStack
      divider={<Divider />}
      textAlign={"start"}
      spacing={5}
      alignItems="flex-start"
    >
      <Text fontSize="sm">
        Please review the validation results below and click "Start Review" to
        make changes and confirm.
      </Text>
      <VStack>
        <Text fontSize="lg" fontWeight="bold" color="blue.400">
          {(stores.length || 0) + (uploadedStores.length || 0)} on file{" "}
          <TimeIcon />
        </Text>
      </VStack>
      {!!uploadedStores.length && (
        <VStack>
          <Text fontSize="lg" fontWeight="bold" color="green">
            {uploadedStores.length || 0} uploaded <CheckIcon />
          </Text>
        </VStack>
      )}
      {!!(
        existingStore.length +
        possibleDuplicate.length +
        warningStores.length +
        locationIssueStores.length
      ) && (
        <VStack alignItems={"flex-start"} w="100%">
          <Text fontSize="lg" fontWeight="bold" color="orange">
            {existingStore.length +
              possibleDuplicate.length +
              warningStores.length +
              locationIssueStores.length || 0}{" "}
            for review <WarningIcon />
          </Text>
          <Text w="100%" fontSize="sm">
            Check issues and confirm selection to add to your list of locations.
          </Text>
          <VStack
            p={2}
            spacing={2}
            alignItems={"flex-start"}
            alignSelf="flex-start"
            fontSize="sm"
            bg="#F7F7F7"
            rounded="md"
            w={"100%"}
          >
            {!!existingStore.length && (
              <Text>
                <Code colorScheme="orange">{existingStore.length}</Code> already
                in your locator
              </Text>
            )}
            {!!possibleDuplicate.length && (
              <Text>
                <Code colorScheme="orange">{possibleDuplicate.length}</Code>{" "}
                duplicate on file
              </Text>
            )}
            {!!locationIssueStores.length && (
              <Text>
                <Code colorScheme="orange">{locationIssueStores.length}</Code>{" "}
                have location issues
              </Text>
            )}
            {!!warningStores.length && (
              <Text>
                <Code colorScheme="red">
                  {pluralize(storeLabel, warningStores.length, true)}
                </Code>{" "}
                unrecognized address
              </Text>
            )}
          </VStack>
        </VStack>
      )}
      <VStack alignItems={"flex-start"} w="100%">
        <Text fontSize="lg" fontWeight="bold" color="blue.400">
          {selectedStores.length || 0} ready for upload <CheckIcon />
        </Text>
        {!hideDetails && selectedStores.length > 0 && (
          <>
            <Text w="100%" fontSize="sm">
              Confirm selection to add to your list of locations.
            </Text>
            <VStack
              p={2}
              spacing={2}
              alignItems={"flex-start"}
              alignSelf="flex-start"
              fontSize="sm"
              bg="#F7F7F7"
              rounded="md"
              w={"100%"}
            >
              {!!(selectedStores.length - notFound.length) && (
                <Text>
                  <Code colorScheme="blue">
                    {selectedStores.length - notFound.length}
                  </Code>{" "}
                  verified
                </Text>
              )}
              {!!notFound.length && (
                <Text>
                  <Code>{notFound.length}</Code> unverified location
                  <Text fontSize="sm" ml={5} fontStyle="italic" color="gray">
                    Make sure the data is correct before uploading.
                  </Text>
                </Text>
              )}
            </VStack>
          </>
        )}
      </VStack>
      {!!ignored.length && (
        <VStack alignItems={"flex-start"} w="100%">
          <Text fontSize="lg" fontWeight="bold" color="gray">
            {ignored.length || 0} will be ignored <NotAllowedIcon />
          </Text>
        </VStack>
      )}
    </VStack>
  );
};

function ExistingNameWarning({
  dialogState,
  rowData,
  setDialogState,
}: {
  dialogState: any;
  rowData: Store;
  setDialogState: React.Dispatch<any>;
}): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
  return (
    <VStack divider={<Divider />} spacing={5}>
      <Text>
        The location you are trying to upload is the same as another. To upload,
        change it's name.
      </Text>

      <FormControl isInvalid={dialogState.name === rowData.name}>
        <FormLabel>Change location name</FormLabel>
        <Input
          value={dialogState.name}
          onChange={(e) => setDialogState({ name: e.currentTarget.value })}
        />
      </FormControl>
    </VStack>
  );
}

type Props = {
  items: Store[];
  onFinish: Function;
  onClose: Function;
  storeTypes?: (string | undefined)[];
  noModals?: boolean;
  actionsFromOutside?: React.SetStateAction<any>;
  filePath?: any;
};

function StoreStatusMessages() {
  const statusMessages = useAppSelector(selectStatusMessages);
  const storeStatusMessages = statusMessages.filter((m) =>
    ["creatingitemsbatch", "updatingitemsbatch", "deletingitemsbatch"].includes(
      `${m.id}`
    )
  );
  const firstStoreMessage = storeStatusMessages?.[0];
  return storeStatusMessages.length ? (
    <Alert variant="subtle" colorScheme={"blue"}>
      <HStack>
        <Box>
          <Text>{firstStoreMessage.title}</Text>
          <Text>{firstStoreMessage.message}</Text>
        </Box>
        {firstStoreMessage.loading && <Spinner />}
      </HStack>
    </Alert>
  ) : (
    <></>
  );
}

function UploadStoresProxy({
  items,
  onFinish,
  onClose,
  storeTypes,
  noModals = false,
  actionsFromOutside,
  filePath,
}: Props) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const [uploadedStores, setUploadedStores] = useState<Store[]>([]);
  const putBatch = useCallback(
    async (items: any[], accessToken: string) => {
      const { result } = await putStoresBatch(
        accessToken,
        items.map((i) =>
          new Store({
            ...i,
            type: storeTypes?.includes("restaurant") ? "restaurant" : i.type,
            chain: i.chain,
          }).buildForUpdate()
        )
      );
      return result;
    },
    [storeTypes]
  );
  const postBatch = useCallback(
    async (items: any[], accessToken: string) => {
      const { Key } = filePath || {};
      const { store_id } = await postStoresBatch(
        accessToken,
        items.map((i) =>
          new Store({
            ...i,
            type: storeTypes?.includes("restaurant") ? "restaurant" : i.type,
            chain: i.chain,
            sources: [Key],
          }).buildForPost()
        )
      );
      return store_id;
    },
    [filePath, storeTypes]
  );
  const { uploadItems, uploadFile, updateItems } = useUpload({
    postBatch,
    putBatch,
  });
  const [stores, setStores] = useState<any[]>([]);
  const [uploadingItems, setUploadingItems] = useState(false);
  const [possibleDuplicates, setPossibleDuplicates] = useState<number[]>([]);
  const [selectedStores, setSelectedStores] = useState<number[]>([]);
  const [existingStores, setExistingStores] = useState<number[]>([]);
  const [warningStores, setWarningStores] = useState<number[]>([]);
  const [locationIssueStores, setLocationIssueStores] = useState<number[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>("no_issue");
  const processID = useMemo(() => items?.[0]?.searched?.process_id, [items]);
  const messageModalContext = useContext(MessageModalContext);
  const storeLabel = useMemo(
    () =>
      storeTypes?.includes("restaurant")
        ? "Restaurant"
        : orgProperties?.properties?.storeNameReplacement || "Store",
    [orgProperties, storeTypes]
  );

  useEffect(() => {
    setUploadedStores(
      items
        .filter(
          (item) =>
            item.searched?.store_validation === "found_in_org" &&
            item.status === "exists"
        )
        .map((s, id) => new Store({ ...s, id: id }))
    );
    const _items = items
      .filter(
        (item) =>
          !item.searched?.store_validation ||
          !item.status ||
          item.searched?.store_validation !== "found_in_org" ||
          item.status !== "exists"
      )
      .map((s) => ({ ...s, id: s.searched.id }));
    setStores(_items);
    if (_items) {
      const duplicates = _items.filter(
        (item) => item.searched?.store_validation === "duplicated"
      );
      const existingItems = _items.filter(
        (item) =>
          item.searched?.store_validation === "found_in_org" &&
          item.searched?.orgStore?.status !== "not_selling"
      );
      const foundWithWarning = _items.filter(
        (item) => item.searched?.store_validation === "found_warning"
      );
      const foundWithLocationIssues = _items.filter(
        (item) => !!item.searched?.zip_state_mismatch
      );
      const newItems = _items.filter(
        (item) =>
          (item.searched?.store_validation !== "found_in_org" ||
            item.searched?.orgStore?.status === "not_selling") &&
          item.searched?.store_validation !== "duplicated" &&
          item.searched?.store_validation !== "found_warning" &&
          !item.searched?.zip_state_mismatch
      );
      setPossibleDuplicates(duplicates.map((item) => item.searched.id));
      setSelectedStores(newItems.map((item) => item.searched.id));
      setExistingStores((existingItems || []).map((item) => item.searched.id));
      setWarningStores(
        (foundWithWarning || []).map((item) => item.searched.id)
      );
      setLocationIssueStores(
        (foundWithLocationIssues || []).map((item) => item.searched.id)
      );
      messageModalContext.showModal({
        title: `Review ${pluralize(storeLabel)} to upload`,
        hideCloseButton: true,
        message: (
          <ConfirmationMessage
            stores={_items.map((s) => ({ ...s, id: s.searched.id } as Store))}
            uploadedStores={items
              .filter(
                (item) =>
                  item.searched?.store_validation === "found_in_org" &&
                  item.status === "exists"
              )
              .map((s, id) => new Store({ ...s, id: id }))}
            selectedStores={newItems.map((item) => item.searched.id)}
            existingStores={(existingItems || []).map(
              (item) => item.searched.id
            )}
            warningStores={(foundWithWarning || []).map(
              (item) => item.searched.id
            )}
            locationIssueStores={(foundWithLocationIssues || []).map(
              (item) => item.searched.id
            )}
            possibleDuplicates={duplicates.map((item) => item.searched.id)}
            storeLabel={storeLabel}
          />
        ),
        actions: [{ label: "Start review" }],
      });
    }
  }, [items, storeLabel]);

  const duplicatePairs = useMemo(
    () => stores.map((s) => s.searched?.duplicate_pair).filter((d) => !!d),
    [stores]
  );

  const extendedFilter = useCallback(
    (items: Store[]) => {
      return items?.filter((item: Store) => {
        return (
          (!selectedFilter && item.issueSolution !== "ignore") ||
          (selectedFilter === "no_issue" && selectedStores.includes(item.id)) ||
          (selectedFilter === "duplicate" &&
            duplicatePairs.includes(item.id)) ||
          (selectedFilter === "locationIssues" &&
            locationIssueStores.includes(item.id)) ||
          selectedFilter === item.issue
        );
      });
    },
    [duplicatePairs, locationIssueStores, selectedFilter, selectedStores]
  );

  const putItem = useCallback(
    async (item: Store) => {
      const originalStore = (stores || []).find((s) => s.id === item.id);
      const validatedStores = await storeUploadValidation(
        [
          {
            ...new Store(item).buildForPost(),
            order: originalStore.searched.order,
          },
        ],
        accessToken || "",
        [],
        orgProperties?.properties?.country,
        storeLabel,
        orgProperties?.organization_id,
        processID
      );
      const validatedItem = validatedStores?.[0];
      const storesToReplace = [validatedItem].map((s) => ({
        ...s,
        id: originalStore.searched.id,
        searched: {
          ...s.searched,
          id: originalStore.searched.id,
          order: originalStore.searched.order,
        },
      }));
      setStores((old) => {
        const n = [...old];
        storesToReplace.forEach((store) => {
          n.splice(store.searched.order, 1, store);
        });
        return n;
      });
      setPossibleDuplicates((old) => [
        ...old.filter((o) => !storesToReplace.map((s) => s.id).includes(o)),
        ...storesToReplace
          .filter((s) => s.searched.store_validation === "duplicated")
          .map((s) => s.id),
      ]);
      setExistingStores((old) => [
        ...old.filter((o) => !storesToReplace.map((s) => s.id).includes(o)),
        ...storesToReplace
          .filter((s) => s.searched.store_validation === "found_in_org")
          .map((s) => s.id),
      ]);
      setWarningStores((old) => [
        ...old.filter((o) => !storesToReplace.map((s) => s.id).includes(o)),
        ...storesToReplace
          .filter((s) => s.searched.store_validation === "found_warning")
          .map((s) => s.id),
      ]);
      setLocationIssueStores((old) => [
        ...old.filter((o) => !storesToReplace.map((s) => s.id).includes(o)),
        ...storesToReplace
          .filter((s) => !!s.searched.zip_state_mismatch)
          .map((s) => s.id),
      ]);
      setSelectedStores((old) => [
        ...old.filter((o) => !storesToReplace.map((s) => s.id).includes(o)),
        ...storesToReplace
          .filter(
            (s) =>
              !["duplicated", "found_in_org", "found_warning"].includes(
                s.searched.store_validation
              )
          )
          .map((s) => s.id),
      ]);
      return storesToReplace?.[0];
    },
    [accessToken, orgProperties, storeLabel, stores]
  );

  const issueOnChange = useCallback(
    (
      rowData: Store,
      newValue: string,
      bypassSetStores?: boolean,
      _stores?: Store[]
    ) => {
      let orgStore: any;
      let dupStore: any;
      if (!bypassSetStores) {
        setStores((oldSelection) => {
          const index = oldSelection.findIndex((s) => s.id === rowData.id);
          const newSelection = [...oldSelection];
          if (index > -1) {
            newSelection.splice(index, 1, {
              ...oldSelection[index],
              issueSolution: newValue,
            } as unknown as Store);
            orgStore = oldSelection[index].searched?.orgStore;
            if (oldSelection[index].searched?.duplicate_pair) {
              dupStore = oldSelection.find(
                (s) => s.id === oldSelection[index].searched.duplicate_pair
              );
            }
          }
          return newSelection;
        });
      } else if (_stores) {
        const index = _stores.findIndex((s) => s.id === rowData.id);
        if (index > -1) {
          orgStore = _stores[index].searched?.orgStore;
          if (_stores[index].searched?.duplicate_pair) {
            dupStore = _stores.find(
              (s) => s.id === _stores[index].searched.duplicate_pair
            );
          }
        }
      }
      setSelectedStores((old) => {
        const selectValue = newValue;
        return Array.from(
          new Set([
            ...old.filter((o) => selectValue !== "ignore" || o !== rowData.id),
            ...(["good", "original"].includes(selectValue) ? [rowData.id] : []),
          ])
        );
      });
      const storeToCheck = orgStore || dupStore;
      if (
        storeToCheck &&
        ((!storeToCheck.name && !rowData.name) ||
          storeToCheck.name === rowData.name) &&
        storeToCheck.address === rowData.address &&
        newValue === "good"
      ) {
        messageModalContext.showModal({
          title: "Warning",
          initialDialogState: { name: rowData.name },
          hideCloseButton: true,
          modalProps: { closeOnEsc: false },
          onClose() {
            setStores((oldSelection) => {
              const index = oldSelection.findIndex((s) => s.id === rowData.id);
              const newSelection = [...oldSelection];
              if (index > -1) {
                newSelection.splice(index, 1, {
                  ...oldSelection[index],
                  issueSolution: undefined,
                } as unknown as Store);
              }
              return newSelection;
            });
            setSelectedStores((old) => old.filter((o) => o !== rowData.id));
          },
          message: (dialogState, setDialogState) => (
            <ExistingNameWarning
              dialogState={dialogState}
              rowData={rowData}
              setDialogState={setDialogState}
            />
          ),
          actions: [
            {
              label: "Cancel",
              callback() {
                setStores((oldSelection) => {
                  const index = oldSelection.findIndex(
                    (s) => s.id === rowData.id
                  );
                  const newSelection = [...oldSelection];
                  if (index > -1) {
                    newSelection.splice(index, 1, {
                      ...oldSelection[index],
                      issueSolution: undefined,
                    } as unknown as Store);
                  }
                  return newSelection;
                });
                setSelectedStores((old) => old.filter((o) => o !== rowData.id));
              },
            },
            {
              label: "Confirm",
              props: (_, dialogState) => ({
                colorScheme: "blue",
                isLoading: dialogState.isLoading,
              }),
              callback(index, dialogState, setDialogState) {
                if (dialogState.name === rowData.name) {
                  toast.error("Please change the name of the location");
                  return;
                }
                const newRowData = {
                  ...rowData,
                  name: dialogState.name,
                } as Store;
                putItem(newRowData).then((updatedStore) =>
                  issueOnChange(updatedStore as Store, "good")
                );
                messageModalContext.dismissModal(index);
              },
              preventDismiss: true,
            },
          ],
          variant: "warning",
        });
      }
    },
    [putItem]
  );

  const getIssueOnChange = useCallback(
    (rowData: Store) => {
      return ((e) => {
        const newValue = e.currentTarget.value;
        issueOnChange(rowData, newValue);
      }) as React.ChangeEventHandler<HTMLSelectElement>;
    },
    [issueOnChange]
  );

  const SelectDuplicate = useCallback(
    (props: ICellTextProps) => {
      const { rowData } = props;
      return (
        <FormControl>
          {!rowData.issueSolution && (
            <FormHelperText>Possible duplicate</FormHelperText>
          )}
          <Select
            isInvalid={!rowData.issueSolution}
            value={rowData.issueSolution}
            onChange={getIssueOnChange(rowData)}
          >
            <option value={undefined} disabled selected>
              - Resolve issue -
            </option>
            <option value={"ignore"}>Ignore duplicate</option>
            <option value={"good"}>It's not a duplicate</option>
          </Select>
        </FormControl>
      );
    },
    [getIssueOnChange]
  );

  const SelectExisting = useCallback(
    (props: ICellTextProps) => {
      const { rowData } = props;
      return (
        <FormControl>
          {!rowData.issueSolution && (
            <FormHelperText>Already in your locator</FormHelperText>
          )}
          <Select
            isInvalid={!rowData.issueSolution}
            value={rowData.issueSolution}
            onChange={getIssueOnChange(rowData)}
          >
            <option value={undefined} disabled selected>
              - Resolve issue -
            </option>
            <option value={"ignore"}>Ignore this location</option>
            <option value={"good"}>Keep both locations</option>
          </Select>
        </FormControl>
      );
    },
    [getIssueOnChange, storeLabel]
  );

  const WarningButton = useCallback(
    (props: ICellTextProps) => {
      const { rowData } = props;
      const store = stores.find((s) => s.id === rowData.id);
      return !rowData.issueSolution ? (
        <FormControl>
          <FormHelperText>Please review matched {storeLabel}</FormHelperText>
          <Button
            colorScheme="orange"
            onClick={() => {
              messageModalContext.showModal({
                title: `Are these ${pluralize(storeLabel)} the same?`,
                hideCloseButton: true,
                modalProps: { closeOnEsc: false },
                message: <CompareStores storeToCompare={store} />,
                actions: [
                  {
                    label: `Ignore`,
                    callback: () => issueOnChange(rowData, "ignore"),
                  },
                  {
                    label: "Use original",
                    callback: () => {
                      issueOnChange(
                        {
                          ...store.original,
                          searched: store.searched,
                          original: store.original,
                          id: store.searched.id,
                        },
                        "original"
                      );
                    },
                  },
                  {
                    label: "Use verified",
                    isLeastDestructive: true,
                    props: { colorScheme: "blue" },
                    callback: () => {
                      const { id, order, store_id, store_validation, ...rest } =
                        store.searched || {};
                      issueOnChange({ ...rowData, ...rest }, "good");
                    },
                  },
                ],
              });
            }}
          >
            Review
          </Button>
        </FormControl>
      ) : (
        <></>
      );
    },
    [issueOnChange, storeLabel, stores]
  );

  const LocationIssueMessage = useCallback(
    ({ rowData }: { rowData: Store }) => {
      return (
        <FormControl>
          <FormHelperText>The zipcode and state don't match.</FormHelperText>
          {!rowData.issueSolution && (
            <FormHelperText>
              Click on zipcode or state to correct.
            </FormHelperText>
          )}
          <Select
            isInvalid={!rowData.issueSolution}
            value={rowData.issueSolution}
            onChange={getIssueOnChange(rowData)}
          >
            <option value={undefined} disabled selected>
              - Resolve issue -
            </option>
            <option value={"ignore"}>Ignore this location</option>
            <option value={"good"}>Keep mismatched data</option>
          </Select>
        </FormControl>
      );
    },
    [getIssueOnChange]
  );

  const FoundIndicator = useCallback(
    ({ value, rowData }: any) => {
      const found = value === "found";
      const store = stores.find((s) => s.id === rowData.id);
      return (
        <Tooltip
          hasArrow
          label={
            found
              ? `We have found the location in our records. Click over it to validate.`
              : `We have no record of the location. Make sure the data is correct before you upload it.`
          }
        >
          <HStack w="100%">
            {found ? (
              <FaCheck color="green" />
            ) : (
              <Text fontSize="sm">Not verified</Text>
            )}
            {rowData.found === "found" && (
              <IconButton
                aria-label={"edit match"}
                icon={<EditIcon />}
                variant="link"
                onClick={() => {
                  messageModalContext.showModal({
                    title: `Are these ${pluralize(storeLabel)} the same?`,
                    hideCloseButton: true,
                    modalProps: { closeOnEsc: false },
                    message: <CompareStores storeToCompare={store} />,
                    actions: [
                      {
                        label: "Use original",
                        callback: () => {
                          const { id } = store.searched || {};
                          issueOnChange(
                            {
                              ...store.original,
                              searched: store.searched,
                              original: store.original,
                              id,
                            },
                            "good"
                          );
                        },
                      },
                      {
                        label: "Use verified",
                        isLeastDestructive: true,
                        props: { colorScheme: "blue" },
                        callback: () => {
                          const {
                            id,
                            order,
                            store_id,
                            store_validation,
                            ...rest
                          } = store.searched || {};
                          issueOnChange(
                            {
                              ...rowData,
                              ...rest,
                            },
                            "good"
                          );
                        },
                      },
                    ],
                  });
                }}
              />
            )}
          </HStack>
        </Tooltip>
      );
    },
    [issueOnChange, messageModalContext, storeLabel, stores]
  );

  const _onClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const TextCellContent = useCallback(
    (
      props: ICellEditorProps & {
        isEditing?: boolean | undefined;
        data?: Store[] | undefined;
      }
    ) => {
      if (!props.isEditing) {
        const store = stores.find((s) => s.id === props.rowData.id);
        return (
          <Text
            color={!props.value ? "gray" : undefined}
            fontStyle={!props.value ? "italic" : undefined}
          >
            {props.value || "Click to edit"}
          </Text>
        );
      } else {
        return <CellEditorInput props={props} />;
      }
    },
    [stores]
  );

  const columns: CustomColumn<Store>[] = useMemo(
    () => [
      {
        title: "Status",
        key: "issue",
        filterOptions: ["duplicate", "exists", "no_issue"].map((value) => ({
          value,
          label:
            value === "duplicate"
              ? "Possible duplicate"
              : value === "exists"
              ? "Already exists"
              : "Ready for upload",
        })),
        width: 200,
        Render: (props) =>
          props.rowData.issue === "duplicate" ? (
            <SelectDuplicate {...props} />
          ) : props.rowData.issue === "exists" ? (
            <SelectExisting {...props} />
          ) : props.rowData.issue === "warning" ? (
            <WarningButton {...props} />
          ) : locationIssues.includes(props.rowData.issue) ? (
            <LocationIssueMessage {...props} />
          ) : (
            <Text>Ready for upload</Text>
          ),
      },
      {
        title: "Name",
        key: "name",
        Render: TextCellContent,
      },
      {
        title: "Chain",
        key: "chain",
        Render: TextCellContent,
      },
      {
        title: "Address",
        key: "address",
        style: { width: 200 },
        Render: TextCellContent,
      },
      {
        title: "State",
        key: "state",
        Render: TextCellContent,
      },
      {
        title: "City",
        key: "city",
        Render: TextCellContent,
      },
      {
        title: "Zipcode",
        key: "zipcode",
        Render: TextCellContent,
      },
      {
        title: "Verified",
        key: "found",
        info: "When a store is verified, it means we found the store in our database and will autofill the data for the store. If it's not verified, make sure the data you uploaded is correct.",
        Render: ({ value, rowData }) => (
          <FoundIndicator value={value} rowData={rowData} />
        ),
        filterOptions: ["found", "not_found"].map((value) => ({
          value,
          label: value === "found" ? "Verified" : "Not verified",
        })),
      },
    ],
    [
      FoundIndicator,
      SelectDuplicate,
      SelectExisting,
      TextCellContent,
      WarningButton,
    ]
  );

  const generateDownload = useCallback(
    (uploadToS3?: boolean) => {
      const storesToDownload = [
        ...uploadedStores.map((s) => ({
          ...s,
          status: "uploaded",
          verified: s.parent_id ? "verified" : "not_verified",
        })),
        ...stores.map((store) => ({
          ...store,
          status:
            store.searched?.store_validation === "duplicated"
              ? "duplicate"
              : store.searched?.store_validation === "found_in_org"
              ? "exists"
              : store.searched?.store_validation === "found_warning"
              ? "warning"
              : "ready_for_upload",
          verified: store.parent_id ? "verified" : "not_verified",
        })),
      ].map((s) => ({
        ...pick(
          s,
          columns
            .filter((c) => !["issue", "found"].includes(c.key))
            .map((c) => c.key)
        ),
        status: s.status,
        verified: s.verified,
      }));
      var wb = XLSX.utils.book_new();

      const storesSheet = XLSX.utils.json_to_sheet(storesToDownload);
      XLSX.utils.book_append_sheet(wb, storesSheet, "locations");
      XLSX.writeFile(wb, "upload_progress.xlsx");

      if (uploadToS3) {
        const wbBlob = new Blob(
          [XLSX.write(wb, { type: "array", bookType: "xlsx" })],
          {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }
        );
        const file = new File([wbBlob], "upload_progress.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        uploadFile({
          file,
          folderName: "store-files",
          getFileName: `${processID}_upload_progress.xlsx`,
        }).then((res) => {
          console.log(res);
        });
      }
    },
    [columns, processID, stores, uploadFile, uploadedStores]
  );

  const storesForTable = useMemo(
    () =>
      (
        stores
          .map(
            (store: Store) =>
              ({
                ...store,
                issue:
                  store.searched?.store_validation === "duplicated"
                    ? "duplicate"
                    : store.searched?.store_validation === "found_in_org" &&
                      store.searched?.orgStore?.status !== "not_selling"
                    ? "exists"
                    : store.searched?.store_validation === "found_warning"
                    ? "warning"
                    : store.searched?.store_validation === "uploaded"
                    ? "uploaded"
                    : store.searched?.zip_state_mismatch
                    ? "zip_state_mismatch"
                    : "no_issue",
                found: store.parent_id ? "found" : "not_found",
              } as unknown as Store)
          )
          .sort((a, b) =>
            a.issue === b.issue
              ? 0
              : !!a.issue && b.issue === "no_issue"
              ? -1
              : 1
          )
          .reduce((previousValue, currentValue, _, array) => {
            if (currentValue.searched?.duplicate_pair) {
              const duplicatePair: Store | undefined = array.find(
                (s) => s.searched?.id === currentValue.searched?.duplicate_pair
              );
              return [
                ...previousValue.filter(
                  (s: Store) =>
                    s.searched?.id !== currentValue.searched?.duplicate_pair
                ),
                currentValue,
                ...(duplicatePair ? [duplicatePair] : []),
              ] as Store[];
            }
            return [...previousValue, currentValue] as Store[];
          }, [] as Store[])
          .reduce(
            (acc: any[], store: { id: any }) =>
              acc.find((s) => {
                return s.id === store.id;
              })
                ? acc
                : [...acc, store],
            []
          ) as Store[]
      ).map((s) =>
        pick(s, ["id", "issueSolution", ...columns.map((c) => c.key)])
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stores]
  );

  const uploadLocationsSubmit = useCallback(async () => {
    trackClick("upload-locations-submit", storeTypes?.[0] || "", () =>
      messageModalContext.showModal({
        title: `Confirm ${pluralize(storeLabel)} to upload`,
        hideCloseButton: true,
        message: (
          <ConfirmationMessage
            stores={stores}
            uploadedStores={uploadedStores}
            selectedStores={selectedStores}
            existingStores={existingStores}
            warningStores={warningStores}
            locationIssueStores={locationIssueStores}
            possibleDuplicates={possibleDuplicates}
            storeLabel={storeLabel}
            hideDetails
          />
        ),
        actions: [
          { label: "Go back", isLeastDestructive: true },
          {
            label: selectedStores.length ? "Confirm" : "Validate products",
            callback: () => {
              if (
                stores.find(
                  (s) =>
                    ["duplicated", "found_in_org", "found_warning"].includes(
                      s.searched?.store_validation
                    ) &&
                    s.searched?.orgStore?.status !== "not_selling" &&
                    !s.issueSolution
                ) &&
                selectedStores.length
              ) {
                trackClick(
                  "upload-locations-confirm-partial",
                  storeTypes?.[0] || ""
                );
                setUploadingItems(true);
                updateItems(
                  stores
                    .filter(
                      (s) =>
                        selectedStores.includes(s.id) &&
                        s.searched?.orgStore?.status === "not_selling"
                    )
                    .map((s) =>
                      new Store({
                        ...s.searched.orgStore,
                        status: "selling",
                      }).buildForUpdate()
                    )
                )
                  .then(({ goodItems: updatedItems }) => {
                    setUploadedStores((oldItems) => [
                      ...oldItems,
                      ...updatedItems.map((item) => new Store(item.item)),
                    ]);
                    return uploadItems(
                      stores
                        .filter(
                          (s) =>
                            selectedStores.includes(s.id) &&
                            s.searched?.orgStore?.status !== "not_selling"
                        )
                        .map((s) => new Store(s).buildForPost()),
                      (goodItems) => ({
                        title: `${
                          goodItems.length + (updatedItems.length || 0)
                        } locations have been added`,
                        message: (
                          <VStack alignItems="flex-start">
                            <Text>
                              Some of the locations still have issues.
                            </Text>
                            <ConfirmationMessage
                              stores={stores}
                              uploadedStores={uploadedStores}
                              selectedStores={[]}
                              existingStores={existingStores}
                              warningStores={warningStores}
                              locationIssueStores={locationIssueStores}
                              possibleDuplicates={possibleDuplicates}
                              storeLabel={storeLabel}
                            />
                          </VStack>
                        ),
                        actions: [
                          {
                            label: "Download progress",
                            callback: () => generateDownload(),
                            preventDismiss: true,
                          },
                          {
                            label: "Go back to review",
                          },
                          {
                            label: "Continue",
                            isLeastDestructive: true,
                            props: { colorScheme: "blue" },
                            callback: () => {
                              if (onFinish)
                                onFinish(
                                  [
                                    ...(goodItems || []),
                                    ...(updatedItems || []),
                                  ],
                                  true,
                                  processID
                                );
                              _onClose();
                            },
                          },
                        ],
                      })
                    );
                  })
                  .then(({ goodItems }) => {
                    setStores((old) =>
                      old.filter((s) => !selectedStores.includes(s.id))
                    );
                    setSelectedStores([]);
                    setUploadedStores((oldItems) => [
                      ...oldItems,
                      ...goodItems.map((item) => new Store(item.item)),
                    ]);
                    generateDownload(true);
                  })
                  .finally(() => setUploadingItems(false));
              } else {
                trackClick("upload-locations-confirm", storeTypes?.[0] || "");
                generateDownload(true);
                if (onFinish)
                  onFinish(
                    stores.filter((s) => selectedStores.includes(s.id)),
                    false,
                    processID
                  );
                _onClose();
              }
            },
            props: { colorScheme: "blue", mt: 5 },
          },
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _onClose,
    existingStores,
    generateDownload,
    onFinish,
    possibleDuplicates,
    selectedStores,
    storeLabel,
    storeTypes,
    stores,
    updateItems,
    uploadItems,
    uploadedStores,
    warningStores,
  ]);

  useEffect(() => {
    if (actionsFromOutside) {
      actionsFromOutside({
        uploadLocationsSubmit,
        generateDownload,
        selectedStores: selectedStores,
        uploadingItems,
      });
    }
  }, [
    actionsFromOutside,
    generateDownload,
    selectedStores,
    uploadLocationsSubmit,
    uploadingItems,
  ]);

  const initialTableProps = useMemo(
    () =>
      selectedFilter === "uploaded"
        ? {
            columns: [
              {
                title: "Status",
                key: "issue",
                width: 200,
                Render: (props) => (
                  <Tag colorScheme="green">
                    <TagLabel>Uploaded</TagLabel>
                    <TagRightIcon as={FaCheck} />
                  </Tag>
                ),
              },
              {
                title: "Name",
                key: "name",
              },
              {
                title: "Chain",
                key: "chain",
              },
              {
                title: "Address",
                key: "address",
                style: { width: 200 },
              },
              {
                title: "State",
                key: "state",
              },
              {
                title: "City",
                key: "city",
              },
              {
                title: "Zipcode",
                key: "zipcode",
              },
            ],
          }
        : {
            columns,
          },
    [columns, selectedFilter]
  );

  const getBody = useCallback(() => {
    return (
      <VStack spacing={5} h="100%" w="100%" alignItems={"flex-start"}>
        <HStack spacing={5} w="100%">
          <Tooltip
            label={`Confirm selection to add to your list of locations.`}
          >
            <Tag
              variant="subtle"
              cursor={"pointer"}
              onClick={() => setSelectedFilter("no_issue")}
              colorScheme={selectedFilter === "no_issue" ? "blue" : undefined}
              size="lg"
              border={selectedFilter === "no_issue" ? "2px" : "none"}
            >
              <TagLabel>
                <Badge fontSize="lg">{selectedStores.length || 0}</Badge> ready
                for upload
              </TagLabel>
              <TagRightIcon as={CheckIcon} />
            </Tag>
          </Tooltip>
          {!!stores.filter((s) => possibleDuplicates.includes(s.id)).length && (
            <Tooltip
              label={`Check issues and confirm selection to add to your list of locations.`}
            >
              <Tag
                variant="subtle"
                cursor={"pointer"}
                colorScheme={
                  selectedFilter === "duplicate" ? "blue" : undefined
                }
                size="lg"
                onClick={() => setSelectedFilter("duplicate")}
                border={selectedFilter === "duplicate" ? "2px" : undefined}
              >
                <TagLabel>
                  <Badge fontSize="lg">
                    {stores.filter(
                      (s) =>
                        possibleDuplicates.includes(s.id) && !s.issueSolution
                    ).length || 0}
                  </Badge>{" "}
                  duplicated on file
                </TagLabel>
                <TagRightIcon as={WarningIcon} color="orange" />
              </Tag>
            </Tooltip>
          )}
          {!!stores.filter((s) => existingStores.includes(s.id)).length && (
            <Tooltip
              label={`Check issues and confirm selection to add to your list of locations.`}
            >
              <Tag
                variant="subtle"
                cursor={"pointer"}
                colorScheme={selectedFilter === "exists" ? "blue" : undefined}
                size="lg"
                onClick={() => setSelectedFilter("exists")}
                border={selectedFilter === "exists" ? "2px" : undefined}
              >
                <TagLabel>
                  <Badge fontSize="lg">
                    {stores.filter(
                      (s) => existingStores.includes(s.id) && !s.issueSolution
                    ).length || 0}
                  </Badge>{" "}
                  Already in locator
                </TagLabel>
                <TagRightIcon as={WarningIcon} color="orange" />
              </Tag>
            </Tooltip>
          )}
          {!!warningStores.length && (
            <Tooltip
              label={`Check issues and confirm selection to add to your list of locations.`}
            >
              <Tag
                variant="subtle"
                cursor={"pointer"}
                colorScheme={selectedFilter === "warning" ? "blue" : undefined}
                size="lg"
                onClick={() => setSelectedFilter("warning")}
                border={selectedFilter === "warning" ? "2px" : undefined}
              >
                <TagLabel>
                  <Badge fontSize="lg">{warningStores.length || 0}</Badge> need
                  more info
                </TagLabel>
                <TagRightIcon as={WarningIcon} color="orange" />
              </Tag>
            </Tooltip>
          )}
          {!!locationIssueStores.length && (
            <Tooltip
              label={`Check issues and confirm selection to add to your list of locations.`}
            >
              <Tag
                variant="subtle"
                cursor={"pointer"}
                colorScheme={
                  selectedFilter === "locationIssues" ? "blue" : undefined
                }
                size="lg"
                onClick={() => setSelectedFilter("locationIssues")}
                border={selectedFilter === "locationIssues" ? "2px" : undefined}
              >
                <TagLabel>
                  <Badge fontSize="lg">{locationIssueStores.length || 0}</Badge>{" "}
                  have location issues
                </TagLabel>
                <TagRightIcon as={WarningIcon} color="orange" />
              </Tag>
            </Tooltip>
          )}
          {!!uploadedStores.length && (
            <Tooltip label={`Items you have uploaded from this file.`}>
              <Tag
                variant="subtle"
                cursor={"pointer"}
                onClick={() => setSelectedFilter("uploaded")}
                colorScheme={selectedFilter === "uploaded" ? "blue" : undefined}
                size="lg"
                border={selectedFilter === "uploaded" ? "2px" : undefined}
              >
                <TagLabel>
                  <Badge fontSize="lg">{uploadedStores.length || 0}</Badge>{" "}
                  uploaded
                </TagLabel>
                <TagRightIcon as={CheckIcon} color="green" />
              </Tag>
            </Tooltip>
          )}
          <Tooltip label={`The total of items for you to review`}>
            <Tag
              variant="subtle"
              cursor={"pointer"}
              onClick={() => setSelectedFilter("")}
              colorScheme={!selectedFilter ? "blue" : undefined}
              size="lg"
              border={!selectedFilter ? "2px" : undefined}
            >
              <TagLabel>
                <Badge fontSize="lg">
                  {stores?.filter((s) => s.issueSolution !== "ignore").length ||
                    0}
                </Badge>{" "}
                awaiting upload
              </TagLabel>
              <TagRightIcon as={TimeIcon} />
            </Tag>
          </Tooltip>
        </HStack>
        <Box flex={1} w="100%">
          {selectedFilter === "uploaded" ? (
            <EntityTable
              containerProps={{ height: "100%" }}
              initialTableProps={initialTableProps}
              dataFromOutside={uploadedStores.map((s) =>
                pick(s, [
                  "id",
                  "name",
                  "chain",
                  "address",
                  "state",
                  "city",
                  "zipcode",
                  "issue",
                ])
              )}
            />
          ) : (
            <EntityTable
              containerProps={{ height: "100%" }}
              extendedFilter={extendedFilter}
              putItem={async (id, item, field) => {
                if (
                  field &&
                  stores.find((s) => s.id === id)[field] !==
                    item[field as keyof Store]
                ) {
                  await putItem(item);
                }
              }}
              selectedItems={selectedStores || []}
              onSelectedChange={(newSelection, rowKeyValue) => {
                setSelectedStores((old) =>
                  old.filter((o) => newSelection?.map((s) => s.id).includes(o))
                );
                const selectedStore = newSelection?.find(
                  (s) => s.id === rowKeyValue
                );
                if (selectedStore) {
                  issueOnChange(selectedStore, "good");
                } else {
                  setStores((oldSelection) => {
                    return [...oldSelection].map((s) => ({
                      ...s,
                      issueSolution: newSelection?.find(
                        (ns: any) => ns.id === s.id
                      )
                        ? "good"
                        : s.issueSolution,
                    }));
                  });
                  newSelection?.map((s) =>
                    issueOnChange(s, "good", true, stores)
                  );
                }
              }}
              initialTableProps={initialTableProps}
              allowBatchActions
              additionalBatchActions={[
                ...(stores.find(
                  (s) =>
                    possibleDuplicates.includes(s.id) &&
                    s.issueSolution !== "ignore"
                )
                  ? [
                      {
                        label: "Ignore all duplicates",
                        onSubmit: () => {
                          setStores((old) =>
                            old.map((o) => {
                              const isDup = possibleDuplicates.includes(o.id);
                              return isDup
                                ? ({
                                    ...o,
                                    issueSolution: "ignore",
                                  } as unknown as Store)
                                : o;
                            })
                          );
                          setSelectedStores((old) => {
                            return [
                              ...old.filter(
                                (o) =>
                                  !possibleDuplicates.find((dup) => dup === o)
                              ),
                            ];
                          });
                        },
                        alwaysEnabled: true,
                      },
                    ]
                  : []),
                ...(stores.find(
                  (s) =>
                    existingStores.includes(s.id) &&
                    s.issueSolution !== "ignore"
                )
                  ? [
                      {
                        label: "Ignore all existing",
                        onSubmit: () => {
                          setStores((old) =>
                            old.map((s) => {
                              const isExisting = existingStores.includes(s.id);
                              return isExisting
                                ? ({
                                    ...s,
                                    issueSolution: "ignore",
                                  } as unknown as Store)
                                : s;
                            })
                          );
                          setSelectedStores((old) => {
                            return [
                              ...old.filter(
                                (o) => !existingStores.find((dup) => dup === o)
                              ),
                            ];
                          });
                        },
                        alwaysEnabled: true,
                      },
                    ]
                  : []),
                ...(stores.find(
                  (s) => warningStores.includes(s.id) && !s.issueSolution
                )
                  ? [
                      {
                        label: `Use all matched ${pluralize(storeLabel)}`,
                        onSubmit: () => {
                          setStores((old) => {
                            const newWarningStores = old.map((s) => {
                              const isWarning = warningStores.includes(s.id);
                              const {
                                id,
                                order,
                                store_id,
                                store_validation,
                                ...rest
                              } = s.searched || {};
                              return isWarning
                                ? ({
                                    ...s,
                                    ...rest,
                                    issueSolution: "good",
                                  } as unknown as Store)
                                : s;
                            });
                            setSelectedStores((old) => {
                              return [
                                ...old.filter(
                                  (o) =>
                                    !newWarningStores.find(
                                      (dup) => dup.id === o
                                    )
                                ),
                                ...newWarningStores,
                              ];
                            });
                            return newWarningStores;
                          });
                        },
                      },
                    ]
                  : []),
              ]}
              // @ts-ignore
              dataFromOutside={storesForTable}
            />
          )}
        </Box>
        <StoreStatusMessages />
      </VStack>
    );
  }, [
    existingStores,
    extendedFilter,
    initialTableProps,
    issueOnChange,
    possibleDuplicates,
    putItem,
    selectedFilter,
    selectedStores,
    storeLabel,
    stores,
    storesForTable,
    uploadedStores,
    warningStores,
  ]);

  return !noModals ? (
    <ResponsiveModal
      isOpen={!!items}
      onClose={_onClose}
      isCentered
      scrollBehavior="inside"
      size="full"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          uploadLocationsSubmit();
        }}
      >
        <ModalOverlay />
        <ModalContent h="100%">
          <ModalCloseButton
            onClick={() => {
              onFinish();
              _onClose();
            }}
          />

          <ModalHeader textAlign="center">
            {storeTypes?.[0] || "Location"} Validation
          </ModalHeader>

          <ModalBody>{getBody()}</ModalBody>
          <ModalFooter as={HStack} justifyContent={"space-between"}>
            <Button onClick={() => generateDownload()}>
              Download progress
            </Button>
            <Button
              type="submit"
              colorScheme="blue"
              isDisabled={!selectedStores?.length}
              isLoading={uploadingItems}
            >
              Upload {pluralize("Location", selectedStores?.length, true)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </ResponsiveModal>
  ) : (
    getBody()
  );
}

export default UploadStoresProxy;
