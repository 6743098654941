import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { Box, Button, ChakraProvider, Flex, Text } from "@chakra-ui/react";
import { getApp, initializeApp } from "firebase/app";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import TagManager from "react-gtm-module";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { base_url, dathic_url } from "../constants/serviceConfig";
import { MessageModalsProvider } from "../contexts/MessageModalContext";
import { useAuth } from "../features/login/AuthHook";
import {
  selectCurrentView,
  selectUpdateViewTimeout,
} from "../features/map-views/mapViewsSlice";
import { getViews, putView } from "../services/api.service";
import { secondaryDark } from "../theme/colors";
import dathicChakraTheme from "../theme/dathic_chakra_theme";
import {
  fetchOrgProperties,
  fetchProducts,
  fetchProperties,
  selectAccessToken,
  selectLatestGrowthVersion,
} from "./appSlice";
import CacheBuster from "./CacheBuster";
import DathicRouterProvider from "./DathicRouterProvider";
import { useAppDispatch, useAppSelector } from "./store";

const { S3Client } = require("@aws-sdk/client-s3");

try {
  getApp("[DEFAULT]");
} catch (e) {
  initializeApp(
    {
      apiKey: "AIzaSyBSom0soKsQPfyP8mfJFiLh0xq-bzDoFzc",
      authDomain: "dathic-locator.firebaseapp.com",
      databaseURL: "https://dathic-locator.firebaseio.com",
      projectId: "dathic-locator",
      storageBucket: "dathic-locator.appspot.com",
      messagingSenderId: "697824418614",
      appId: "1:697824418614:web:f2823ad898d449d75cf197",
      measurementId: "G-QC3FZ9EEL3",
    },
    "[DEFAULT]"
  );
}

toast.configure({
  position: toast.POSITION.BOTTOM_CENTER,
  pauseOnHover: true,
  newestOnTop: true,
});

function App({ fbRespose }: { fbRespose: any }) {
  useAuth();
  const accessToken = useAppSelector(selectAccessToken);
  const currentView = useAppSelector(selectCurrentView);
  const updateViewTimeoutId = useAppSelector(selectUpdateViewTimeout);
  const latestAppVersion = useAppSelector(selectLatestGrowthVersion);
  const [cleaningView, setCleaningView] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (accessToken) {
      dispatch(fetchProducts());
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    let fetchInterval: NodeJS.Timer | undefined = undefined;
    const visibilityChangeListener = () => {
      if (document.hidden) {
        clearInterval(fetchInterval);
      } else {
        if (accessToken) {
          dispatch(fetchOrgProperties());
          dispatch(fetchProperties());
          fetchInterval = setInterval(() => {
            dispatch(fetchOrgProperties());
            dispatch(fetchProperties());
          }, 120000);
        }
      }
    };
    document.addEventListener("visibilitychange", visibilityChangeListener);

    if (accessToken) {
      dispatch(fetchOrgProperties());
      dispatch(fetchProperties());
      fetchInterval = setInterval(() => {
        dispatch(fetchOrgProperties());
        dispatch(fetchProperties());
      }, 120000);
    }
    return () => {
      clearInterval(fetchInterval);
      document.removeEventListener(
        "visibilitychange",
        visibilityChangeListener
      );
    };
  }, [accessToken, dispatch]);
  const UpdateToastContent = () => {
    return (
      <Box>
        <Text>New update available</Text>
        <strong>Click to refresh</strong>
      </Box>
    );
  };

  const reloadView = async (
    resetErrorBoundary: FallbackProps["resetErrorBoundary"]
  ) => {
    setCleaningView(true);
    if (currentView) {
      try {
        const lastStableConfigViewStr = localStorage.getItem(
          `lastStableConfigView${currentView.id}`
        );
        let lastStableConfigView;
        if (lastStableConfigViewStr) {
          try {
            lastStableConfigView = JSON.parse(lastStableConfigViewStr);
          } catch (error) {}
        }
        const upToDateView = await getViews(currentView.id, accessToken);
        if (upToDateView) {
          await putView(
            {
              id: upToDateView.id,
              name: upToDateView.name,
              description: upToDateView.description,
              config: lastStableConfigView || {
                ...(upToDateView.config || {}),
                mapConfig: null,
              },
            },
            accessToken
          );
          resetErrorBoundary();
        }
      } catch (error) {}
    }
    setCleaningView(false);
  };

  function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
    const [viewDetails, setViewDetails] = useState(false);

    useEffect(() => {
      if (error && !cleaningView) {
        if (updateViewTimeoutId) {
          clearTimeout(updateViewTimeoutId);
        }
        reloadView(resetErrorBoundary);
        const tagManagerArgs = {
          dataLayer: {
            event: "error",
            "gtm.errorMessage": `ErrorBoundary: ${error.message}`,
            "gtm.errorLineNumber": error.cause,
            "gtm.errorUrl": `${error.name}:${error.stack}`,
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
    }, []);
    return (
      <>
        <AlertDialog
          isOpen={!!error}
          isCentered
          closeOnOverlayClick
          leastDestructiveRef={undefined}
          onClose={() => {}}
        >
          <AlertDialogOverlay />

          <AlertDialogContent>
            <AlertDialogHeader>App Crashed</AlertDialogHeader>
            <AlertDialogBody>
              Whoah! Dathic Growth platform has just crashed while running some
              code. Refresh the page to try again.
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  variant="link"
                  mt={5}
                  onClick={() => setViewDetails((prevState) => !prevState)}
                >
                  {viewDetails ? "Hide details" : "Show details"}
                </Button>
                {viewDetails && error.toString()}
              </div>
              {cleaningView && "Cleaning view..."}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={resetErrorBoundary}>
                Reload Growth platform
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    );
  }
  return (
    <CacheBuster latestVersion={latestAppVersion}>
      {({
        loading,
        isLatestVersion,
        refreshCacheAndReload,
      }: {
        loading: boolean;
        isLatestVersion: boolean;
        refreshCacheAndReload: () => void;
      }) => {
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          toast.warn(UpdateToastContent, {
            toastId: "appupdate",
            position: toast.POSITION.BOTTOM_RIGHT,
            style: { marginBottom: 50, color: secondaryDark },
            autoClose: false,
            onClick: refreshCacheAndReload,
          });
        }

        return (
          <ChakraProvider theme={dathicChakraTheme}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={refreshCacheAndReload}
            >
              <MessageModalsProvider>
                <Flex
                  flexDir="column"
                  textAlign="center"
                  bgColor={secondaryDark}
                  w="100%"
                  minH="100vh"
                  pos="relative"
                >
                  {!base_url.includes(dathic_url) && (
                    <Flex
                      borderTop="4px solid"
                      borderColor="red.300"
                      justifyContent="center"
                      bg="white"
                      pos="absolute"
                      w="100%"
                      zIndex={9999}
                    >
                      <Text
                        bg="red.300"
                        color="white"
                        p="0 12px"
                        borderBottomLeftRadius={10}
                        borderBottomRightRadius={10}
                        pos="absolute"
                      >
                        Using testing environment
                      </Text>
                    </Flex>
                  )}
                  <DathicRouterProvider fbRespose={fbRespose} />
                </Flex>
              </MessageModalsProvider>
            </ErrorBoundary>
          </ChakraProvider>
        );
      }}
    </CacheBuster>
  );
}

export default App;
