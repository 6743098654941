export const dathic_url_test = "api.dathic.com:5000/";
export const dathic_url = "api-1.dathic.com/";
export const base_url_local = "localhost:5000/";

export const base_url = window.location.protocol + "//" + dathic_url;
export const login = "login";
export const create_user = "create_user";
export const api_properties = "api/properties";
export const api_organization_properties = "api/organization_properties";
export const api_organization = "api/organization";
export const api_user_role = "api/user_role";
export const api_role = "api/role";
export const api_predict = "api/predict";
export const api_sales_predict = "api/predict_cidrines_us_sales";
export const api_predict_fb_sales = "api/predict_fb_sales";
export const list_users = "api/users";
export const api_views = "api/views";
export const api_shared_views = "api/user_views";
export const api_favorites = "api/favorites";
export const api_annotation = "api/annotation";
export const upload_file = "api/upload_file";
export const upload_public_file = "api/upload_public_file";
export const api_delivery_product = "api/delivery/product";
export const api_delivery_entity = "api/delivery/entity";
export const api_delivery_inventory = "api/delivery/inventory";
export const api_products = "api/products";
export const api_product_store = "api/product_store";
export const api_product_sales = "api/product_sales";
export const api_product_sales_stores = "api/product_sales_stores";
export const api_stores = "api/stores";
export const api_delivery_store_locator_recommendations_new_stores =
  "api/delivery/store_locator_recommendations/new_stores";
export const api_delivery_store_locator_recommendations_stores =
  "api/delivery/store_locator_recommendations/stores";
export const api_delivery_store_locator_recommendations =
  "api/delivery/store_locator_recommendations";
export const api_delivery_store_locator_recommendations_fulfill =
  "api/delivery/store_locator_recommendations/fulfill";
export const api_delivery_store_locator_recommendations_summary =
  "api/delivery/store_locator_recommendations/summary";
export const api_recommendations_backward_store_locator_recommendations_summary =
  "api/recommendations/backward_store_locator_recommendations/summary";
export const api_recommendations_backward_store_locator_recommendations_stores =
  "api/recommendations/backward_store_locator_recommendations/stores";
export const api_recommendations_backward_store_locator_recommendations =
  "api/recommendations/backward_store_locator_recommendations";
export const api_recommendations_backward_store_locator_recommendations_fulfill =
  "api/recommendations/backward_store_locator_recommendations/fulfill";
export const api_geolocation = "api/geolocation";
export const api_twitter_indicators = "api/twitter_indicators";
export const api_topic_modeling_scores = "api/topic_modeling_scores";
export const api_correlation_service = "api/correlation_service";
export const api_delivery_entity_indicators = "api/delivery/entity_indicators";
export const api_zip3_indicators = "api/delivery/zip3_indicators";
export const api_website_sales = "api/website_sales";
export const api_superset_guest_token = "api/superset/guest_token/";
export const api_search_stores = "api/match_stores";
export const api_search_one_store = "api/match_one_store";
export const api_search_chains = "api/match_chain";
export const api_stores_batch = "api/stores_batch";
export const api_product_stores_batch = "api/product_store_batch";
export const api_store_import_history = "api/store_import_history";
export const api_support_ticket = "api/support_ticket";
export const api_stores_from_delivery = "api/stores_from_delivery";
export const api_geolayer = "api/geolayer";
export const api_zipcodes_in_radius = "api/zipcodes_in_radius";
export const api_demographics = "api/demographics";
export const api_analytics_county = "api/analytics/county";
export const api_analytics_state = "api/analytics/state";
export const api_analytics_major_city = "api/analytics/major_city";
export const api_analytics_national_avg = "api/analytics/national_avg";
export const api_facebook_advertisement = "api/facebook/advertisement";
export const api_facebook_auth = "api/facebook/auth";
export const api_facebook_adaccounts = "api/facebook/adaccounts";
export const api_facebook_pages = "api/facebook/pages";
export const create_anonymous_token = "create_anonymous_token";
export const login_with_token = "login_with_token";
export const checkout_session = "api/stripe/checkout_session";
export const shopify_request_subscription = "api/shopify/request_subscription";
export const shopify_connect_shopify = "api/shopify/connect_shopify";
export const shopify_bulk_query_orders = "api/shopify/bulk_query_orders";
export const shopify_sync_products = "api/shopify/sync_products";
export const shopify_get_current_active_subscriptions =
  "api/shopify/get_current_active_subscriptions";
export const billing_portal_session = "api/stripe/billing_portal_session";
export const stripe_customer = "api/stripe/customer";
export const stripe_customer_session = "api/stripe/customer_session";
export const stripe_product = "api/stripe/product";
export const strip_active_store_locator_invoices =
  "api/stripe/active_store_locator_invoices";
export const api_insights = "api/insights";
export const api_insights_independent_level = "api/insights/independent_level";
export const api_insights_store_nlp_insights =
  "api/insights/store_nlp_insights";
export const api_store_locator_store_reviews =
  "api/store_locator/store_reviews";
export const api_store_locator_generate_storelocator =
  "api/store_locator/generate_storelocator";
export const api_stores_overview = "api/stores_overview";
export const api_stores_paginated = "api/stores_paginated";
