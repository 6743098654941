import { StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Select,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import { updateFilterRowValue } from "ka-table/actionCreators";
import { DataType, PagingPosition, SortDirection } from "ka-table/enums";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import { pick } from "lodash";
import { DateTime } from "luxon";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import { FaEdit, FaTags } from "react-icons/fa";
import {
  MdChangeCircle,
  MdMoreVert,
  MdVisibility,
  MdVisibilityOff,
} from "react-icons/md";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { rrulestr } from "rrule";
import {
  selectAccessToken,
  selectChains,
  selectOrgProperties,
  selectUserResources,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  BatchActionRenderProps,
  CustomColumn,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Pagination } from "../../domain/Pagination";
import { Store } from "../../domain/Store";
import {
  getStores,
  getStoresPaginated,
  putStore,
  putStoresBatch,
} from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { camelCaseWords, replacePlaceholders } from "../../utils/stringUtils";
import { refreshIframe } from "../store-locator/storeLocatorSlice";
import { ManageProductsButton } from "./BatchProductStoreForm";
import ManageFeaturedContentButton from "./ManageFeaturedContentBtn";
import StoreEditBatch from "./StoreEditBatch";
import StoreFilterByProduct from "./StoreFilterByProduct";
import StoreForm from "./StoreForm";
import { useStoreRecommendations } from "./StoreRecommendationsHooks";
import { ConfidenceFilterIcon } from "./StoreRecommendationsProxy";
import StoreReviews from "./StoreReviews";

const pluralize = require("pluralize");

const ToggleStoresVisibility = ({
  selectedRows,
  onToggleFinish,
  isDisabled,
}: {
  selectedRows: Store[];
  onToggleFinish: (updatedStores: Store[]) => void;
  isDisabled?: boolean;
}) => {
  const userResources = useAppSelector(selectUserResources);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const messageModalContext = useContext(MessageModalContext);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const toEnable = selectedRows.filter((s) => s.status === "not_selling");
  const toDisable = selectedRows.filter(
    (s) => !s.status || s.status === "selling"
  );

  const batchUpdateStores = (
    stores: Store[],
    newStatus: "selling" | "not_selling"
  ) => {
    messageModalContext.showModal({
      title: "Confirm " + (newStatus === "selling" ? "enable" : "disable"),
      message: `You will ${
        newStatus === "selling" ? "enable" : "disable"
      } ${pluralize(storeLabel, stores.length, true)} and will ${
        newStatus === "selling" ? "" : "not"
      } be visible`,
      actions: [
        {
          label: "Cancel",
          isLeastDestructive: true,
        },
        {
          label: "Confirm",
          props: { colorScheme: "blue" },
          callback: async () => {
            try {
              setIsLoading(true);
              let res: { result: string };
              try {
                res = await putStoresBatch(
                  accessToken,
                  stores.map((s) => {
                    const toUpdate = new Store({
                      id: s.id,
                      address: s.address,
                      secondary_images: s.secondary_images,
                    });
                    toUpdate.status = newStatus;
                    return toUpdate.buildForUpdate();
                  }),
                  "status"
                );
              } catch (e) {
                res = { result: "error" };
              }
              if (res.result === "ok") {
                setIsLoading(false);

                toast.success("Updated items");
                dispatch(refreshIframe());
                onToggleFinish(
                  stores.map((s) => {
                    s.status = newStatus;
                    return s;
                  })
                );
              }
            } catch (error) {
              console.log(error);
            }
          },
        },
      ],
    });
  };
  return !toEnable.length || !toDisable.length ? (
    <Button
      leftIcon={
        !toEnable.length && !!toDisable.length ? (
          <MdVisibilityOff size="1rem" />
        ) : (
          <MdVisibility size="1rem" />
        )
      }
      isLoading={isLoading}
      variant="link"
      isDisabled={
        !(toEnable.length + toDisable.length) ||
        !userResources?.includes(RESOURCES.STORES_UPDATE) ||
        isDisabled
      }
      aria-label={"Batch store disable or enable button"}
      onClick={async () => {
        const stores = toEnable.length ? toEnable : toDisable;
        batchUpdateStores(stores, toEnable.length ? "selling" : "not_selling");
      }}
    >
      {toDisable.length ? "Disable" : "Enable"}
    </Button>
  ) : (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<MdVisibility size="1rem" />}
        isLoading={isLoading}
        variant="link"
        isDisabled={
          !userResources?.includes(RESOURCES.STORES_UPDATE) || isDisabled
        }
        aria-label={"Batch store disable or enable button"}
      >
        Enable or disable
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<MdVisibility size="1rem" />}
          onClick={() => batchUpdateStores(toEnable, "selling")}
        >
          Enable {pluralize(storeLabel, toEnable.length, true)}
        </MenuItem>
        <MenuItem
          icon={<MdVisibilityOff size="1rem" />}
          onClick={() => batchUpdateStores(toDisable, "not_selling")}
        >
          Disable {pluralize(storeLabel, toDisable.length, true)}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const StoreTypeSelector = ({
  selectedRows,
  onToggleFinish,
  storeTypes,
  isDisabled,
}: {
  selectedRows: Store[];
  onToggleFinish: (updatedStores: Store[]) => void;
  storeTypes?: (string | undefined)[];
  isDisabled?: boolean;
}) => {
  const userResources = useAppSelector(selectUserResources);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const messageModalContext = useContext(MessageModalContext);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const batchUpdateStores = (stores: Store[], newStatus: string) => {
    const storeType = newStatus;
    messageModalContext.showModal({
      title: "Confirm change location type to " + newStatus,
      message: `You will change the type of location of ${pluralize(
        storeLabel,
        stores.length,
        true
      )} to ${newStatus}`,
      actions: [
        {
          label: "Cancel",
          isLeastDestructive: true,
        },
        {
          label: "Confirm",
          props: { colorScheme: "blue" },
          callback: async () => {
            try {
              setIsLoading(true);
              let res: { result: string };
              const toUpdate = stores.map((s) => {
                const toUpdate = new Store({
                  id: s.id,
                  address: s.address,
                  secondary_images: s.secondary_images,
                });
                toUpdate.type = storeType;
                toUpdate.category =
                  newStatus === "restaurant"
                    ? ["Restaurants"]
                    : toUpdate.category;
                return toUpdate.buildForUpdate();
              });
              try {
                res = await putStoresBatch(accessToken, toUpdate);
              } catch (e) {
                res = { result: "error" };
              }
              if (res.result === "ok") {
                setIsLoading(false);

                toast.success("Updated items");
                dispatch(refreshIframe());
                onToggleFinish(
                  stores.map((s) => {
                    const toUpdate = new Store(s);
                    toUpdate.type = storeType;
                    toUpdate.category =
                      newStatus === "restaurant"
                        ? ["Restaurants"]
                        : toUpdate.category;
                    return toUpdate;
                  })
                );
              }
            } catch (error) {
              console.log(error);
            }
          },
        },
      ],
    });
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<MdChangeCircle size="1rem" />}
        isLoading={isLoading}
        variant="link"
        isDisabled={
          !selectedRows.length ||
          !userResources?.includes(RESOURCES.STORES_UPDATE) ||
          isDisabled
        }
        aria-label={"Batch store disable or enable button"}
      >
        Change type
      </MenuButton>
      <MenuList>
        {!storeTypes?.includes("store") && (
          <MenuItem onClick={() => batchUpdateStores(selectedRows, "store")}>
            Store
          </MenuItem>
        )}
        {!storeTypes?.includes("restaurant") && (
          <MenuItem
            onClick={() => batchUpdateStores(selectedRows, "restaurant")}
          >
            Restaurant
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

const EditStoreButton = ({
  selectedRows,
  onClick,
}: {
  selectedRows: Store[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const userResources = useAppSelector(selectUserResources);
  return (
    <Button
      leftIcon={<FaEdit />}
      variant="link"
      isDisabled={
        selectedRows.length !== 1 ||
        !userResources?.includes(RESOURCES.STORES_UPDATE)
      }
      aria-label={"Store edit button"}
      onClick={onClick}
    >
      Edit
    </Button>
  );
};

const OptionsButton = ({
  rowData,
  onEdit,
}: {
  rowData?: ICellTextProps["rowData"];
  onEdit?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const userResources = useAppSelector(selectUserResources);
  const orgProperties = useAppSelector(selectOrgProperties);
  const messageContext = useContext(MessageModalContext);
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  return rowData ? (
    <Box>
      <Menu>
        <MenuButton disabled={!rowData.id}>
          <IconButton
            variant="link"
            icon={<MdMoreVert />}
            colorScheme="blue.900"
            aria-label={""}
          />
        </MenuButton>
        <Portal>
          <MenuList maxHeight={200} overflowY="auto">
            <MenuItem
              icon={<FaEdit />}
              isDisabled={!userResources?.includes(RESOURCES.STORES_UPDATE)}
              onClick={onEdit}
            >
              Edit {storeLabel}
            </MenuItem>
            <MenuItem
              leftIcon={<StarIcon />}
              onClick={() => {
                messageContext.showModal({
                  title: `Reviews for ${pluralize(storeLabel)} ${
                    rowData.name || rowData.address
                  }`,
                  message: <StoreReviews store={rowData} />,
                });
              }}
            >
              View Reviews
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  ) : (
    <></>
  );
};

export type StoreFilter = {
  states?: string[];
  chains?: string[];
  zipcodes?: string[];
  name?: string;
  address?: string;
  ids?: (string | number)[];
  cities?: string[];
  status?: string;
  created_at?: Date;
  hasFeaturedContent?: string;
};

export default function MyStoresTable({
  storeTypes,
  storeId,
}: {
  storeTypes?: (string | undefined)[];
  storeId?: number;
}) {
  const location = useLocation();
  const [editingStore, setEditingStore] = useState<Store | undefined>();
  const [filteredProducts, setFilteredProducts] = useState<string[]>([]);
  const [stores, setStores] = useState<Store[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [paging, setPaging] = useState<Pagination<Store>>();
  const [totalEnabledCount, setTotalEnabledCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [storeFilter, setStoreFilter] = useState<StoreFilter>({});
  const [forceFetchKey, setForceFetchKey] = useState<number>();
  const [selectedStores] = useState(location.state?.selectedStores);
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLocatorConfig = orgProperties?.store_locator;
  const accessToken = useAppSelector(selectAccessToken);
  const userResources = useAppSelector(selectUserResources);
  const chains = useAppSelector(selectChains);
  const dispatch = useAppDispatch();

  const {
    getStores: getDiscontinuedStores,
    storeRecommendations: discontinuedStores,
  } = useStoreRecommendations("discontinued");
  const statesLabel = useMemo(
    () => orgProperties?.properties?.statesNameReplacement || "State",
    []
  );
  const storeLabel = useMemo(
    () => orgProperties?.properties?.storeNameReplacement || "Store",
    []
  );
  const zipcodeLabel = useMemo(
    () => orgProperties?.properties?.zipcodeNameReplacement || "Zipcode",
    []
  );
  const storePageSize = useMemo(
    () => Number(orgProperties?.properties?.storePageSize || 10000),
    []
  );

  useEffect(() => {
    if (accessToken) {
      getDiscontinuedStores(accessToken);
    }
  }, [accessToken, getDiscontinuedStores]);

  useEffect(() => {
    getStoresPaginated(
      [],
      [],
      [],
      [],
      [],
      undefined,
      true,
      accessToken,
      orgProperties?.properties?.country,
      [],
      [],
      [],
      [],
      undefined,
      storeTypes ?? [],
      [],
      1,
      0
    ).then((res: Pagination<Store>) => {
      setTotalCount(res.total);
    });
    getStoresPaginated(
      [],
      [],
      [],
      [],
      [],
      undefined,
      true,
      accessToken,
      orgProperties?.properties?.country,
      [],
      [],
      [],
      [],
      undefined,
      storeTypes ?? [],
      [],
      1,
      0,
      undefined,
      undefined,
      "selling"
    ).then((res: Pagination<Store>) => {
      setTotalEnabledCount(res.total);
    });
  }, [accessToken, orgProperties, storeTypes]);

  useEffect(() => {
    if (storeId) {
      getStores(
        [],
        [],
        [],
        [],
        [],
        undefined,
        true,
        accessToken,
        undefined,
        [],
        [storeId],
        [],
        [],
        undefined,
        [],
        [
          "id",
          "store_id",
          "parent_id",
          "name",
          "address",
          "chain",
          "state",
          "county",
          "city",
          "zipcode",
          "latitude",
          "longitude",
          "status",
          "created_at",
          "category",
          "products",
          "email",
          "type",
          "phone",
          "description",
          "image_url",
          "featured_content",
          "secondary_images",
          "start_date",
          "end_date",
          "recurrence_rule",
          "origin",
        ]
      ).then((stores: Store[]) => {
        if (stores?.[0]) {
          setEditingStore(stores?.[0]);
        }
      });
    }
  }, [accessToken, storeId]);

  const StatusSelect = (
    props: ICellEditorProps & {
      isEditing?: boolean;
      data?: any[];
      thisDispatch: any;
    }
  ) => {
    const { rowData, data, thisDispatch } = props;
    const [isLoading, setIsLoading] = useState(false);
    const accessToken = useAppSelector(selectAccessToken);
    return (
      <VStack alignItems={"flex-start"}>
        <Select
          defaultValue={rowData.status}
          value={rowData?.status}
          isDisabled={isLoading}
          onChange={(event) => {
            const newStore = new Store(rowData);
            newStore.status = event.currentTarget.value;
            setIsLoading(true);
            putStore(newStore.buildForUpdate(), accessToken)
              .then(() => {
                thisDispatch(refreshIframe());
                toast.success(`Status updated`);
                const newStores = [...(data || [])];
                newStores[
                  (data || []).findIndex((item) => item.id === rowData.id)
                ] = newStore;
                setStores(newStores || []);
                setForceFetchKey(Date.now());
              })
              .finally(() => setIsLoading(false));
          }}
        >
          <option value="" />
          {["selling", "not_selling"].map((option) => (
            <option key={option} value={option}>
              {option === "selling" ? "Enabled" : "Disabled"}
            </option>
          ))}
        </Select>
        {rowData.delisting_confidence_level && (
          <HStack>
            <Tag
              size="sm"
              variant="solid"
              colorScheme={
                rowData.delisting_confidence_level === "High"
                  ? "red"
                  : rowData.delisting_confidence_level === "Medium"
                  ? "orange"
                  : "yellow"
              }
            >
              {rowData.delisting_confidence_level === "High"
                ? "Most likely delisted"
                : rowData.delisting_confidence_level === "Medium"
                ? "Possibly absent"
                : "First sign of absence"}
            </Tag>
            <ConfidenceFilterIcon
              confidenceLevel={rowData.delisting_confidence_level}
              gradientKey={rowData.id + "-confidence-filter"}
            />
          </HStack>
        )}
        {rowData?.status === "not_selling" && (
          <Text>Reason: {rowData.disabled_reason || "Unknown"}</Text>
        )}
      </VStack>
    );
  };

  const batchActions = useMemo(() => {
    const onFinishBatchAction = (newRows: Store[], oldRows?: any[]) => {
      dispatch(refreshIframe());
      const _newRows = newRows.filter(
        (s) => !!stores?.find((store) => store.id === s.id)
      );
      setForceFetchKey(Date.now());
      setStores(
        Array.from(
          new Set(
            [
              ..._newRows,
              ...(oldRows || []).filter(
                (s) => !_newRows.find((n) => n.id === s.id)
              ),
            ].map((s) => s.id)
          )
        )
          .map((id) =>
            [..._newRows, ...(oldRows || [])].find((s) => s.id === id)
          )
          .filter((s) => !!s) || []
      );
    };
    return [
      {
        Render: ({
          selectedRows,
          data,
          isDisabled,
          totalDataSelected,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <ToggleStoresVisibility
            isDisabled={isDisabled}
            selectedRows={
              totalDataSelected
                ? (data || []).map((d) => new Store(d))
                : stores?.filter((store) =>
                    selectedRows.find((s) => s.id === store.id)
                  ) || []
            }
            onToggleFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
          isDisabled,
          totalDataSelected,
        }: BatchActionRenderProps<Partial<Store>>) =>
          selectedRows.length < 2 ? (
            <EditStoreButton
              selectedRows={
                stores?.filter((store) =>
                  selectedRows.find((s) => s.id === store.id)
                ) || []
              }
              onClick={() =>
                setEditingStore(
                  stores?.find((s) => s.id === selectedRows[0].id)
                )
              }
            />
          ) : (
            <StoreEditBatch
              isDisabled={isDisabled}
              stores={
                totalDataSelected
                  ? (data || []).map((d) => new Store(d))
                  : stores?.filter((store) =>
                      selectedRows.find((s) => s.id === store.id)
                    )
              }
              onFinish={(newRows) =>
                onFinishBatchAction(
                  newRows,
                  stores?.filter((store) =>
                    (data || []).find((s) => s.id === store.id)
                  )
                )
              }
            />
          ),
      },
      {
        Render: ({
          selectedRows,
          data,
          isDisabled,
          totalDataSelected,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <ManageProductsButton
            isDisabled={isDisabled}
            stores={
              totalDataSelected
                ? (data || []).map((d) => new Store(d))
                : stores?.filter((store) =>
                    selectedRows.find((s) => s.id === store.id)
                  )
            }
            onFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
          isDisabled,
          totalDataSelected,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <ManageFeaturedContentButton
            isDisabled={isDisabled}
            stores={
              totalDataSelected
                ? (data || []).map((d) => new Store(d))
                : stores?.filter((store) =>
                    selectedRows.find((s) => s.id === store.id)
                  )
            }
            onFinish={(newStores) => {
              trackClick(
                "store-update-featured-content",
                storeTypes?.[0] || ""
              );
              onFinishBatchAction(
                newStores,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              );
            }}
          />
        ),
      },
      {
        Render: ({
          selectedRows,
          data,
          isDisabled,
          totalDataSelected,
        }: BatchActionRenderProps<Partial<Store>>) => (
          <StoreTypeSelector
            isDisabled={isDisabled}
            selectedRows={
              totalDataSelected
                ? (data || []).map((d) => new Store(d))
                : stores?.filter((store) =>
                    selectedRows.find((s) => s.id === store.id)
                  ) || []
            }
            onToggleFinish={(newRows) =>
              onFinishBatchAction(
                newRows,
                stores?.filter((store) =>
                  (data || []).find((s) => s.id === store.id)
                )
              )
            }
            storeTypes={storeTypes}
          />
        ),
      },
      {
        Render: ({ selectedRows }: BatchActionRenderProps<Partial<Store>>) =>
          selectedRows.length === 1 ? (
            <OptionsButton
              rowData={stores?.find((s) => s.id === selectedRows[0].id)}
              onEdit={() =>
                setEditingStore(
                  stores?.find((s) => s.id === selectedRows[0].id)
                )
              }
            />
          ) : (
            <></>
          ),
      },
    ];
  }, [dispatch, stores]);

  const DateFilterInput = ({
    onClick,
  }: {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  }) => {
    return (
      <Button onClick={onClick} bg={"white"} fontWeight="normal" pr={6}>
        {storeFilter.created_at
          ? `${DateTime.fromJSDate(storeFilter.created_at).toFormat(
              "LLL dd, yyyy"
            )}`
          : "Choose date"}
      </Button>
    );
  };

  const columns: CustomColumn<any>[] = useMemo(
    () => [
      {
        title: "ID",
        key: "id",
        visible: false,
        isSortable: false,
      },
      {
        title: "Status",
        dataType: DataType.String,
        key: "status",
        info: `${pluralize(
          storeLabel
        )} that have the status (Enabled) will appear in your ${storeLabel} Locator`,
        filterOptions: ["selling", "not_selling"].map((status) => ({
          value: status,
          label: status === "selling" ? "Enabled" : "Disabled",
        })),
        Render: (p: any) => <StatusSelect {...p} thisDispatch={dispatch} />,
        format: (value: string) => camelCaseWords(value),
        default: "None",
        isSortable: false,
      },
      {
        title: "Creation date",
        key: "created_at",
        sortDirection: SortDirection.Descend,
        dataType: DataType.Date,
        format: (value: Date) =>
          value ? DateTime.fromJSDate(value).toLocaleString() : "No date",
        FilterRender: (p: any) => {
          return (
            <ReactDatePicker
              onChange={(date) => {
                if (date) {
                  date.setHours(0);
                  date.setMinutes(0);
                  date.setSeconds(0);
                  date.setMilliseconds(0);
                  p.dispatch(updateFilterRowValue(p.column.key, date));
                } else {
                  p.dispatch(updateFilterRowValue(p.column.key, null));
                }
              }}
              selected={storeFilter.created_at}
              // @ts-ignore
              customInput={<DateFilterInput />}
              clearButtonTitle="Clear"
              isClearable
            />
          );
        },
      },
      {
        title: "",
        isSortable: false,
        key: "hasFeaturedContent",
        filterOptions: [
          { value: "true", label: "Has featured content" },
          { value: "false", label: "No featured content" },
        ],
        width: 80,
        Render: (props: ICellEditorProps) =>
          props.value === "true" ? (
            <Icon as={FaTags} size="lg" color={"green"} />
          ) : (
            <></>
          ),
      },
      {
        title: "Name",
        key: "name",
        Render: (p) => (
          <Text>
            {storeLocatorConfig?.storeDetails.nameFormat
              ? replacePlaceholders(
                  storeLocatorConfig?.storeDetails.nameFormat,
                  p.rowData
                )
              : p.rowData.name}
          </Text>
        ),
      },
      {
        title: "Chain",
        key: "chain",
      },
      {
        title: "Address",
        key: "address",
      },
      ...(!location.pathname.includes("storelocator")
        ? [
            {
              title: "Score",
              dataType: DataType.Number,
              key: "score",
              sortDirection: SortDirection.Descend,
              filterRowOperator: ">",
              format: (value: number) =>
                ((value ?? 0) * 1000).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
            },
            {
              title: "Sales Prediction",
              dataType: DataType.Number,
              key: "predicted_avg_units",
              format: (value: number) =>
                (value ?? 0)?.toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                }),
            },
          ]
        : []),
      {
        title: pluralize(statesLabel, 1),
        key: "state",
      },
      {
        title: "City",
        key: "city",
      },
      {
        title: pluralize(zipcodeLabel, 1),
        key: "zipcode",
      },
      ...(storeTypes?.includes("popup_store")
        ? [
            {
              title: "Start Date",
              dataType: DataType.Date,
              key: "start_date",
            },
            {
              title: "End Date",
              dataType: DataType.Date,
              key: "end_date",
            },
            {
              title: "Recurrence",
              dataType: DataType.String,
              key: "recurrence_rule",
              format: (val: string) =>
                val ? rrulestr(val).toText() : "No recurrence",
            },
          ]
        : []),
      {
        title: "Origin",
        key: "origin",
        default: "User Submitted",
        filterOptions: ["Dathic", "Manual"].map((status) => ({
          value: status,
          label: status,
        })),
      },
    ],
    [
      dispatch,
      location.pathname,
      statesLabel,
      storeFilter.created_at,
      storeLabel,
      storeTypes,
      zipcodeLabel,
    ]
  );

  const extendedFilter = useCallback(
    (rows: Partial<Store>[]) =>
      rows.filter((s) => {
        const storeProducts =
          stores?.find((store) => store.id === s.id)?.products || [];
        return !(
          filteredProducts.length &&
          !filteredProducts.every((fp) =>
            storeProducts.find((ps: any) => +ps.product_id === +fp)
          )
        );
      }),
    [filteredProducts, stores]
  );

  const onFilterChangeDebounced = useCallback(
    (newFilters: any) => {
      const filteredChain = newFilters.chain
        ? chains.find((s) =>
            s.toLowerCase().includes(newFilters.chain.toLowerCase())
          )
        : undefined;
      const _filter = {
        states: newFilters.state ? [newFilters.state.toUpperCase()] : [],
        chains: filteredChain ? [filteredChain] : [],
        zipcodes: newFilters.zipcode ? [newFilters.zipcode] : [],
        name: newFilters.name,
        address: newFilters.address,
        ids: [],
        cities: newFilters.city ? [newFilters.city] : [],
        status: newFilters.status,
        hasFeaturedContent: newFilters.hasFeaturedContent,
        created_at: newFilters.created_at,
        delisting_confidence_level: newFilters.delisting_confidence_level,
      };
      setStoreFilter(_filter);
    },
    [chains]
  );

  const getDataForDownload = useCallback(
    async (onlyFiltered?: boolean) => {
      const _filter = onlyFiltered ? storeFilter : {};
      const _filteredProducts = onlyFiltered ? filteredProducts : [];
      const res = await getStores(
        _filter.states || [],
        _filter.chains || [],
        _filter.zipcodes || [],
        [],
        [],
        undefined,
        true,
        accessToken,
        orgProperties?.properties?.country,
        [],
        _filter.ids || [],
        [],
        _filter.cities || [],
        undefined,
        storeTypes ?? [],
        [
          "id",
          "store_id",
          "parent_id",
          "name",
          "address",
          "chain",
          "state",
          "county",
          "city",
          "zipcode",
          "latitude",
          "longitude",
          "status",
          "created_at",
          "category",
          "products",
          "email",
          "type",
          "phone",
          "description",
          "image_url",
          "featured_content",
          "secondary_images",
          "start_date",
          "end_date",
          "recurrence_rule",
          "origin",
        ],
        _filter.status,
        _filteredProducts?.length ? filteredProducts : undefined,
        _filter.hasFeaturedContent,
        _filter.name,
        _filter.address,
        _filter.created_at
      );
      return res;
    },
    [accessToken, filteredProducts, storeFilter]
  );

  return (
    <VStack position="relative" alignItems="start" height="100%">
      {!!editingStore && (
        <Box
          pos="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          backgroundColor="white"
          zIndex={99}
          p={3}
        >
          <StoreForm
            store={editingStore}
            storeTypes={storeTypes}
            onFinish={(result) => {
              if (result) {
                setStores((oldStores) => {
                  if (!oldStores?.length && !!isLoading) {
                    setIsLoading(false);
                    return undefined;
                  }
                  const index = (oldStores || []).findIndex(
                    (s) => s.id === result.id
                  );
                  const newStores = [...(oldStores || [])];
                  if (index > -1) newStores.splice(index, 1, result);
                  return newStores;
                });
                dispatch(refreshIframe());
                setForceFetchKey(Date.now());
              }
              setEditingStore(undefined);
            }}
          />
        </Box>
      )}
      <Box w="100%" flex={1}>
        <EntityTable
          initialTableProps={{
            columns,
            selectedRows: selectedStores,
          }}
          onFilterChange={onFilterChangeDebounced}
          filteredCount={paging?.total}
          totalCount={totalCount}
          paging={{
            enabled: !!paging,
            pageSize: storePageSize,
            position: PagingPosition.Bottom,
            pagesCount: paging?.pages,
            pageIndex: (paging?.current_page || 1) - 1,
          }}
          fetch={async (pageIndex?: number) => {
            const result: Pagination<Store> = await getStoresPaginated(
              storeFilter.states || [],
              storeFilter.chains || [],
              storeFilter.zipcodes || [],
              [],
              [],
              undefined,
              true,
              accessToken,
              orgProperties?.properties?.country,
              [],
              storeFilter.ids || [],
              [],
              storeFilter.cities || [],
              undefined,
              storeTypes ?? [],
              [
                "id",
                "store_id",
                "parent_id",
                "name",
                "address",
                "chain",
                "state",
                "county",
                "city",
                "zipcode",
                "latitude",
                "longitude",
                "status",
                "created_at",
                "category",
                "products",
                "email",
                "type",
                "phone",
                "description",
                "image_url",
                "featured_content",
                "secondary_images",
                "start_date",
                "end_date",
                "recurrence_rule",
                "origin",
              ],
              (pageIndex ?? 0) + 1,
              storePageSize,
              "created_at",
              "desc",
              storeFilter.status,
              filteredProducts?.length ? filteredProducts : undefined,
              storeFilter.hasFeaturedContent,
              storeFilter.name,
              storeFilter.address,
              storeFilter.created_at
            );
            const { items, ..._paging } = result || {};
            const selectedNotInPage = new Set(
              (selectedStores || []).filter(
                (id: number) => !items.map((item) => item.id).includes(id)
              )
            );
            let storesOutsidePage = [];
            if (selectedNotInPage.size) {
              storesOutsidePage = await getStores(
                [],
                [],
                [],
                [],
                [],
                undefined,
                true,
                accessToken,
                undefined,
                [],
                Array.from(selectedNotInPage),
                [],
                [],
                undefined,
                [],
                [
                  "id",
                  "store_id",
                  "parent_id",
                  "name",
                  "address",
                  "chain",
                  "state",
                  "county",
                  "city",
                  "zipcode",
                  "latitude",
                  "longitude",
                  "status",
                  "created_at",
                  "category",
                  "products",
                  "email",
                  "type",
                  "phone",
                  "description",
                  "image_url",
                  "featured_content",
                  "secondary_images",
                  "start_date",
                  "end_date",
                  "recurrence_rule",
                  "origin",
                ]
              );
              storesOutsidePage = storesOutsidePage || [];
            }
            setPaging(
              new Pagination({
                ..._paging,
                current_page: Math.min(_paging.current_page, _paging.pages),
              })
            );
            const _stores = [...(items || []), ...storesOutsidePage].map(
              (s: Store) => new Store({ ...s, status: s.status || "selling" })
            );
            setStores(_stores);
            return (_stores || []).map((s) => {
              const delistRecommendation = discontinuedStores?.find(
                (ds: any) => ds.store_id === s.id
              );
              const delistConfidenceLevel =
                delistRecommendation?.confidence_level;
              return {
                ...pick(
                  s,
                  columns.map((c) => c.key)
                ),
                hasFeaturedContent: (typeof s.featured_content === "string"
                  ? JSON.parse(s.featured_content)
                  : s.featured_content || []
                )?.length
                  ? "true"
                  : "false",
                delisting_confidence_level: delistConfidenceLevel,
              };
            });
          }}
          forceFetchKey={forceFetchKey}
          extendedFilter={filteredProducts?.length ? extendedFilter : undefined}
          containerProps={{ height: "100%" }}
          LeftButton={({ rowData }) => (
            <OptionsButton
              rowData={stores?.find((s) => s.id === rowData.id)}
              onEdit={() =>
                setEditingStore(stores?.find((s) => s.id === rowData.id))
              }
            />
          )}
          allowBatchActions
          additionalBatchActions={batchActions}
          extraFilters={[
            <StoreFilterByProduct
              value={filteredProducts}
              onChange={(p) => {
                setFilteredProducts(p);
                setForceFetchKey(Date.now());
              }}
            />,
          ]}
          onClearFilters={() => setFilteredProducts([])}
          allowDownload={
            userResources.includes(RESOURCES.DOWNLOAD_STORES) &&
            orgProperties?.store_locator?.status === "payed"
          }
          mapForDownload={({
            name,
            chain,
            state,
            city,
            zipcode,
            address,
            origin,
          }) => ({
            name: name || "",
            chain: chain || "",
            address: address || "",
            state: state || "",
            city: city || "",
            zipcode: zipcode || "",
            origin: origin || "",
          })}
          downloadLabel={`Download all enabled (${totalEnabledCount})`}
          getDataForDownload={getDataForDownload}
          entityName={storeLabel}
        />
      </Box>
    </VStack>
  );
}
