import {
  Button,
  HStack,
  Stack,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { S3Params, useUpload } from "../features/stores/UploadHooks";
import { checkAcceptAttribute, resizeImage } from "../utils/fileUtils";
import { FileDrop } from "./FileDrop";

type BaseProps = {
  message?: string;
  accept?: string;
  onChange?: (file: File | undefined) => void;
  fileFromOutside?: File;
  onUrl?: (value: string | undefined) => void;
  s3Params?: Partial<S3Params>;
  shouldUpload?: boolean;
  maxImageWidth?: number;
  maxImageHeight?: number;
  containerProps?: StackProps;
  messageDirection?: "row" | "column";
};

type ConditionalProps<T extends boolean | undefined> = T extends true
  ? { shouldUpload: true; s3Params: Partial<S3Params> }
  : { shouldUpload: false; s3Params?: Partial<S3Params> };

export type Props = BaseProps & ConditionalProps<BaseProps["shouldUpload"]>;

export const DathicFileDrop = ({
  message,
  accept,
  fileFromOutside,
  onChange,
  onUrl,
  s3Params,
  shouldUpload = false,
  maxImageWidth,
  maxImageHeight,
  containerProps = {},
  messageDirection = "row",
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | undefined>(fileFromOutside);
  const [draggingOverFrame, setDraggingOverFrame] = useState(false);
  const { uploadFile } = useUpload();

  const tryUploadFile = (file: File) => {
    if (s3Params?.folderName) {
      const params = { ...s3Params } as S3Params;
      params.file = file;
      uploadFile(params).then(onUrl);
    }
  };

  const handleFileChange = (file: File | undefined) => {
    if (onChange) {
      onChange(file);
    } else {
      setFile(file);
    }

    if (shouldUpload && file) {
      if (file?.type?.includes("image")) {
        resizeImage(
          file,
          maxImageWidth,
          maxImageHeight,
          (resizedImage: File) => {
            tryUploadFile(resizedImage);
          }
        );
      } else {
        tryUploadFile(file);
      }
    }

    if (!file && onUrl) onUrl(undefined);
  };

  return (
    <VStack>
      <FileDrop
        onTargetClick={() => inputRef.current?.click()}
        onDragOver={() => setDraggingOverFrame(true)}
        onDragLeave={() => setDraggingOverFrame(false)}
        onDrop={(files) => {
          setDraggingOverFrame(false);
          if (accept && !checkAcceptAttribute(accept, files?.[0])) {
            toast.warn("File extension not supported");
            return;
          }
          handleFileChange(files?.[0]);
        }}
      >
        <VStack
          rounded="md"
          bg="gray.100"
          border="1px solid black"
          borderStyle="dashed"
          p={3}
          opacity={draggingOverFrame ? 0.5 : 1}
          {...containerProps}
        >
          <Stack direction={messageDirection}>
            <Button>Upload file</Button>
            <Text fontSize="sm">...or drag a file here.</Text>
          </Stack>
          {message && <Text fontSize="sm">{message}</Text>}
        </VStack>
        <input
          accept={accept}
          value=""
          style={{ display: "none" }}
          ref={inputRef}
          multiple={false}
          type="file"
          onChange={(e) => {
            e.preventDefault();
            handleFileChange(e.target.files?.[0]);
            e.target.value = "";
          }}
        />
      </FileDrop>
      <HStack>
        {(fileFromOutside || file) && (
          <>
            <Text fontSize="sm" color="gray.800" fontStyle={"italic"}>
              File: {(fileFromOutside || file)?.name}
            </Text>
            <Button variant="link" onClick={() => handleFileChange(undefined)}>
              Remove
            </Button>
          </>
        )}
      </HStack>
    </VStack>
  );
};
