import { GetObjectCommand } from "@aws-sdk/client-s3";
import { Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { FilteringMode } from "ka-table";
import { ICellEditorProps } from "ka-table/props";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { FaUndo } from "react-icons/fa";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import { selectAccessToken, selectS3Client } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import { EntityTable } from "../../components/entity-table/EntityTable";
import { PageSection } from "../../components/PageSection";
import MessageModalContext from "../../contexts/MessageModalContext";
import { ProductStore } from "../../domain/ProductStore";
import { Store } from "../../domain/Store";
import { StoreImportHistory } from "../../domain/StoreImportHistory";
import {
  deleteProductStoresBatch,
  getStoreImportHistory,
  postProductStoresBatch,
  putStoreImportHistory,
  putStoresBatch,
} from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";

const ConfirmationMessage = ({
  storeImport,
}: {
  storeImport?: StoreImportHistory;
}) => {
  return (
    <VStack
      divider={<Divider />}
      textAlign={"start"}
      spacing={5}
      alignItems={"flex-start"}
    >
      <Text fontSize="sm">The selected import made the following changes:</Text>
      <VStack alignItems={"flex-start"}>
        <Text fontSize="lg" fontWeight="bold" color="blue.400">
          {storeImport?.new_product_availability}
        </Text>
        <Text fontSize="lg" fontWeight="bold" color="blue.400">
          {storeImport?.num_stores_added} locations were added
        </Text>
      </VStack>
      <Text fontSize="sm">
        After clicking "Confirm", the changes will be undone.
      </Text>
    </VStack>
  );
};

const FileStatus = ({
  value,
  rowData,
  doRestore,
}: ICellEditorProps & {
  isEditing?: boolean | undefined;
  data?: StoreImportHistory[] | undefined;
  doRestore?: (storeImport: StoreImportHistory) => void;
}) => {
  const isLocked =
    DateTime.now().diff(
      DateTime.fromJSDate(new Date(rowData.created_at)),
      "hours"
    ).hours > 48;
  return value !== "imported" ? (
    <Text>{value}</Text>
  ) : !isLocked ? (
    <HStack>
      <Text>Imported</Text>
      {!!doRestore && (
        <Button
          rightIcon={<FaUndo />}
          aria-label={"undo import"}
          onClick={() =>
            trackClick(`restore-last-stores-upload`, "filename", () => {
              doRestore(rowData);
            })
          }
          fontWeight="normal"
        >
          Undo import
        </Button>
      )}
    </HStack>
  ) : (
    <Text>Imported</Text>
  );
};

export default function ImportHistory() {
  const s3client = useAppSelector(selectS3Client);
  const accessToken = useAppSelector(selectAccessToken);
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [importHistory, setImportHistory] = useState<StoreImportHistory[]>([]);
  const messageModalContext = useContext(MessageModalContext);

  useEffect(() => {
    getStoreImportHistory(accessToken).then((data) => {
      setImportHistory(data?.map((d: any) => new StoreImportHistory(d)) || []);
    });
  }, [accessToken]);

  const doRestore = async (storeImport: StoreImportHistory) => {
    const availabilityBucket =
      storeImport.availability_file_key?.split(":")[0] || "";
    const availabilityFileKey =
      storeImport.availability_file_key?.split(":")[1] || "";
    const storesBucket = storeImport.processed_file_key?.split(":")[0] || "";
    const storesFileKey = storeImport.processed_file_key?.split(":")[1] || "";
    const downloadFile = async (
      bucket: string,
      fileKey: string,
      isRelationFile: boolean
    ) => {
      const params = {
        Bucket: bucket,
        Key: fileKey,
      };
      let fileInfo:
        | { added: ProductStore[]; removed: ProductStore[] }
        | { locations: any[] }
        | undefined;
      try {
        const objectData = await s3client.send(new GetObjectCommand(params));
        const chunks: Uint8Array[] = [];
        const reader = objectData.Body.getReader();

        const readChunk = async () => {
          const { done, value } = await reader.read();
          if (done) {
            const buffer = new Uint8Array(
              chunks.reduce((acc, chunk) => acc + chunk.length, 0)
            );
            let offset = 0;
            for (const chunk of chunks) {
              buffer.set(chunk, offset);
              offset += chunk.length;
            }
            const workbook = XLSX.read(buffer, { type: "buffer" });
            if (isRelationFile) {
              fileInfo = {
                added: XLSX.utils.sheet_to_json(workbook.Sheets["added"]),
                removed: XLSX.utils.sheet_to_json(workbook.Sheets["removed"]),
              };
            } else {
              fileInfo = {
                locations: XLSX.utils.sheet_to_json(
                  workbook.Sheets["locations"]
                ),
              };
            }
          } else {
            chunks.push(value);
            await readChunk();
          }
        };

        await readChunk();
      } catch (err) {
        console.log(err);
      }
      return fileInfo;
    };
    messageModalContext.showModal({
      title: `Confirm undo stores import`,
      message: <ConfirmationMessage storeImport={storeImport} />,
      actions: [
        { label: "Cancel", isLeastDestructive: true },
        {
          label: "Confirm",
          callback: () => {
            trackClick("restore-last-stores-confirm", "", async () => {
              if (availabilityBucket && availabilityFileKey) {
                const lastProductStoreFile = (await downloadFile(
                  availabilityBucket,
                  availabilityFileKey,
                  true
                )) as {
                  added: ProductStore[];
                  removed: ProductStore[];
                };
                try {
                  await Promise.all([
                    postProductStoresBatch(
                      accessToken,
                      (lastProductStoreFile?.removed || []).map((ps) => {
                        const _ps = new ProductStore(ps).buildForPost();
                        _ps.sources = ["import_history_restore"];
                        return _ps;
                      })
                    ),
                    deleteProductStoresBatch(
                      accessToken,
                      (lastProductStoreFile?.added || []).map((ps) => ps.id),
                      true
                    ),
                  ]);
                } catch (error) {
                  console.log(error);
                }
              }
              if (storesBucket && storesFileKey) {
                const lastStoreFile = (await downloadFile(
                  storesBucket,
                  storesFileKey,
                  false
                )) as { locations: any[] };
                try {
                  await putStoresBatch(
                    accessToken,
                    (lastStoreFile?.locations || []).map((ps) =>
                      new Store({
                        ...ps,
                        status: "not_selling",
                      }).buildForUpdate()
                    )
                  );
                } catch (error) {
                  console.log(error);
                }
              }
              setImportHistory((old) => {
                const newStoreImport = new StoreImportHistory({
                  ...storeImport,
                  is_restored: true,
                  restored_at: new Date().toISOString(),
                });
                const newHistory = [...old];
                const index = newHistory.findIndex(
                  (si) => si.id === storeImport.id
                );
                if (index > -1) {
                  newHistory[index] = newStoreImport;
                }
                putStoreImportHistory(accessToken, newStoreImport);
                return newHistory;
              });
              toast.success("Store import restored successfully");
            });
          },
          props: { colorScheme: "blue", mt: 5 },
        },
      ],
    });
  };

  return (
    <PageSection
      title="Import History"
      label={
        importHistory.length
          ? 'If you find any problems after importing, no worries! You can go back to your old data within 48 hours. Just click "Undo import" in the status column.'
          : ""
      }
      contentDirection="column"
    >
      {!importHistory.length && (
        <Text>You have not imported any files yet.</Text>
      )}
      {!!importHistory.length && (
        <EntityTable
          hideTopBar
          containerProps={!isShowingMore ? { h: 56 } : undefined}
          initialTableProps={{
            filteringMode: FilteringMode.None,
            columns: [
              {
                key: "created_at",
                title: "Date and time",
                Render: ({ value }) => (
                  <Text>
                    {DateTime.fromJSDate(new Date(value)).toFormat(
                      "LLL dd, yyyy HH:mm"
                    )}
                  </Text>
                ),
              },
              { key: "original_filename", title: "Filename" },
              { key: "num_stores_added", title: "Locations added" },
              { key: "num_products_added", title: "Products added" },
              { key: "new_product_availability", title: "New availability" },
              {
                key: "status",
                title: "Status",
                Render: (p) => <FileStatus {...p} doRestore={doRestore} />,
              },
            ],
          }}
          dataFromOutside={importHistory.slice(
            0,
            isShowingMore ? importHistory.length : 3
          )}
        />
      )}
      {importHistory.length > 3 && (
        <Button onClick={() => setIsShowingMore((old) => !old)} variant="link">
          {isShowingMore ? "Show less" : "Show more"}
        </Button>
      )}
    </PageSection>
  );
}
